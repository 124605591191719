
@import "../../../../sass/_mixins/pux/breakpoints.scss";
@import "../../../../sass/_mixins/pux/css-rules.scss";
@import "../../../../sass/_mixins/pux/pux-content.scss";
@import "../../../../sass/_mixins/pux/scale.scss";
@import "../../../../sass/_variables.scss";



$modal-overlay-background: #000;
$modal-max-width: 500px;
$modal-margin: 40px auto;

$modal-base-radius: 15px;
$modal-close-background: darken(grey,15);
$modal-close-color: red;

$modal-light-background: #fff;
$modal-light-color: #000;
$modal-light-border: none;
$modal-light-close-background: darken(grey,15);
$modal-light-close-color: red;

$modal-dark-background: #585857;
$modal-dark-color: #fff;
$modal-dark-border: none;
$modal-dark-close-background: grey;
$modal-dark-close-color: red;


.pux-modal-leave,
.pux-modal{
  .modal-dialog{
    position: fixed;
    opacity: 0;
    pointer-events: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    max-width: 100%;
    z-index: 1000;
    transition: opacity 0.2s ease;
    &:target{
      opacity: 1;
      pointer-events: auto;
    }
  }
  .modal-content{
    text-align: center;
    @include border-radius($modal-base-radius);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat !important;
    max-width: $modal-max-width;
    width: 80%;
    margin: 0;
    padding: 15px 15px;
    @include media($menu-break){
      width: 100%;
    }
    h2{
      font-size: 3.5rem;
      text-align: center;
      font-weight: bold;
      padding: 0;
      line-height: 5rem;
      @media ( min-width: 360px ) {
        padding: 0 10rem;
      }
      @include media($menu-break){
        font-size: 2.7rem;
        line-height: initial;
        padding: 0 5rem;
      }
    }
    ul{
      text-align: center;
      padding: 2rem 0;
      li{
        padding: 0;
      }
      li::before{
        content: none;
      }
    }
    a:last-child{
      color: $base-black;
      text-decoration: underline;
      font-size: 3rem;
      font-weight: bold;
      @include media($menu-break){
        font-size: 2rem;
      }
    }
    .close{
      outline: 0;
      position:absolute;
      right:10px;
      top:15px;
      width:30px;
      height:30px;
      text-shadow:none;
      cursor:pointer;
      z-index:22;
      font-size: 2rem;
      @include media($menu-break){
        font-size: 1.5rem;
        right:15px;
      }
      @include opacity(1);
      &::before{
        content: "\e90c";
      }
    }
    &.modal-light{
      background-color: $modal-light-background;
      color: $modal-light-color;
      border: $modal-light-border;
      .close{
        background: $modal-light-close-background;
        color: $modal-light-close-color;
        &:focus,
        &:hover{
          background: darken($modal-light-close-background,10);
        }
      }
    }
    &.modal-dark{
      background-color: $modal-dark-background;
      color: $modal-dark-color;
      border: $modal-dark-border;
      .close{
        background: $modal-dark-close-background;
        color: $modal-dark-close-color;
        &:focus,
        &:hover{
          background: darken($modal-dark-close-background,10);
        }
      }
    }
    .modal-body{
      padding: 30px;
      &-title{
        @include font-size(22px);
        padding:0 0 15px 0;
        font-weight:bold;
      }
      &-subtitle{
        @include font-size(18px);
        padding:0 0 10px 0;
      }
      &-content{

      }
      &.no-padding{
        padding:0;
      }
    }
    &.background-cover{
      background-size: cover !important;
    }
    &.top-center{
      background-position: top center !important;
    }
    &.top-left{
      background-position: top left !important;
    }
    &.top-right{
      background-position: top right !important;
    }
    &.center-center{
      background-position: center center !important;
    }
    &.bottom-center{
      background-position: bottom center !important;
    }
    &.bottom-left{
      background-position: bottom left !important;
    }
    &.bottom-right{
      background-position: bottom right !important;
    }
  }
}

.modal-backdrop{
  background: $modal-overlay-background;
}
