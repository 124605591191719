
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

body{
  font-family: $font-family;
}

.pux-container{
    margin-left: auto;
    margin-right: auto;
    padding-left: $container-padding-left;
    padding-right: $container-padding-right;
    max-width: $container-max-width;
    &:after{
        display: block;
        content: "";
        clear: both;
    }
    @include media(md){
        padding-left: $container-padding-left-md;
        padding-right: $container-padding-right-md;
    }
}

.pux-container{
    .row{
        @include flex-block;
        @include flex-wrap(wrap);
        margin-left: $row-margin-left;
        margin-right: $row-margin-right;
        @include media(md){
          margin-left: 0;
          margin-right: 0;
        }
    }
}

.pux-container{
    [class^="col-"]{
        padding-left: $col-padding-left;
        padding-right: $col-padding-right;
        @include media(md){
          padding-left: $col-padding-left-md;
          padding-right: $col-padding-right-md;
        }
    }
}

.pux-fluid-container{
    padding-left: $fluid-container-padding-left;
    padding-right: $fluid-container-padding-right;
    @include media(md){
      padding-left: $fluid-container-padding-left-md;
      padding-right: $fluid-container-padding-right-md;
    }
    @include media(xl){
      padding-left: $fluid-container-padding-left-xl;
      padding-right: $fluid-container-padding-right-xl;
    }
}

header {
  position: relative;
  z-index: 999;
  .header-top {
    @include flex-block;
    @include align-items(center);
    @include justify-content(space-between);
    display: block;
    height: 12rem;
    background-color: $base-black;
    @include media(md) {
      height: 9rem;
    }
    .pux-container{
      height: 100%;
    }
    .header-top-inner{
      display: flex;
      justify-content: space-between;
      height: 100%;
      &-left,
      &-right{
        display: flex;
        align-items: center;
      }
      &-right{
        margin-right: 7rem;
        @include media(md){
          margin: 0
        }
      }
    }

    &-logo {
      display: flex;
      @include media(md){
        margin: auto 2rem auto 0;
      }
      @include media (lg){
        margin: auto 2rem auto 0;
      }
      @media(min-width: 1050px){
        margin: auto 4rem auto 0;
      }
      a {
        color: $base-white;
        text-decoration: none;
        font-weight: bold;
        font-size: 5rem;
        line-height: 5rem;
        @include media(md) {
          font-size: 3rem;
          line-height: 3rem;
        }
        span {
          display: block;
          color: $base-white;
          font-size: 2.5rem;
          @include media(xs){
            font-size: 2.8rem;
          }
          @include media(sm){
            font-size: 2.5rem;
          }
          @include media(md){
            font-size: 1.7rem;
          }
          @include media(lg){
            font-size: 1.5rem;
          }
        }
        img {
          max-width: none;
          height: 5rem !important;
        }
      }
    }


  .header-top-phone,
  .header-top-email{
    display: none;
    @include media(md){
      display: flex;
      margin: auto 1rem auto 0;
    }
    @media ( min-width: 930px ){
      margin: auto 2rem auto 0;
    }
    @include media(lg){
      margin: auto 2rem auto 0;
    }
    @media(min-width: 1050px){
      margin: auto 4rem auto 0;
    }

  }

  .header-top-signin,
  .header-top-signup{
    display: flex;
    flex-direction: row;
    margin: auto 2rem auto 0;
    @media( min-width: 360px ){
      margin: auto 5rem auto 0;
    }
    @include media(md){
      margin: auto 3rem auto 0;
    }
    @include media(lg){
      margin: auto 2rem auto 0;
    }
    @media(min-width: 1050px){
      margin: auto 4rem auto 0;
    }

  }

    .sign-in-box,
    .sign-up-box{
      display: none;
      position: absolute;
      margin: 8rem 0 0 -30rem;
      z-index: 1000;
      width: 220px;
      height: auto;
      background-color: white;
      border-radius: 20px;
      padding: 3rem 2rem 3rem 2rem;
      text-align: center;
      box-shadow: 0 0 11px rgba(33, 33, 33, .2);
      @media (min-width: 360px) {
        margin: 8rem 0 0 -34rem;
      }
      @include media(md) {
        margin: 6.5rem 0 0 -8rem;
        width: 250px;
      }
      @include media(lg) {
        margin: 6.3rem 0 0 -11rem;
        width: 250px;
      }
      @include media(xl) {
        margin: 6.3rem 0 0 -10rem;
        padding: 2rem 2rem 4rem 2rem;
        width: 250px;
      }
      @include media(xxl) {
        margin: 6.3rem 0 0 -8rem;
        padding: 2rem 2rem 4rem 2rem;
      }
      .btn-default {
        margin-top: 4rem;
        padding: 2rem 5rem;
        background-color: $main-color;
        color: $base-white;
        border: none;
        @include media(md) {
          padding: 2rem 4rem;
        }
        @include media(lg){
          font-size: 2rem;
        }
        @include media(xl) {
          font-size: 2rem;
          margin-top: 2rem;
          padding: 1.5rem 3rem;
        }
        &:hover {
          background-color: $color-link;
        }
      }
    }

    .sign-in-box.open{
      display: block;
    }
    .sign-up-box.open{
      display: block;
    }

  .sign-in-form,
  .password-form{
    text-align: center;
    padding-bottom: 4rem;
    @include media(xl) {
      padding-bottom: 2rem;
    }

    label {
      padding: 2rem 0;
      @include media(md) {
        padding: 1rem 0;
      }
    }

    input {
      border-radius: 20px;
    }

  }
  .password-form{
    display: none;
    position: absolute;
    top: 20px;
    left: 0;
    background: $base-white;
    overflow: hidden;
    padding: 0 2rem;
    height: 90%;
    width: 100%;
  }
  .password-form.open{
    display: block;
  }


  .header-top-signup{
    .sign-up-box {
      .sign-in-form {
        @include media(sm) {
          padding-bottom: 2rem;
        }
      }
    }
  }


  .header-top-phone,
  .header-top-email,
  .header-top-signin,
  .header-top-signup{
    > a{
      line-height: 0;
      font-size: 0;
      @media ( min-width: 930px ){
        color: $base-white;
        font-size: 1.8rem;
        padding: 5px 5px;
        line-height: initial;
      }
      &:hover{
      color: $main-color;
      }
      &:hover::before{
        color: $main-color;
      }
    }

  }
  .header-top-phone,
  .header-top-email{
    > a{
      @include media (md){
        color: $base-white;
        font-size: 1.8rem;
        padding: 5px 5px;
        line-height: initial;
      }
    }

  }

  .header-top-phone a::before,
  .header-top-email a::before,
  .header-top-signin a::before,
  .header-top-signup a::before{
    font-size: 4.5rem;
    color: $base-white;
    @include media(md){
      font-size: 2rem;
      padding-right: 1rem;
    }
  }

  .header-top-phone a::before{
    content: "\e907";
  }
  .header-top-email a::before{
    content: "\e906";
  }
  .header-top-signin > a::before{
    content: "\e905";
  }
  .header-top-signup > a::before{
    content: "\e908";
  }
  }


  .header-bottom{
    //@include flex-block;
    @include align-items(center);
    @include justify-content(space-between);

      background: transparent;
      position: absolute;
      width: 100%;
      height: 12rem;
    @include  media($menu-break) {
      position: relative;
      height: 6rem;
      background: $menu-color;
    }
    .pux-container{
      padding: 0;
      @include media($menu-break){
        height: 100%;
        padding: 0 4.5rem;
      }
    }
    &-menu{
      display: none;
      top: 0;
      position: absolute;
      height: 100%;
      z-index: 77;
      left:0;
      width: 100%;
      @include calc("max-height", "100vh - 5rem");
      @include media($menu-break){
        @include flex-block;
        height: 6rem;
        position: static;
        width: 100%;
      }
      > ul{
        width: 100%;
        margin:0 auto;
        padding:0;
        list-style: none;
        text-transform: uppercase;
        background-color: $menu-color;
        @include media($menu-break){
          @include flex-block;
          height: 100%;
          background-color: transparent;
        }
        > li{
          display: flex;
          position: relative;
          flex-direction: column;
          @include media($menu-break){
            display: block;
            position: static;
            padding: 0;
            height: 80%;
            margin: auto 1%;
            width: 17%;
            border-radius: 41px;
            transition: all 0.2s ease-in-out;
            @include flex-block;
            @include align-items(center);
            &:after {
              content: "";
              border-top-color: transparent;
              transition: border-top-color 0.2s ease-in-out;
            }
          }


          a{
            font-size: 3.5rem;
            padding: 1rem 3rem;
            width: 100%;
            text-align: left;
            margin: auto auto;
            display: flex;
            align-items: center;
            color: $base-black;
            border-bottom: 1px solid $menu-border;
            height: 60px;
            @media ( min-width: 360px ) {
              font-size: 3.5rem;
            }
            @include media(sm){
              font-size: 4rem;
            }
            @include media($menu-break) {
              font-size: 2rem;
              width: 100%;
              height: 100%;
              color: $base-black;
              border-radius: 41px;
              text-align: center;
              line-height: 0;
              padding: 0;
              display: block;
              border-bottom: none;
            }

            &.Highlighted{
              color: $color-link;
              background-color: $menu-border;
              text-decoration: none;
              @include media($menu-break){
                color: $base-white;
                background-color: $base-black;
              }
            }


          }
           & > a::before{
              content: ' '; //menu icon
              background-size: 30px 30px;
              height: 30px;
              width: 30px;
              display: inline-flex;
              margin-right: 3rem;
              vertical-align: middle;
              @media ( min-width: 360px ) {
                background-size: 40px 40px;
                height: 40px;
                width: 40px;
              }
              @include media($menu-break){
                background-size: 30px 30px;
                height: 30px;
                width: 30px;
                margin: 0 0.5rem 0.5rem 0;
              }
            }

          & > a::after{
            @include media($menu-break) {
              display: block;
              width: 100%;
              content: "";
              height: 1rem;
            }
          }

          &:hover {
              @include media($menu-break) {
                color: $base-white;
                background-color: $base-black;
              }
            span{
              @include media($menu-break) {
                color: $base-white;
              }
            }
            @include media($menu-break) {
              &:after {

                content: ""; // menu arrow down
                width: 0;
                height: 0;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-top: 15px solid $base-black;
                z-index: 101;
              }
            }
            .menu-level-2 {
              @include media($menu-break) {
                display: block;
              }
            }
          }
          span{
            @include media($menu-break){
              text-align: center;
              line-height: 4.7rem;
              padding: 0;
            }
          }


          &.open{
            @media (max-width: 767px) {
              .menu-level-2{
                display: block;
              }
            }
          }
//////////////////////////////////////////////////// LEVEL 2 ////////////////////////////////////////////////////
          .menu-level-2{
            display: none;
            position: relative;
            left: 0;
            width: 100%;
            top:0;
            height: auto;
            @include media($menu-break){
              display: none;
              position: absolute;
              top: 6rem;
              left: 0;
              width: 100%;
              z-index: 100;
            }
            @include media(xl){
              left: 4%;
              width: 92%;
            }
            @include media(xxl){
              width: 92%;
              left: 4%;
            }
            @media ( min-width: 1600px ) {
              width: 84%;
              left: 8%;
            }

            > ul {
              box-shadow: 0 0 11px rgba(33,33,33,.2);
              @include media($menu-break){
                background-color: $base-white;
                min-height: 25rem;
              }
              > li:nth-child(even) {
                @include media($menu-break) {
                  background-color: $light-grey;
                }
              }
              > li:nth-child(odd) {
                @include media($menu-break) {
                  background-color: $base-white;
                }

              }
            }

            ul{
              display: block;
              width: 100%;
              padding: 0;
              list-style: none;
              @include media($menu-break){
                display: flex;
                align-items: flex-start;
              }
              >li{
                @include media($menu-break) {
                  text-align: center;
                }
                > a{
                  @include media($menu-break) {
                    padding-top: 1rem;
                  }
                }
              }
              li{
                width: 100%;
                margin: 0;
                padding: 0;
                height: 100%;
                align-items: start;
                flex-wrap: wrap;
                position: relative;
                @include media($menu-break){
                  width: 25%;
                }
                &.open{
                  .menu-level-3{
                    display: block;
                  }
                }
                span{
                  margin: auto;
                  font-weight: bold;
                  font-size: 1.6rem;
                }
                //a::after{
                //  right: 0;
                //}
                & > a{
                  padding: 0 20px 0 70px;
                  display: flex;
                  align-items: center;
                  height: 60px;
                  @include media($menu-break){
                    display: inline;
                    padding: 0;
                    display: block;
                    font-weight: bold;
                    line-height: 4rem;
                    height: auto;
                  }
                }

                >a:hover{
                  @include media($menu-break) {
                    background: none;
                    color: $base-black;
                    cursor: initial;
                  }
                }
              .menu-level-3{
                  width: 100%;
                  height: auto;
                  display: none;
                  flex-wrap: wrap;
                  text-transform: none;
                  list-style: none;
                  padding-left: 0;
                  @include media($menu-break){
                    display: flex;
                    padding: 0;
                    position: relative;
                  }

                  li{
                    width: 100%;
                    padding: 0.5rem 0 0.5rem 0;
                    height: auto;
                    text-align: left;
                    @include media($menu-break){
                      padding: 0.5rem 0 0.5rem 1rem;
                    }
                    a{
                      display: inline-flex;
                      @include media($menu-break){
                        font-weight: normal;
                      }
                      &:hover {
                        color: $color-link;
                        background-color: $menu-border;
                        @include media($menu-break) {
                          background: none;
                          color: $base-black;
                          font-weight: bold;
                          cursor: pointer;
                        }
                      }
                      .menu-level-3-icon{
                        display: block;
                        //height: 9rem;
                        width: 9rem;
                        @include media(sm){
                          margin-right: 1rem;
                        }

                        @include media($menu-break){
                          height: 4rem;
                          width: 4rem;
                        }
                        img{
                          width: 60%;
                          padding: 0;
                          @media ( min-width: 360px ){
                            width: 70%;
                          }
                          @include media ($menu-break){
                            width: 80%;
                          }
                        }
                      }
                    }

                  }
                }
              }
            }
          }
        }

        //////////////////////////// MAIN MENU ICONS //////////////////////////////

        .menu-level-1-icon-nhl::before{
          background-image: url("../img/svg/menu_nhl_icon.svg");
        }
        .menu-level-1-icon-nfl::before{
          background-image: url("../img/svg/menu_nfl_icon.svg");
        }
        .menu-level-1-icon-mlb::before{
          background-image: url("../img/svg/menu_mlb_icon.svg");
        }
        .menu-level-1-icon-nba::before{
          background-image: url("../img/svg/menu_nba_icon.svg");
        }
        .menu-level-1-icon-ufc::before{
          background-image: url("../img/svg/menu_ufc_icon.svg");
        }
        .menu-level-1-icon-mls::before{
          background-image: url("../img/svg/menu_mls_icon.svg");
        }


        ///////////////////////////////////////////////////////////////////////////

          &.Highlighted{
            a{
              color:$main-color;
            }
          }
          ul{
            width:100%;
            @include opacity(1);
            visibility: visible;
            @include transition;
            @include media(md){

              position: absolute;
              width:20rem;
              visibility: visible;
              @include opacity(1);
              display: block;
              top:100%;
            }
            li{
              a{
                &:focus,
                &:hover{

                }
              }
            }
          }
          &.hovered,
          &:hover{
            & > a{
              color: $base-white;
              background-color: $nav-hover;
              font-weight: bold;
            }
            & > ul {
              visibility: visible;
              @include opacity(1);
            }
          }
          &.open{
            .menu-openner{
              &:after{
                @include rotate(0deg);
              }
            }
            ul{
              display:block;
            }
          }
        }

      }
    ///////////////////////////////////// END OF MENU /////////////////////////////
      .menu-openner{
        &:hover{
          color: $color-link;
          @include media($menu-break){
            color: $base-white;
          }
        }
        @media ( max-width: 767px ) {
          &:after{
            content:"\e90b";
            font-size: 2rem;
            position: absolute;
            right: 5rem;
            margin-top: 3.5rem;
            transition: transform .3s ease;
            @media ( min-width: 360px ) {
              margin-top: 4rem;
            }
          }
        }

        &.open{
          &:after{
            transform: rotate(180deg);
          }
        }
      }
    }

    .header-hamburger{
      @include media($menu-break){
        display: none;
      }
      position: absolute;
      top: 0;
      right: 0;
      margin-top: -12rem;
      width: 11.5rem;
      height: 12rem;
      padding: 3rem 2rem;
      @media ( min-width: 360px ) {
        width: 12.5rem;
        padding: 3rem 3rem;
      }
      .open-menu{
        width:$hamburger-width;
        height:$hamburger-width;
        position: relative;
        display: block;
        span{
          width:$hamburger-width - 1rem;
          position: absolute;
          left:0.5rem;
          height:3px;
          background: $base-white;
          margin-top:-1px;
          top:50%;
          @include transition;
          &:before,
          &:after{
            content:"";
            width:$hamburger-width - 1rem;
            left:0;
            height:3px;
            background: $base-white;
            position: absolute;
            @include transition;
          }
          &:before{
            top:-1.5rem;
          }
          &:after{
            bottom:-1.5rem;
          }
        }
      }
    }

    &.open{
      .header-hamburger{
        background-color: #f4f4f4;
        .open-menu{
          span{
            background: none;
            &:before{
              top:0;
              height:4px;
              background: $color-link;
              @include rotate(45deg);
            }
            &:after{
              top:0;
              height:4px;
              background: $color-link;
              @include rotate(-45deg);
            }
          }
        }
      }
      .header-bottom-menu{
        display: block;
      }
    }

}
////////////////////////////////////////// END OF HEADER //////////////////////////////

.EditMode,
.DesignMode {
  header {
    position: static;
  }
}
////////////////////////////////////////////////// FOOTER ////////////////////////////////////////
footer{
  padding: 4.5rem 0;
  min-height: 33rem;
  height: auto;
  background-color: $base-black;
  .footer{
    @include flex-block;
    @include justify-content(space-between);
    @include align-items(center);
    @include flex-wrap(wrap);
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    @include media(md){
      flex-direction: row;
      height: auto;
    }
    hr{
      border-color: $dark-grey;
      width: 100%;
      @include media(md){
        display: none;
      }
    }
    .footer-contact {
      padding: 2rem 0;
    }
    .footer-contact-phone,
    .footer-contact-email {
      padding: 2rem 0;
      @include media(md) {
        display: none;
      }
      a{
        color: $base-white;
        font-size: 3rem;
        padding: 5px 5px;
        @include media(md) {
          font-size: 1.8rem;
        }
        &:hover {
          color: $main-color;
        }
        &::before {
          padding-right: 2rem;
          font-size: 3rem;
        }
      }
    }

    .footer-contact-phone a::before {
      content: "\e907";
    }

    .footer-contact-email a::before {
      content: "\e906";
    }



  .footer-menu,
  .footer-contact,
  .footer-newsletter,
  .footer-social{
    display: flex;
    padding: 5rem 0;
    flex-direction: column;
    @include media(md){
      padding: 0;
    }
    h4{
      font-size: 3.5rem;
      padding-bottom: 1rem;
      @media ( min-width: 360px ){
        font-size: 4rem;
      }
      @include media(md) {
        font-size: 2rem;
        padding-bottom: .9rem;
      }
    }
  }

  .footer-menu{
    color: $base-white;
    height: auto;
    width: 100%;
    padding-left: 0;
    ul {
      list-style: none;
      width: 100%;
      color: $base-white;
      padding: 0 0;
      line-height: 4rem;

      li a{
        font-size: 3rem;
        line-height: 8rem;
        color: $base-white;
        text-decoration: underline;

        @include media(md) {
          font-size: 1.8rem;
          line-height: 4rem;
        }
        &:hover{
          color: $main-color;
        }
      }
    }
  }

  .footer-contact{
    flex-direction: column;
  }

  .footer-newsletter{
    width: 100%;
    color: $base-white;
    flex-direction: column;
    label {
      display: none;
      @include media(md){
        display: flex;
      }
    }
    .footer-newsletter-input{
      display: flex;
      flex-direction: row;
      padding-top: 3rem;
      @include media(md){
        padding-top: 1rem;
      }
      h4{
        line-height: 2.5rem;
        @include media(lg){
          line-height: 1.8rem;
        }
      }
    }
    input{
      height: 10rem;
      //min-width: 30rem;
      width: 70%;
      border-radius: 41px;
      background-color: $base-black;
      border: 1px solid $base-white;
      color: $base-white;
      margin-right: 1.5rem;
      @include media(xs){
        margin-right: 2rem;
      }
      @include media(xs){
        width: 50rem;
      }
      @include media(md){
        width: 70%;
        height: 6rem;
        margin-right: 0.5rem;
      }
      @include media(lg){
        width: 50rem;
      }
      @include media(xl){
        margin-right: 2rem;
      }
      &::placeholder{
        color: $base-white;
        opacity: 1;
      }
    }
    .btn-default{
      padding: 0;
      width: 30%;
      height: 10rem;
      font-size: 2.5rem;
      @include media(xs){
        width: 20rem;
      }
      @include media(md){
        width: 30%;
        height: 6rem;
        font-size: 1.8rem;
      }
      @include media(lg){
        font-size: 1.8rem;
        width: 20rem;
        letter-spacing: 0;
      }
      @include media(xl){
        width: 15rem;
      }

    }
  }

  .footer-social {
    width: 100%;
    list-style: none;
    color: $base-white;
    text-align: left;
    flex-direction: column;
    @include media(md) {
      text-align: right;
    }
    h4 {
      display: none;
      @include media(md) {
        display: flex;
        justify-content: flex-end;
      }
    }

    .footer-social-icons {
      display: flex;
      flex-direction: row;
      @include media(md){
        justify-content: end;
        margin: 0 0 0 auto;
      }
      a{
        font-size: 0;
        padding: 0 4rem 0 1rem;
        color: $base-white;
        @include media(md){
          padding-right: 1rem;
        }
        &:before{
          font-size: 4rem;
        }
      }
      .icon-footer_facebook_icon:hover{
        color: $icon-facebook;
      }
      .icon-footer_instagram_icon:hover{
        color: $icon-instagram;
      }
    }
  }

  .footer-bottom{
    display: flex;
    flex-direction: column;
    width: 100%;
    @include media(md){
      flex-direction: row;
      justify-content: space-between;
    }
    .text-small{
      display: flex;
      color: $base-white;
    }
  }
  }
}

//////////////////////////////////////////// END OF FOOTER /////////////////////////////////////

.is-xxl{
    display:none;
    @include media(xxl){
        display:block;
    }
}

.full-page-spinner{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.75);
  display: none;
  @include spinner();
}

body.loading{
  .full-page-spinner{
    display: block;
    .spinner{
      display: block;
    }
  }
}

body{
  @include spinner($main-color, 0.8, rgb(255,255,255), 8rem, 1rem);
  &.loading{
    &:before{
      position: fixed;
      z-index: 999;
    }
    .spinner{
      position: fixed;
      z-index: 9999;
    }
  }
}

//* __Lazy Load__ */
@keyframes animateLoaded__ {
  0% {
    opacity: 0;
    filter: blur(10px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

[data-lazy-type] {
  &.loaded {
    animation: $imgLoadAnimation;
  }
}

.menu-level-1-li.no-submenu{
  &:after{
    display: none !important;
  }
}

.menu-openner{
  position: absolute;
  width: 60px;
  height: 60px;
  top:0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0 !important;
  @include media($menu-break){
    display: none;
  }
  &:hover{
    color: $color-link;
    @include media($menu-break){
      color: $base-white;
    }
  }
  &:after{
    margin: 0 !important;
  }
  @media ( max-width: 767px ) {
    &:after{
      content:"\e90b";
      font-size: 2rem;
      transition: transform .3s ease;
      margin: 0;
    }
  }

  &.open{
    &:after{
      transform: rotate(180deg);
    }
  }
}
