
@import "../../sass/_mixins/pux/breakpoints.scss";
@import "../../sass/_mixins/pux/css-rules.scss";
@import "../../sass/_mixins/pux/scale.scss";
@import "../../sass/_variables.scss";

.selectric-wrapper {
  position: relative;
  cursor: pointer;
  width: 17rem;
  height: 7rem;
  margin: auto;

}

.selectric-responsive {
  width: 100%;
}

.selectric {
  height: 100%;
  background: $select-background;
  position: relative;
  overflow: hidden;
  border:1px solid $select-border;
  font-weight: 900;
  text-transform: uppercase;
  padding: 0 2rem;
  border-radius: 20px;
  @include flex-block;
  @include align-items(center);
  @include justify-content(space-between);
  .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
    @include pux-scale-with-min("line-height", $select-height, 40px);
    color: $select-color;
    @include pux-scale-with-min("height", $select-height, 40px);
    user-select: none;
    padding:0 2rem 0 2rem;
  }

  .button {
    display: block;
    position: relative;
    right: 0;
    top: 0;
    @include pux-static-size("width",30px);
    height: 100%;
    text-align: center;
    transition: all ease 200ms;
    font-size: 0;
    @include flex-block;
    @include align-items(center);
    @include justify-content(center);
    @if($select-use-triangle == 0){
      &:after {
        @include pux-static-size("font-size", 15px);
        content: $select-arrow-code;
        color: $select-arrow-color;
      }
    }
    @if($select-use-triangle == 1){
      &:before{
        content: "\e90b";
        font-size: .8rem;
        vertical-align: middle;
      }
    }
  }
}

.selectric-focus .selectric {
  border-color: $select-border-focus;
}

.selectric-open {
  z-index: 9999;

  .selectric {
    border-color: $select-border-focus;
    border-bottom: 1px solid $select-border-focus;

    .button {
      @include rotate(180deg);
    }
  }
  .selectric-items {
    display: block;
    width: 100% !important;
    border-color: $select-border-focus;
    border-top: 1px solid $select-border-focus;
  }
}

.selectric-disabled {
  @include opacity(0.5);
  cursor: default;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;

  select {
    position: absolute;
    left: -100%;
  }

  &.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;

    select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0;
    }
  }
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  overflow: hidden;
  display: none;
  position: absolute;
  top: 60%;
  left: 0;
  background-color: darken($select-background,5);
  border: 1px solid $select-border;
  border-top: 1px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: -1;
  padding-top: 2.5rem;

  .selectric-scroll {
    height: 100%;
    overflow: auto;
  }

  .selectric-above & {
    top: auto;
    bottom: 60%;
    padding-top: 0;
    padding-bottom: 2.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;
  }

  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: inherit;
    min-height: 2rem;
  }

  li {
    display: block;
    padding: 1rem 2rem;
    color: $select-color;
    background: $select-background;
    cursor: pointer;
    transition: all ease 200ms;
    &:before{
        display:none;
    }

    &.selected {
      color: $select-color;
    }

    &.highlighted {
      color: $select-color;
    }

    &:hover {
      background: darken($select-background, 2);
      color: $color-link;
    }
    &:nth-child(odd){
        background: darken($select-background, 5);
        &:hover{
            background: darken($select-background, 7);
        }
    }
  }

  .disabled {
    @include opacity(0.5);
    cursor: default !important;
    background: none !important;
    color: $base-black !important;
    user-select: none;
  }

  .selectric-group {
    .selectric-group-label {
      font-weight: bold;
      padding-left: 1rem;
      cursor: default;
      user-select: none;
      background: none;
      color: $base-black;
    }

    &.disabled li {
      @include opacity(1);
    }

    li {
      padding-left: 2.5rem;
    }
  }
}

.list-box{
  .selectric-scroll{
    ul{
      li{
        padding: 1rem 2rem 1rem 1rem;
        &:before{
          content:"";
          width:12px;
          height:12px;
          @include border-radius(0);
          display: inline-block;
          margin:0 1rem 0 0;
          background: darken($select-background, 20);
          vertical-align: middle;
          position: static;
          border:2px solid darken($select-background, 20);
        }
        &.selected{
          &:before{
            background: $main-color;
          }
        }
        &:first-child{
          &:before{
            display: none;
          }
        }
      }
    }
  }
}
