
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


.label-form,
label{
  display: block;
  margin:1rem 0 0.5rem 0;
  @include pux-static-size("font-size", 14px);
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"]{
  -moz-appearance:textfield;
}

.form-control,
select,
select.form-control,
textarea,
textarea.form-control,
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"]{
  width:100%;
  @include pux-scale-with-min("height", $input-height, 40px);
  padding: 0 1.5rem;
  border: 1px solid $border-color;
  background: $input-background;
  color:$base-black;
  @include pux-static-size("font-size", 14px);
  @include border-radius(12px);
  @include placeholder{
    color: $color-placeholder;
  }
  &:focus{
    outline: none;
  }
}

textarea.form-control,
textarea{
  @include pux-scale-with-min("height", $textarea-height, 100px);
  padding: 1.5rem;
  &:focus{
    outline: none;
  }
}

input[type="submit"]{
  cursor: pointer;
  &:focus,
  &:hover{

  }
}

.required{
    label{
        &:before{
            content:"*";
            display: inline-block;
            vertical-align: 0.6rem;
            margin: 0 2px 0 0;
            @include pux-static-size("font-size", 10px);
        }
    }
}

label{
  &.required{
    &:before,
    span:before{
        content:"*";
        display: inline-block;
        vertical-align: 0.2rem;
        margin: 0 2px 0 0;
        color:$error-label-color;
        @include pux-static-size("font-size", 12px);
    }

  }
}

.file{
  .uploader-upload{
      display:none;
  }
  label{
      display:inline-block;
      padding:1rem 2rem;
      text-transform:uppercase;
      color: $upload-color;
      background: $upload-background;
      cursor:pointer;
      @include pux-static-size("font-size", 14px);
      @include border-radius(0);
      max-width: 24rem;
      margin:0;
      width: 100%;
      text-align:center;
      cursor:pointer;
      letter-spacing:1px;
      outline:0 !important;
  }
  .file-name{
      padding:0.5rem;
      background: $file-name-background;
      color: $file-name-color;
  }
}

@if($use-blink-error == 1){
  .form-validation-error,
  .Error:not(.form-validation-valid){
      select,
      .selectric,
      input[type="text"],
      input[type="password"],
      input[type="email"],
      input[type="number"],
      textarea{
          border: 1px solid $error-input-border !important;
          @include box-shadow(inset 0 0 0 1px $error-input-border);
          @include animation(normal, blink, 1s, ease, 2);
      }
  }

  @include keyframes(blink){
      0% {
          background: $error-blink-start;
      }
      50% {
          background: $error-blink-final;
      }
      100% {
          background: $error-blink-start;
      }
  }
}

div.form-validation-valid{
  .FormErrorLabel,
  .EditingFormErrorLabel{
    display: none;
  }
}

.ErrorLabel,
.form-control-error,
div.form-validation-error,
.EditingFormErrorLabel{
    @include pux-static-size("font-size", 14px);
    color:$error-label-color;
    display:block;
    margin:0.5rem 0 0 0;
}

.radio{
  padding: 2rem 0;
  @include media(md){
    padding: 0 0 2rem 0;
  }
    input[type="radio"]{
        display:none;
        & +label{
            position:relative;
            display: inline-block;
            padding: 3px 0 1rem 30px;
            margin: 0 0 0 0;
          @include media(md){
            font-size: 1.8rem;
          }
          @include media(xxl){
            padding: 0 0 1rem 30px;
          }
            &:before{
                content:"";
                @include border-radius(50%);
                width:20px;
                height:20px;
                background:$radio-background;
                display:block;
                position:absolute;
                left:0;
                top:3px;
                border:1px solid $base-grey;
                @include transition;
            }
            &:after{
                content:"";
                display:block;
                left:4px;
                top:7px;
                width:12px;
                height:12px;
                background:$radio-after-background;
                @include border-radius(50%);
                position:absolute;
                @include opacity(0);
                @include transition;
            }
        }
        &:checked{
            & +label{
                &:before{
                    border:1px solid $radio-active-border;
                }
                &:after{
                    @include opacity(1);
                }
            }
        }
    }
}

.checkbox{
    input[type="checkbox"]{
        display:none;
        & +label{
            position:relative;
            padding:3px 0 1rem 30px;
            margin:0 0 0 3.5rem;
            display: inline-block;
            &:before{
                content:"";
                width:20px;
                height:20px;
                background:$checkbox-background;
                display:block;
                position:absolute;
                left:0;
                top:3px;
                border:1px solid $base-grey;
                border-radius: 5px;
                @include transition;
            }
            &:after{
                content:"\2713";
                display:block;
                left:2px;
                top:2px;
                position:absolute;
                color: $main-color;
                font-weight: bold;
                font-size: 3.5rem;
                @include opacity(0);
                @include transition;
              @include media(md){
                font-size: 2.5rem;
              }

            }
        }
        &:checked{
            & +label{
                &:before{
                    border:1px solid $checkbox-active-border;
                }
                &:after{
                    @include opacity(1);
                }
            }
        }
    }
}

.row-form{
  @include flex-block;
  @include flex-wrap(wrap);
  margin:0 -1rem;
}

.row-form{
  [class^="col-"]{
    padding-left:1rem;
    padding-right:1rem;
  }
}
