@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

body{
  overflow-x: hidden;
}

h1,h2{
  text-align: center;
  padding: 3rem 0;
  @include media(md){
    text-align: left;
  }
}

.main-top-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .main-breadcrumb-nav {
      padding: 4rem 0 4rem 0;
      z-index: 50;
      position: relative;
      width: 80%;
      font-size: 2.5rem;
      @include media(md){
        font-size: 2rem;
      }
      a {
        text-transform: uppercase;
        color: $base-black;
        &:hover{
          color: $main-color;
        }
      }
      span {
        text-transform: uppercase;
        color: $base-grey;
      }
      a:after {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $base-grey;
        display: inline-block;
        margin: 0px 7px 3px 11px
      }
    }
  .full-width{
    width: 100%;
  }

    .main-search-bar {
      display: block;
      background: #fff;
      position: absolute;
      right: 10px;
      z-index: 70;
      height: 8rem;
      margin: 2rem 0;
      box-shadow: -6px 0px 5px 5px rgba(255,255,255,1);
      @include media(md) {
        width: 35rem;
        height: 7rem;
        position: relative;
        right: initial;
      }
      @include media(lg) {
        height: 6rem;
      }
      @include media(xl) {
        height: 5rem;
      }
      form{
        border: 1px solid $base-grey;
        border-radius: 20px;
      }
      .input-group {
        height: 100%;
        padding: 1.5rem 1.5rem;
        @include media(xl) {
          padding: 1rem 1.5rem;
        }
        input {
          font-size: 2.5rem;
          height: 100%;
          width: 0;
          transition: all 0.5s ease;
          @include media(md){
            width: initial;
            font-size: 1.5rem;
          }
          &::placeholder {
            font-size: 0;
            @include media(md){
              font-size: 1.5rem;
            }
          }
        }

        input[type=search]{
        &:focus {
          width: 40rem;
          @include media(md){
            width: 0;
          }
        }
          &:focus::placeholder {
            font-size: 2.5rem;
            @include media(md){
              font-size: 1.5rem;
            }
          }

      }
      }

      button {
        cursor: pointer;
        padding: 0.7rem 0.5rem;
        @include media(md){
          padding: 1rem 0.5rem;
        }
        @include media(lg){
          padding: 0.5rem 0.5rem;
        }
        @include media(xl){
          padding: 0 0.5rem;
        }
        &::before{
          content: "\e90e";
          font-size: 3.3rem;
          color: $base-black;
          margin: 0;
          @include media(md){
            font-size: 1.8rem;
          }
        }
        &:hover::before{
          color: $main-color;
        }

        img {
          width: 10px;
        }
      }
    }
  }

.buttons-section{
    padding-top: 5rem;
    max-width: 800px;
    h2{
      padding-bottom: 3rem
    }
    .components-button{
      display: inline-block;
      width: auto;
      margin-right: 3rem;
      h4{
        padding-bottom: 0.5rem;
      }
    }
  }

.hover{
  background-color: $color-link;
}

.background-dark{
  background-color: $base-black;
  color: $base-white;
  padding: 1rem 1rem;
}

.colors-section{
    max-width: 90%;
    padding: 4rem 0 0 0;
    .color-badge{
      display: inline-block;
      width: 200px;
      height: 200px;
      padding: 1rem 1rem;
      margin: 1rem -0.3rem 0 0;
      color: $base-white;
      background-color: $main-color;

      h4{
        font-size: 1.5rem;
        font-weight: normal;
      }
      h4:last-of-type{
        padding-bottom: 10rem;
        font-size: 1.2rem;
      }
    }
    .badge-hover-primary{
      background-color: $color-link;
    }
    .badge-text{
      background-color: $base-black;
    }
    .badge-dark-grey{
      background-color: $dark-grey;
    }
    .badge-helper{
      background-color: $light-grey;
      color: $base-black;
    }
    .badge-space{
      background-color: $base-white;
      color: $base-black;
    }
    .badge-redness{
      background-color: $error-label-color;
    }
    .badge-news{
      background-color: $color-news;
    }
  }

.form{
    width: 100%;
    padding-bottom: 2rem;
    h2{
      padding-top: 5rem;
    }
    label{
      width: 100%;
      font-size: $font-size-small;
    }
    input{
      width: 100%;
      max-width: 400px;
      height: 9rem;
      border-radius: 12px;
      border: 1px solid $base-grey;
      padding: 0 3rem;
      margin-bottom: 2rem;
    }
    textarea {
      border-radius: 12px;
    }
  }

.error-message span{
    color: $error-label-color;
  }

//////////////////////////////////////////////////        MAIN WRAPPER        /////////////////////////////////////////////
  .main-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: stretch;
  }

  .main-wrapper-sidebar {
    z-index: 1000;
    position: fixed;
    left: 0;
    top: 0;
    margin-left: -250px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $light-grey;
    transition: all 0.5s ease;
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 4rem 0 4rem 3rem;
    color: white;
    @include media(md) {
      width: 300px;
      left: -50px;
      margin-left: -300px;
    }
    @include media(lg) {
      position: relative;
      margin: 0;
      left: 0;
      padding: 2rem 0;
      background: none;
      z-index: 75;
      min-width: 200px;
      max-width: 200px;
    }
    @include media(xl){
      min-width: 250px;
      max-width: none;
    }
    @include media(xxl){
      width: 300px;
    }

    > a {
      &::before {
        content: "\e90f";
        position: absolute;
        right: 20px;
        font-size: 3rem;
        top: 18px;
        color: $base-black;
        transform: rotate(90deg);
        @media ( min-width: 360px ) {
          font-size: 4rem;
        }
        @include media(sm) {
          font-size: 3rem;
        }
        @include media(md) {
          font-size: 2rem;
          top: 25px;
        }
        @include media(lg) {
          content: "";
        }
      }

      &:hover::before {
        color: $main-color;
      }

    }

    h4 {
      color: $base-black;
      padding-bottom: 1rem;
      text-transform: uppercase;
      font-weight: bold;
      padding: 0;
      font-size: 3rem;
      @media ( min-width: 360px ) {
        font-size: 3.5rem;
      }
      @include media(sm) {
        font-size: 3rem;
      }
      @include media(md) {
        font-size: 2.5rem;
      }
      @include media(lg) {
        font-size: 2rem;
      }
    }


    .sidebar-nav-header {
      padding: 20px;
    }

    ul {
      padding: 2rem 0 4rem 0;
      list-style: none;
      font-size: 3rem;
      @media ( min-width: 360px ) {
        font-size: 3.5rem;
      }
      @include media(sm) {
        font-size: 3rem;
      }
      @include media(md) {
        font-size: 2.5rem;
      }
      @include media(lg) {
        font-size: 2rem;
        padding: 0 0 4rem 0;
      }
    }

    ul li.active > a,
    a[aria-expanded="true"] {
      color: black;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 3rem;
      @media ( min-width: 360px ) {
        font-size: 3.5rem;
      }
      @include media(sm) {
        font-size: 3rem;
      }
      @include media(md) {
        font-size: 2.5rem;
      }
      @include media(lg) {
        font-size: 2rem;
      }
    }

    ul li {
      padding: 2rem 0;
      margin: 0;
      @include media(md) {
        padding: 1rem 0;
      }
      @include media(lg) {
        padding: 0.3rem 0;
      }

      a {
        line-height: 3.5rem;
        display: flex;
        align-items: center;
        color: $base-black;
      }

      ul {
        padding: 2rem 0 0 0;
      }
      a:hover {
        color: $main-color;
      }
    }

    ul li::before {
      content: none;
    }
    .more-colors{
      display: none;
      &.open{
        display: block;
      }
    }
    .color-helper{
      padding:0 0 4rem 0;
    }
    .open-colors-box{
      padding: 1.5rem 0 0 0;
    }
    .sidebar-colors{
      padding: 0;
        a::before {
        display: flex;
        content: "";
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        margin-right: 3rem;
        @include media (md) {
          width: 3rem;
          height: 3rem;
        }
        @include media (lg) {
          width: 2.5rem;
          height: 2.5rem;
          margin-right: 2rem;
        }
      }
      .sidebar-colors-blue a::before{
        background: #0b9deb;
      }
      .sidebar-colors-red a::before{
        background: #dd2525;
      }
      .sidebar-colors-black a::before{
        background: #000000;
      }
      .sidebar-colors-green a::before{
        background: #36c773;
      }
      .sidebar-colors-yellow a::before{
        background: #fdc300;
      }
      .sidebar-colors-gray a::before{
        background: #808080;
      }
      .sidebar-colors-white a::before{
        background: #fff;
        border: 1px solid $base-grey;
      }
      .sidebar-colors-orange a::before{
        background: #ff5d00;
      }
      .sidebar-colors-purple a::before{
        background: #9025ff;
      }
      .sidebar-colors-darkblue a::before{
        background: #0f2e6f;
      }
      .sidebar-colors-navy-blue a::before{
        background: #000080;
      }
      .sidebar-colors-olive a::before {background: #808000; }
      .sidebar-colors-azure a::before {background: #add8e6; }
      .sidebar-colors-charcoal a::before {background: #2b2d2f; }
      .sidebar-colors-pink a::before {background: #ffc0cb; }
      .sidebar-colors-khaki a::before {background: #c3b091; }
      .sidebar-colors-royal-blue a::before {background: #002366; }
      .sidebar-colors-brown a::before {background: #a52a2a; }
      .sidebar-colors-sand a::before {background: #c2b280; }
      .sidebar-colors-ash a::before {background: #b2beb5; }
      .sidebar-colors-cream a::before {background: #fffdd0; }
      .sidebar-colors-turquoise a::before {background: #40e0d0; }
      .sidebar-colors-bronze a::before {background: #cd7f32; }
      .sidebar-colors-burgundy a::before {background: #800020; }
      .sidebar-colors-gold a::before {background: #d4af37; }
      .sidebar-colors-camo a::before {background: url('../img/pictures/camo.jpg'); }
    }
  }

  .page-content-wrapper .btn-default{
    display: flex;
    height: 9rem;
    padding: 2rem 4rem;
    border: none;
    background-color: #f7f7f7;
    text-transform: uppercase;
    font-size: 3rem;
    @include media(md){
      height: 6rem;
      font-size: 2.5rem;
    }
    @include media(lg){
      display: none;
    }
    &:hover{
      background-color: $base-black;
      color: $base-white;
    }
  }
  .main-wrapper.open .main-wrapper-sidebar{
    margin-left: 0;
    @include media(md){
      margin-left: 50px;
    }
    @include media(lg){
      margin-left: 0;
    }
  }



.pux-container .main-wrapper{
  [class^="col-"]{
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.main-wrapper-content{
  display: flex;
  width: 100%;
  flex-direction: column;
  min-width: 0;
}




  a[data-toggle="collapse"]{
    position: relative;
  }
  a, a:hover, a:focus{
    text-decoration: none;
    transition: all 0.3s;
  }
  .dropdown-toggle::after{
    content: "\e90f";
    font-size: 1.5rem;
    display: block;
    position: absolute;
    right: 20px;
    width: auto;
    height: auto;
    border: none;
    margin: 0;
    transition: transform .2s ease-in-out;
    @include media(lg){
      font-size: 1rem;
    }
  }
  .dropdown-toggle.open {
    &::after {
      transform: rotate(180deg);
    }
  }

  .collapse:not(.show){
    display: none;
  }



/////////////////////////////////////////////   PRODUCTS - FAVOURITES   //////////////////////////////////////////////

  .products-favourites{
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
    height: 65rem;
    @include media(sm){
      height: 62rem;
    }
    @include media(md){
      height: auto;
    }

    h4{
      text-transform: uppercase;
      font-weight: bold;
      padding-bottom: 1.5rem;
    }
    .products-favourites-inner{
      display: flex;
      flex-direction: column;

      @include media(md){
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

  .card-item-outer {
    display: flex;
    width: 100%;
    padding: 2rem 1rem;
    max-height: 18rem;
    @include media(sm){
      padding: 3rem 1rem;
    }
    @include media(md){
      max-height: 11rem;
    }

    .card-item{
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 15rem;
      background-color: $base-white;
      color: $base-black;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 20px;
      transition: box-shadow .3s;
      @media ( min-width: 360px ) {
        min-width: 300px;
      }
      @include media(md) {
        height: 9rem;
      }
      @include media (xl) {
        min-width: 270px;
      }

    .card-item-img{
      display: flex;
      width: auto;
      margin: auto 2.5rem;
      @media ( min-width: 360px ) {
        margin: auto 3rem;
      }
      @include media(md) {
        margin: auto 1.5rem;
      }
      @include media(xl) {
        margin: auto 1rem;
      }
      img{
        align-self: center;
        width: 12rem;
        @include media(md) {
          width: 7rem;
        }
      }
    }

    .card-item-body{
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      padding-left: 0;
      h4{
        font-size: 3.2rem;
        text-transform: none;
        margin: 0;
        @media ( min-width: 360px ) {
          font-size: 3.5rem;
        }
        @include media(md){
          font-size: 2rem;
          padding-bottom: 0;
          padding-top: 0.5rem;
        }
        @include media(xl){
          font-size: 1.8rem;
        }
      }
      h3{
        font-size: 3rem;
        padding: 1rem 0 0 0;
        @include media(md) {
          font-size: 1.8rem;
        }
      }
    }
      &:hover{
        box-shadow: 0 0 11px rgba(33,33,33,.2);
      }
    }
  }
  }

/////////////////////////////////////////////       PRODUCTS - SORT         //////////////////////////////////////////

  .products-sort {
    display: flex;
    justify-content: space-around;
    padding: 2rem 0;
    @include media(sm){
      justify-content: space-between;
    }
    @include media(lg){
      justify-content: flex-start;
    }
  .selectric-wrapper{
    width: 40rem;
    height: 9rem;
    margin: 0;
    line-height: 6rem;
    @media ( min-width: 360px ) {
      width: 42rem;
    }
    @include media(md){
      width: 35rem;
      height: 6rem;
      line-height: 4rem;
    }
    @include media(lg){
      display: none;
    }
    .selectric{
      border-radius: 40px;
      border: none;
      background-color: $light-grey;
      .label{
        font-size: 2.7rem;
        color: $base-black;
        font-weight: bold;
        @media ( min-width: 360px ) {
          font-size: 3rem;
        }
        @include media(md){
          font-size: 2.5rem;
        }
      }
    }
    .selectric-items{
      border-color: darken($select-background, 5);
      padding-top: 3rem;
      ul li{
        text-transform: uppercase;
        font-weight: bold;
        font-size: 2.7rem;
        padding-left: 3.5rem;
        @media ( min-width: 360px ) {
          font-size: 3rem;
        }
        @include media(md){
          font-size: 2.5rem;
        }
      }
    }
  }
    .selectric:hover{
      background-color: $base-black;
      color: $base-white;
    }
    .selectric:hover .label{
      color: $base-white;
    }
    .selectric-items li:hover,
    .selectric-items li:nth-child(odd):hover{
      transition: all .2s ease;
      background-color: $base-black;
      color: $base-white;
    }

    .page-content-wrapper .btn-default{
      display: flex;
      font-size: 2.7rem;
      @media ( min-width: 360px ) {
        font-size: 3rem;
      }
      @include media (md) {
        font-size: 2.5rem;
      }
      @include media(lg){
        display: none;
      }
    }
    .btn-default {
      display: none;
      @include media(lg){
        display: flex;
        text-transform: uppercase;
        background-color: $light-grey;
        padding: 10px 20px;
        border: none;
        margin-right: 1rem;
        &:hover,
        &.active {
        background-color: $base-black;
        color: $base-white;
        }
      }
    }
  }

/////////////////////////////////////////////   PRODUCTS - CATALOGUE   //////////////////////////////////////////////

  .product-slider{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding:0 30px;
    .slick-slider{
      display: block;
    }
    .slick-arrow{
      position: absolute;
      top:50%;
      transform: translate(0, -50%);
      border: none;
      background: none;
      font-size: 0;
      z-index: 88;
      outline: 0;
      margin-top:-1rem;
      cursor: pointer;
      &:before{
        font-size: 30px;
      }
    }
    .slick-prev{
      left:0;
      &:before{
        content:"\e910";
      }
    }
    .slick-next{
      right: 0;
      &:before{
        content:"\e911";
      }
    }
  }

  .products-catalogue .product-slider .card-item-outer{
    max-width: 100%;
  }

  .products-catalogue{
    display: flex;
    flex-wrap: wrap;
    padding: 3rem 0;
    .card-item-outer {
      display: flex;
      width: 90%;
      margin: auto;
      padding: 0 0.5em 1em 0.5em;
      text-align: center;
      max-width: 320px;
      @include media(sm){
        width: 50%;
      }
      @include media(md) {
        width: 33%;
        padding: 0 0.5em 1em 0.5em;
        text-align: center;
      }
      @media ( min-width: 1330px ) {
        width: 25%;
      }
      .card-item{
        width: 100%;
        color: $base-black;
      }
    }


  .card-item{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: 100%;
    background-color: $base-white;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 1.5rem;
    transition: box-shadow .3s;
    img{
      width: auto;
      align-self: center;
      margin: 10% auto 0 auto;
    }
    .card-label{
      position: absolute;
      right: 3rem;
      top: 1em;
      @include media(md){
        right: 2rem;
      }
    }
    .card-item-body{
      padding: 2rem 2.5rem;
    }
    .card-title{
      //height: 1.5em;
      font-weight: bold;
      padding: 1rem 0 1rem 0;
      font-size: 3.5rem;
      margin: 0;
      line-height: 1.7;
      @include media(sm){
        font-size: 3rem;
      }
      @include media(md){
        font-size: 2rem;
      }
      @include media(lg){
        font-size: 1.8rem;
      }
      @include media(xl){
        font-size: 1.7rem;
      }
    }
    .card-price{
      font-weight: bolder;
    }
    .badge-primary,
    .badge-secondary{
      font-size: 2.5rem;
      padding: 0.5rem 1rem;
      border-radius: 41px;
      @include media (sm){
        font-size: 2.5rem;
      }
      @include media(md){
        font-size: 1.6rem;
      }
    }
    .badge-primary{
      background-color: $color-news;
    }
    .main-label{
      display: block;
      font-size: 2.8rem;
      @include media(sm){
        font-size: 2.5rem;
      }
      @include media(md){
        font-size: 1.8rem;
      }
      @include media(lg){
        font-size: 1.5rem;
      }

    }
    &:hover{
        box-shadow: 0 0 11px rgba(33,33,33,.2);
    }
  }
  }
//////////////////////////////////////////////      PAGINATION      ////////////////////////////////////////////////
  .products-pagination{
    display: flex;
    flex-direction: column;
    padding: 2rem 0 3rem 0;
    justify-content: center;
    @include media(xl){
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    nav {
      @include media(xl){
        padding-top: 1rem;
      }
      ul li {
      font-size: 2.5rem;
      @media (min-width: 360px) {
        font-size: 2.8rem;
      }
      @include media(lg) {
        font-size: 2rem;
      }

      &::before {
        display: none;
      }

      .page-first,
      .page-last {
        border-radius: 20px;
      }

      a {
        background-color: $base-white;
        color: $base-black;
        border-radius: 50%;
        border: none;
        font-weight: bold;
        padding: 0.5rem 1.1rem;

        &:hover {
          background-color: $base-black;
          color: $base-white;
        }
      }
    }
    }
    .pagination-items-per-page:first-child{
      visibility: hidden;
    }
    .pagination-items-per-page{
      display: flex;
      justify-content: center;
      @include media(xl){
        justify-content: right;
        width: 30%;
        flex-direction: column;
        align-items: end;
        padding-right: 1rem;
      }

      > span{
        font-size: 2.4rem;
        line-height: 7rem;
        @include media(md){
          line-height: 5rem;
          padding-left: 0.5rem;
          font-size: 2rem;
        }
        @include media(lg){
          font-size: 1.8rem;
        }
        @include media(xl){
          line-height: 2.6rem;
          font-size: 1.4rem;
          margin:0 0 0 auto;
        }
      }

      .selectric-wrapper{
        margin: auto 0 auto 3rem;
        width: 14rem;
        height: 6rem;
        @media ( min-width: 360px ){
          margin:0 0 0 3rem;
        }
        @include media(md){
          width: 12rem;
          height: 5rem;
        }
        @include media(xl){
          width: 8rem;
          height: 3rem;
          margin:0 0 0 auto;
        }
        .selectric-items{
          @include media (md) {
            padding-top: 1.5rem;
          }
          li {
            padding: 0.5rem 1.5rem;
          }
        }
        .selectric{
          padding: 0 1rem;
          span{
            padding: 0 0 0 0.5rem;

          }
          .button::before{
            content: "\e90f";
          }
        }
      }
    }
  }

  .form-control:focus {
    box-shadow: none;
  }

  .form-control::placeholder {
    font-size: 1.5rem;
    color: $dark-grey;
  }



  /////////////////////////////////////////////     SELECT NUMBER & SIZE     /////////////////////////////////////////

  .product-purchase-inputs{
    display: flex;
    flex-direction: row;
    padding: 3rem 0;
    @include media(lg){
      padding: 0;
    }
    &-inner{
      width: 100%;
      span{
        padding-left: 2rem;
      }
    }
    .select-size{
      width: 95%;
      height: 10.5rem;
      font-size: 3rem;
      border: 1px solid gray;
      border-radius: 20px;
      margin: 1rem auto;
      @include media(lg){
        height: 7rem;
      }
    }

    .input-group{
      width: 95%;
      height: 10.5rem;
      border: 1px solid gray;
      border-radius: 20px;
      margin: 1rem auto;
      padding: 0 2rem;
      @include media(lg){
        height: 7rem;
        padding: 0 1rem;
      }
      .input-group-prepend,
      .input-group-append{
        display: flex;
        width: 30%;
        height: inherit;
        justify-content: center;
        align-items: center;
        margin: auto;
      }
      .btn-decrement,
      .btn-increment{
        display: flex;
        width: 100%;
        background:none;
        border-radius: 50%;
        cursor: pointer;
        padding: 0;
        margin: auto auto;
        &:disabled{
          opacity: 1;
          cursor: auto;
          &::before{
            color: $base-grey;
          }
        }
        &:not([disabled]):hover::before{
          transition: font-size .2s;
          font-size: 4.5rem;
          @include media(sm){
            font-size: 4rem;
          }
          @include media(md){
            font-size: 3.5rem;
          }
          @include media(lg){
            font-size: 3rem;
          }
          @include media(xl){
            font-size: 2.5rem;
          }
        }
      }
      .btn-increment{
        &::before{
          display: flex;
          content: "\e90a";
          color: $main-color;
          margin: 0;
        }
        &:not([disabled]):hover::before{
          color: $increment-hover;
        }
      }

      .btn-decrement{
        &::before{
          display: flex;
          content: "\e909";
          color: $decrement-color;
          margin: 0;
        }
        &:not([disabled]):hover::before{
          color: $decrement-hover;
        }
      }

      input{
        font-size: 3rem;
        height: inherit;
        text-align: center;
        border: none;
        padding: 0;
        background: transparent;
      }
    }
  }

.product-menu{
  li {
    position: relative;
    ul{
      display: none;
    }
    &.open{
      ul{
        display: block;
      }
      .submenu-open{
        &:after{
          @include rotate(180deg);
        }
      }
    }
    a.active{
      color:$main-color;
    }
  }
  .submenu-open{
    position: absolute;
    height: 3.5rem;
    display: flex;
    align-items: center;
    width: 30px;
    justify-content: center;
    font-size: 0;
    top:2rem;
    right:10px;
    cursor: pointer;
    padding: 1rem 0;
    margin: 0;
    @include media(md) {
      top: 1rem;
      padding: 1.5rem 0;
    }
    @include media(lg) {
      top: 0.3rem;
      padding: 2rem 0;
    }
    &:after{
      content: "\e90f";
      font-size: 8px;
      color:$base-black;
      transition: 200ms;
    }
  }
}
