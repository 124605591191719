
@import "../../../../sass/_mixins/pux/breakpoints.scss";
@import "../../../../sass/_mixins/pux/css-rules.scss";
@import "../../../../sass/_mixins/pux/pux-content.scss";
@import "../../../../sass/_mixins/pux/scale.scss";
@import "../../../../sass/_variables.scss";

// gallery type
// 1 = horizontal
// 2 = vertical
// 3 = carousel
// 4 = load more

$gallery-type: 4;

// main image
$main-image-padding: 2rem;
$main-image-bg: #fff;
$main-image-margin: 0 0 2rem 0;
$main-image-height: 52rem;

// laoder
$loader-track-color: $second-color;
$loader-body-color: $main-color;
$loader-size: 6rem;
$loader-weight: 1rem;

// arrows setting
$arrow-color: #fff;
$arrow-bg: #406ff9;
$arrow-height: 2rem;
$arrow-width: 2rem;
$arrow-border-radius: 50%;
$arrow-font-size: 1.6rem;
$arrow-prev-ico: "<";
$arrow-next-ico: ">";

// thumb setting
$thumb-height: 10rem;
$thumb-padding-gutter: 2rem;
$thumb-bg-color: #fff;
$thumb-padding-inner: 0;
$thumb-border-width: 0.1rem;
$thumb-height-mobile: 24rem;
$thumb-active-rgba: rgba(255,255,255,0.5);
$thumb-active-border: $main-color;

// load  setting
$thumb-count: 3;
$btn-background-color: $second-color;
$btn-text-color: #fff;
$btn-font-size: 22px;
$btn-font-weight: 700;

// vertical differencies
$carousel-right-space: 2rem;
$vertical-switch-breakpoint: lg;

// carousel differencies
$carousel-thumb-height: 24rem;

// calcs
$vertical-carousel-height: $main-image-height + $thumb-padding-gutter;
$vertical-thumb-height: ($vertical-carousel-height/4);

//spinner keyframe
@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// MIXINS
@mixin hide-thumbs($startAt) {
    @for $i from 0 + 1 through $startAt {
        &:nth-of-type(#{$i}) {
            display: block;
        }
    }
}

@if($gallery-type == 1 or $gallery-type == 2 or $gallery-type == 3){

.slick-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    @include transform(translate(0%, -50%));
    font-size: 0px;
    width: $arrow-width;
    height: $arrow-height;
    background-color: $arrow-bg;
    color: $arrow-color;
    border: 0;
    border-radius: $arrow-border-radius;
    cursor: pointer;
    @include transition(350ms);

    &:before {
        font-family: "Roboto", sans-serif;
        font-size: $arrow-font-size;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &.slick-disabled {
        border-color: #ddd;
    }

    &:hover {
        background-color: darken($arrow-bg, 10);
    }
}

}

@if($gallery-type == 1 or $gallery-type == 2 or $gallery-type == 4){

  .pux-gallery {
      &-main {
          display: block;
          background-color: $main-image-bg;
          padding: $main-image-padding/2;
          margin: $main-image-margin;
          height: $main-image-height/1;
          width: 100%;
          cursor: pointer;

          @include media(md) {
              display: block;
              height: $main-image-height/1;
              padding: $main-image-padding;
          }

          @include media(xl) {
              height: $main-image-height;
          }

          &-image {
              @include flex-block();
              margin-bottom: 0;
              text-align: center;
              align-items: center;
              justify-content: center;
              height: $main-image-height/1 - ($main-image-padding*2);
              z-index: 1;

              @include media(md) {
                  height: $main-image-height/1 - ($main-image-padding*2);
              }

              @include media(xl) {
                  height: $main-image-height - ($main-image-padding*2);
              }

              img {
                max-width: 100%;
                max-height: 100%;
                height: auto;
              }
          }

          &.loading {
              position: relative;

              &:before {
                  content: '';
                  z-index: 2;
                  position: absolute;
                  top: 0;
                  left: 0;
                  bottom: 0;
                  right: 0;
                  background-color: $main-image-bg;
                  opacity: 0.8;
              }

              &:after {
                  content: '';
                  z-index: 3;
                  border: $loader-weight solid $loader-track-color;
                  /* Light grey */
                  border-top: $loader-weight solid $loader-body-color;
                  /* Blue */
                  border-radius: 50%;
                  width: $loader-size;
                  height: $loader-size;
                  animation: loader 2s linear infinite;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  margin-top: -$loader-size/2;
                  margin-left: -$loader-size/2;
              }
          }
      }
  }

  .IE{
    .pux-gallery-main-image img{
      width: 100%;
    }
  }
}

@if($gallery-type == 1 or $gallery-type == 2){
  // horizontal code
  .pux-gallery {

      &-carousel {
          position: relative;
          height: $thumb-height-mobile;
          overflow: hidden;
          width: 100%;

          @include media(md) {
              height: $thumb-height;
          }

          &-slick {
              margin: 0 $arrow-width + ($thumb-padding-gutter/2);
              position: initial;

              .slick-slide {
                  padding-left: $thumb-padding-gutter/2;
                  padding-right: $thumb-padding-gutter/2;
                  margin: 0;
                  border: 0;

                  &-inner {
                      height: $thumb-height-mobile;

                      @include media(md) {
                          height: $thumb-height;
                      }

                      @include flex-block;
                      align-items: center;
                      justify-content: center;
                      text-align: center;
                      padding: $thumb-padding-inner;
                      background-color: $thumb-bg-color;
                      border: $thumb-border-width solid $thumb-bg-color;

                      img {
                          width: 100%;
                          height:100% !important;
                          object-fit: contain;
                          font-family: 'object-fit: contain';
                      }
                  }
              }

              .slick-prev {
                  left: 0;

                  &:before {
                      content: $arrow-prev-ico;
                  }
              }

              .slick-next {
                  right: 0px;

                  &:before {
                      content: $arrow-next-ico;
                  }
              }
          }
      }
  }
}

@if($gallery-type == 2){
  // vertical code
  .pux-gallery {
      &-container {
          &.vertical {
              @include flex-block();
              @include flex-flow(row, wrap);

              .pux-gallery-main {
                position: relative;
                  @include media($vertical-switch-breakpoint) {
                      @include flex(80%);
                      @include order(2);
                      margin: ($arrow-width + ($thumb-padding-gutter/2)) 0;
                      height: $main-image-height - $thumb-padding-gutter;
                      max-width: 80%;
                  }
              }

              .pux-gallery-carousel {
                  @include media($vertical-switch-breakpoint) {
                      height: $vertical-carousel-height + ($arrow-width*2);
                      @include flex(20%);
                      @include order(1);
                      padding-right: $carousel-right-space;
                      max-width: 20%;

                      &-slick {
                          margin: $arrow-width 0;
                          position: relative;

                          .slick-slide {
                              padding-left: 0px;
                              padding-right: 0px;
                              padding-top: $thumb-padding-gutter/2;
                              padding-bottom: $thumb-padding-gutter/2;
                              height: $vertical-thumb-height;

                              &-inner {
                                  height: $vertical-thumb-height - $thumb-padding-gutter;
                                  @include flex-block;
                                  align-items: center;
                                  justify-content: center;
                                  text-align: center;
                                  img {
                                    width: 100%;
                                    height:100% !important;
                                    object-fit: contain;
                                    font-family: 'object-fit: contain';
                                  }
                              }
                          }

                          .slick-arrow {
                              top: auto;
                              left: 50%;
                              @include transform(translate(-50%, 0));
                              &:before{
                                display: inline-block;
                              }
                          }

                          .slick-prev {
                              top: -2rem;
                              &:before{
                                @include rotate(-90deg);
                              }
                          }

                          .slick-next {
                              bottom: -2rem;
                              &:before{
                                @include rotate(90deg);
                              }
                          }
                      }
                  }
              }
          }
      }
  }
}

@if($gallery-type == 3){
  // carousel gallery
  .pux-gallery {
      &-container {
        @include slick-arrows();
          &.carousel {
              .pux-gallery-carousel {
                  height: 3.2rem;

                  @include media(md) {
                      height: $carousel-thumb-height;
                  }

                  &-slick {
                      .slick-slide {
                          &-inner {
                              height: 3.2rem;
                              @include flex-block;
                              align-items: center;
                              justify-content: center;
                              text-align: center;
                              padding-left: $thumb-padding-gutter/2;
                              padding-right: $thumb-padding-gutter/2;
                              @include media(md) {
                                  height: $carousel-thumb-height;
                              }

                              img {
                                  width: 100%;
                                  height:100% !important;
                                  object-fit: contain;
                                  font-family: 'object-fit: contain';
                              }
                          }
                      }
                  }
              }
          }
      }
  }
}

@if($gallery-type == 4){
  // load more gallery
  .pux-gallery {
      &-container {
          overflow: hidden;
      }

      &-more {
          margin-left: -$thumb-padding-gutter/2;
          margin-right: -$thumb-padding-gutter/2;
          @include flex-block;
          @include flex-wrap(wrap);

          &-thumb {
              width: 25%;
              transition: all 350ms linear;
              padding-left: $thumb-padding-gutter/2;
              padding-right: $thumb-padding-gutter/2;
              margin-bottom: $thumb-padding-gutter;
              display: none;

              figure {
                  width: 100%;
                  margin: 0;
                  border: 0;
                  overflow: hidden;
                  @include flex-block;
                  @include flex-direction(row);

                  a {
                      //@include pux-static-size("height", (($thumb-height / 1px)*10));
                      @include flex-block;
                      align-items: center;
                      justify-content: center;
                      text-align: center;
                      width: 100%;
                      padding: $thumb-padding-inner;
                      background-color: $thumb-bg-color;
                      border: $thumb-border-width solid $thumb-bg-color;
                      @include flex-basis(100%);
                      height: 15rem;
                      @include media(xs){
                          height: 20rem;
                      }
                      @include media(sm){
                          height: 20rem;
                      }
                      @include media(md){
                          height: 12rem;
                      }
                      @include media(lg){
                          height: 12rem;
                      }
                      @include media(xl){
                          height: 15rem;
                      }

                      &:after{
                        display: none;
                      }

                      img {
                          width: 100%;
                          height:100% !important;
                          object-fit: contain;
                          font-family: 'object-fit: contain';
                      }

                      &.active{

                          position: relative;
                          &:before{
                            content:"";
                            width:100%;
                            height: 100%;
                            top:0;
                            left:0;
                            position: absolute;
                            z-index: 22;
                            border: 4px solid $thumb-active-border;
                            border-radius: 20px;
                          }
                        
                     }
                  }
              }

              &.load-more{
                display: none !important;
                &.show{
                  @include flex-block(1);
                }
              }

              @include hide-thumbs($thumb-count);
          }

          &-btn {
              @include flex-block;
              @include flex-direction(row);
              @include pux-scale-with-min("font-size", $btn-font-size, 16px);
              color: $btn-text-color;
              font-weight: $btn-font-weight;
              min-height: $thumb-height;
              overflow: hidden;
              transition: all 350ms linear;
              width: 100%;

              &-inner{
                  min-height: $thumb-height;
                  width: 100%;
                  background-color: $btn-default-background;
                  color: $dark-grey;
                  transition: 200ms;
                  cursor: pointer;
                  @include flex-block;
                  @include flex-direction(column);
                  @include align-items(center);
                  @include justify-content(center);
                  &:hover {
                      background-color: darken($btn-default-background, 5);
                  }
              }
          }

          &.open &-thumb {
              display: block;
          }

          &.open &-btn {
              display: none;
          }
      }
  }
}
