
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
    font-family: 'Core-icons' !important;
}

*:before,
*:after{
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.helper-4k{
  max-width: 200rem;
  margin:auto;
}

.hide{
  display: none !important;
}

.show{
  display:block !important;
}

.text-center{
  text-align: center;
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

.bold{
  font-weight: bold;
}

.underline{
  text-decoration: underline;
}

.line-through{
  text-decoration: line-through;
}

html {
		font-size: $scale-base-size*$scale-ratio-xxs;
		@include media(xs) {
				font-size: $scale-base-size*$scale-ratio-xs;
		}
		@include media(sm) {
				font-size: $scale-base-size*$scale-ratio-sm;
		}
		@include media(md) {
				font-size: $scale-base-size*$scale-ratio-md;
		}
		@include media(lg) {
				font-size: $scale-base-size*$scale-ratio-lg;
		}
		@include media(xl) {
				font-size: $scale-base-size*$scale-ratio-xl;
		}
		@include media(xxl) {
				font-size: $scale-base-size*$scale-ratio-xxl;
		}
}

@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
       only screen and(      -o-min-device-pixel-ratio: 5/4 ),
       only screen and( min-resolution: 120dpi ),
       only screen and( min-resolution: 1.25dppx ) {
		html {
				font-size: $scale-base-size*$scale-ratio-xxs;
				@include media(xs) {
						font-size: $scale-base-size*$scale-ratio-xs;
				}
				@include media(sm) {
						font-size: $scale-base-size*$scale-ratio-sm;
				}
				@include media(md) {
						font-size: $scale-base-size*$scale-ratio-md;
				}
				@include media(lg) {
						font-size: $scale-base-size*$scale-ratio-lg;
				}
				@include media(xl) {
						font-size: $scale-base-size*$scale-ratio-xl*0.8;
				}
				@include media(xxl) {
						font-size: $scale-base-size*$scale-ratio-xxl*0.8;
				}
		}
}

form,
html,
body{
    font-family:$font-family;
    height:100%;
    color: $base-black;
}

body{
	 @include pux-scale-with-min("font-size", 16px, 14px);
}

img{
    max-width:100%;
    height:auto !important;
}

@each $value in $px-values {
  .pt-#{strip-unit($value)} {
    @if(strip-unit($value) >= 20){
			@include pux-scale-with-min("padding-top", $value, 20px, 1);
    } @else{
      @include pux-scale-with-min("padding-top", $value, $value, 1);
    }
  }

  .pb-#{strip-unit($value)} {
    @if(strip-unit($value) >= 20){
			@include pux-scale-with-min("padding-bottom", $value, 20px, 1);
    } @else{
       @include pux-scale-with-min("padding-bottom", $value, $value, 1);
     }
  }

  .mt-#{strip-unit($value)} {
    @if(strip-unit($value) >= 20){
			@include pux-scale-with-min("margin-top", $value, 20px, 1);
    } @else{
      @include pux-scale-with-min("margin-top", $value, $value, 1);
    }
  }

  .mb-#{strip-unit($value)} {
    @if(strip-unit($value) >= 20){
			@include pux-scale-with-min("margin-bottom", $value, 20px, 1);
    } @else{
      @include pux-scale-with-min("margin-bottom", $value, $value, 1);
    }
  }
}

h1,
.h1{
  @include pux-scale-with-min("font-size", 40px, 20px);
  @include pux-scale-with-min("line-height", 40px, 20px);
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.5rem 0 2.5rem 0;
}

h2,
.h2{
  @include pux-scale-with-min("font-size", 30px, 15px);
  @include pux-scale-with-min("line-height", 30px, 15px);
  padding: 0.5rem 0 1rem 0;
}

h3,
.h3{
  @include pux-scale-with-min("font-size", 24px, 12px);
  @include pux-scale-with-min("line-height", 24px, 12px);
  padding: 0.5rem 0 1rem 0;
}

h4,
.h4{
  @include pux-scale-with-min("font-size", 18px, 14px);
  @include pux-scale-with-min("line-height", 18px, 14px);
}

big,
.perex{
  @include pux-scale-with-min("font-size", $font-size-perex, $min-font-size-perex);
  @include pux-scale-with-min("line-height", $line-height-perex, $min-line-height-perex);
  padding: 0.5rem 0;
}

small,
.text-small{
  @include pux-scale-with-min("font-size", $font-size-small, $min-font-size-small);
  @include pux-scale-with-min("line-height", $line-height-small, $min-line-height-small);
  color: $base-black;
  line-height: 2.8rem;
  padding: 1rem 0;
}

small,
.text-small-dark{
  @include pux-scale-with-min("font-size", $font-size-small, $min-font-size-small);
  @include pux-scale-with-min("line-height", $line-height-small, $min-line-height-small);
  color: $base-white;
  line-height: 2.8rem;
  padding: 1rem 0;
}

a{
    color:$color-link;
    cursor: pointer;
    &:focus,
    &:hover{
      color: darken($color-link, 10);
      outline:none;
      text-decoration: none;
    }
}

.link{
  display: inline-flex;
  align-items: center;
  color:$color-link;
  cursor: pointer;
  text-decoration: underline;
  padding: 1.5rem 0;
  &:before{
    @include pux-static-size("font-size", 20px);
    margin:0 10px 0 0;
  }
  &:hover,
  &:focus{
    text-decoration: none;
    color:darken($color-link, 10);
  }
  &.no-text{
    font-size: 0;
    &:before{
      margin: 0;
    }
  }
  &.loading{
    &:before{
      @include a-spinner();
    }
  }
}

.main-price{
  color: $main-color;
  font-weight: bold;
  font-size: 4rem;
  @include media(md){
    font-size: 2.5rem;
  }

}
.main-label{
  font-size: $font-size-small;
}

// příklad okazu s ikonou
//.link-cross{
//  &:before{
//    content:"\e900";
//  }
//}

.btn{
  display: inline-flex;
  align-items: center;
  @include border-radius(0);
  justify-content: center;
  border: none;
  padding:1.5rem 3rem;
  @include box-shadow(none);
  @include pux-scale-with-min("font-size", 18px, 16px);
  letter-spacing: 0.1rem;
  &:before{
    @include pux-static-size("font-size", 20px);
    margin:0 10px 0 0;
  }
  &:focus,
  &:hover{
      text-decoration: none;
      @include box-shadow(none);
  }
  &.loading{
    &:before{
      @include a-spinner();
    }
  }
}

.btn-primary{
  color: $btn-primary-color;
  background-color: $main-color;
  font-size: 20px;
  font-weight: bold;
  padding: 1.5rem 3rem;
  border-radius: 41px;
  border: none;
  &:focus,
  &:hover{
      text-decoration: none;
      background-color: $btn-primary-background-hover;
      color: $btn-primary-color;
  }
  &::before{
    content: "\e904";
    font-size: 2.6rem;
    padding: 0;
    margin: 0 5px 0 0;
    @media ( min-width: 360px ) {
      padding: 0 1.5rem 0 0;
      margin: 0 10px 0 0;
    }
  }
  &.loading{
    &:before{
      border-color: rgba(255, 255, 255, 0.3);
      border-top-color: $second-color;
    }
  }
}

.btn-primary-cart{
  @extend .btn-primary;
  display: flex;
  background-color: transparent;
  font-size: 0;
  color: $base-white;
  margin: 0;
  padding: 0;
  line-height: 5rem;
  margin-right: 1rem;
  @include media(md) {
    width: 22rem;
    align-items: center;
    justify-content: space-between;
    background-color: $base-white;
    color: $base-black;
    padding: 0 3rem;
    font-weight: normal;
    font-size: 1.8rem;
    letter-spacing: 0;
    height: 5.5rem;
    margin: auto auto;
  }
  @include media(lg){
    line-height: 4rem;
  }
  @include media(xl){
    line-height: 4rem;
  }
  &::before{
    display: block;
    vertical-align: middle;
    font-size: 4rem;
    margin: 0 0.6rem 0.3rem 0;
    @media ( min-width: 360px ) {
      font-size: 4rem;
      margin: 0 0.3rem 0.3rem 0;
    }
    @include media(md){
      font-size: 3rem;
    }
  }

  &:hover::before{
    color: $main-color;
    @include media(md){
      color: $base-white;
    }
  }

  &:hover{
    background-color: transparent;
    @include media(md){
      background-color: $main-color;
      color: $base-white;
    }
  }

}

#btn-contains-items{
  color: $btn-primary-color;
  @include media($menu-break) {
    background-color: $btn-primary-background;
  }
  &:focus,
  &:hover{
    text-decoration: none;
    background-color: transparent;
    @include media($menu-break){
      color: $btn-primary-color;
      background-color: $btn-primary-background-hover;
    }
  }
  &:hover::before{
    color: $btn-primary-background;
    @include media($menu-break){
      color: $btn-primary-color;
    }
  }
  &::after{
    display: flex;
    justify-content: center;
    position: relative;
    left: -8px;
    top: -12px;
    content: "3";
    width: 18px;
    height: 18px;
    border-radius: 50%;
    padding-top: 2px;
    margin-left: -10px;
    margin-top: 7px;
    font-size: 2.5rem;
    font-weight: bold;
    background-color: $btn-primary-background;
    color: $base-white;
    @include media(xs){
      padding-top: 1px;
      left: -14px;
      top: -13px;
    }
    @include media(sm){
      padding-top: 0;
    }
    @include media($menu-break){
      content: none;
    }
  }
}


.btn-default{
  font-size: 20px;
  font-weight: bold;
  padding: 17px 30px;
  border-radius: 41px;
  border: 1px solid $base-black;
  text-indent: initial;
  background: $btn-default-background;
  color: $btn-default-color;
  &:focus,
  &:hover{
      text-decoration: none;
      background: $base-black;
      color: $base-white;
  }
  &.loading{
    &:before{
      border-color: rgba(255, 255, 255, 0.3);
      border-top-color: $main-color;
    }
  }
}

.sec-hover{
  background-color: $base-black;
  color: $base-white;
}
.dark-bcg{
  color: $base-black;
  font-weight: normal;
  border: none;
  &:hover {
    background-color: $main-color;
  }
}
.darkbcg-hover{
  background-color: $main-color;
  color: $base-white;
  font-weight: normal;
  border: none;
}
.darkbcg-hover:hover{
  background-color: $main-color;
}



// příklad tlačítka s ikonou
//.btn-plus{
//  &:before{
//    content:"\e907"
//  }
//}

.image-top-center{
  position: top center !important;
}

.image-top-left{
  position: top left !important;
}

.image-top-right{
  position: top right !important;
}

.image-center-center{
  position: center center !important;
}

.image-bottom-center{
  position: bottom center !important;
}

.image-bottom-left{
  position: bottom left !important;
}

.image-bottom-right{
  position: bottom right !important;
}

main{
    min-height:40rem;
    ol{
        margin:0;
        padding:0;
        list-style:none;
        counter-reset: my-ol;
        li{
            position:relative;
            margin:0 0 1.5rem 0;
            padding:0 0 0 2rem;
            &:before{
                content: counter(my-ol)".";
                counter-increment: my-ol;
                color:$ol-number;
                position:absolute;
                left:0;
                top:3px;
                font-weight:bold;
                font-family: $font-family !important;
            }
        }
        &[start="next"]{
          counter-reset:continue;
          li{
            &:before{
              counter-increment: continue;
            }
          }
        }
        @for $i from 1 through 20 {
          &[start="#{$i}"] {
              counter-reset: my-ol $i ;
          }
      }
    }
    ul{
        margin:0;
        padding:0;
        list-style:none;
        li{
            margin:0 0 1.5rem 0;
            padding:0 0 0 1.5rem;
            position:relative;
            &:before{
                content:"";
                width:5px;
                height:5px;
                background:$ul-bullet;
                position:absolute;
                top:10px;
                left:0;
                @include border-radius(50%);
            }
        }
    }
    table{
      width:100%;
      tr{
        border-bottom: 1px solid $table-tr-border;
        line-height: 5rem;
        td{
          padding:1rem 1.5rem;
        }

      }
    }
    .respo-table{
      table{
        width:100%;
        thead{
          display: none;
          @include media($respo-table-break){
            display: table-header-group;
          }
          tr{
            border-bottom: 1px solid $table-tr-border;
            th{
              font-weight: bold;
              padding:1rem 1.5rem;
            }
          }
        }
        tbody{
          tr{
            display: block;
            border: 1px solid $table-tr-border;
            border-bottom: none;
            margin:0 0 2rem 0;
            &:nth-child(even){
              background: $table-even-background;
            }
            @include media($respo-table-break){
              display: table-row;
              margin: 0;
              border: none;
              border-bottom: 1px solid $table-tr-border;
            }
            td{
              @include flex-block;
              @include justify-content(space-between);
              width:100%;
              border-bottom: 1px solid $table-tr-border;
              @include media($respo-table-break){
                display: table-cell;
                border: none;
                width:auto;
              }
              &:before{
                  content:attr(data-th);
                  display:block;
                  text-align:left;
                  font-family:$font-family !important;
                  margin:0 1.5rem 0 0;
                  @include media($respo-table-break){
                      display:none;
                  }
              }
            }
          }
        }
      }
    }
}
