
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.main-top-box-headline{
  display: flex;
  @include media(md) {
    justify-content: space-between;
  }
  .required-info-top{
    display: none;
    @include media(md) {
      display: block;
      padding-top: 1rem;
      label{
        display: inline;
        color: $error-label-color;
        &:before{
          content: "*";
        }
      }
    }
  }
}

.required-info-bottom{
  display: flex;
  label{
    display: inline;
    color: $error-label-color;
    font-size: 2.5rem;
    &:before{
      content: "*";
    }
  }
  @include media(md) {
    display: none;
  }
}

.main-wrapper {

  .cart-container {
    width: 100%;
    background: #fff;
//////////////////////////////////////////////         CART HEADER          /////////////////////////////////////////
      .cart-header {
        display: flex;
        column-count: 3;
        justify-content: center;
        align-items: start;
        border-bottom: 1px solid $base-grey;
        @include media(md){
          justify-content: flex-start;
          border-bottom: none;
        }

        .step {
          width: 100%;
          text-align: center;
          line-height: 4rem;
          margin: 10px 0 20px;
          padding: 0 2rem;
          @include media(sm){
            padding: 0 4rem;
          }
          @include media(lg){
            width: 40rem;
            padding: 0;
            text-align: left;
          }

          &:after{
            content: "";
            @include media(lg) {
              content: "\e90d"; // -->
              color: $base-black;
              font-size: 2rem;
              padding-left: 5rem;
            }
          }
          a,span{
            font-size: 2.5rem;
            @media ( min-width: 360px ) {
              font-size: 2.8rem;
            }
            @include media(lg){
              font-size: 2.7rem;
            }
          }
          span{
            color: $base-grey;
          }
          a, span {
            &::before{
              display: block;
              color: $base-black;
              font-size: 6rem;
            }
          }


          .step1::before{
            content: "\e912"; // (1)
            @include media(lg){
              content: none;
            }
          }
          .step2::before{
            content: "\e911"; // (2)
            @include media(lg){
              content: none;
            }
          }
          .step3::before{
            content: "\e910"; // (3)
            @include media(lg){
              content: none;
            }
          }
          &:last-child::after{
            display: none;
          }
        }

        .step.active {
          span {
            color: $base-black;
          }
        }
        .step:visited{
          color: $main-color;
        }
      }

    ///////////////////////////////////////////////        CART BODY       ////////////////////////////////////////////

    .cart-body {
      padding: 4rem 0 4rem 0;
      min-height: 150px;
      display: flex;
      justify-items: center;
      align-items: center;
      @include media(sm){
        padding: 1rem 0;
      }
        .tab {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .tab-main{
          flex-wrap: wrap;
        }

        .tab-shipping{
          flex-direction: column;
          @include media(md) {
            flex-direction: row;
          }
        }
        .tab-summary table tr{
          border-bottom: none;
        }


        table{
          display: flex;
          width: 100%;
          @include media(md) {
            display: table;
          }
        }

        thead{
          display: none;
          @include media(md){
            display: table-header-group;
          }
          th{
            font-weight: normal;
          }
        }
        tbody{
          display: flex;
          flex-direction: column;
          width: 100%;
          @include media(md){
            display: table-row-group;
          }
        }
        table tr{
          display: flex;
          flex-wrap: wrap;
          @include media(md){
            display: table-row;
            line-height: 3rem;
          }
          th{
            padding-bottom: 1rem;
            &:last-child{
              @include media (xl) {
                padding-left: 3rem;
              }
            }
          }

          th,td{
            display: flex;
            padding-left: 0;
            padding-right: 0;
            @include media(md){
              display: table-cell;
              padding-right: 1.5rem;
            }
          }
          .cart-table-item{
            order: 1;
            width: 80%;
            @include media(md){
              width: 40%;
            }
            @media ( min-width: 860px ){
              width: 35%;
            }

            .card-item-in{
              width: 100%;
              @include media(md){
                width: 30rem;
              }
              @media ( min-width: 860px ) {
                width: 100%;
              }
            }
            .card-item-img{
              width: 25%;
              @include media(xs){
                width: 15rem;
              }
              @include media(md){
                width: 40%;
              }
              @media( min-width: 860px ){
                width: 30%;
              }
              @include media(xl){
                width: 25%;
              }
            }
          }
          .cart-table-size{
            order: 3;
            width: 50%;
            padding-right: 1rem;
            @include media(md){
              width: 15%;
            }
            @include media(xl){
              width: 13%;
            }
          }
          .cart-table-number{
            order: 4;
            width: 50%;
            padding-left: 1rem;
            @include media(md){
              width: 15%;
              padding-left: 0;
              padding-right: 1rem;
            }
            @include media(xl){
              width: 13%;
            }
          }
          .cart-table-price-one{
            display: none;
            @include media(md){
              display: table-cell;
              width: 12%;
            }
            @include media(lg){
              width: 15%;
            }
            @include media(xl){
              display: table-cell;
              width: 10%;
            }
            .main-price{
              font-weight: normal;
            }
          }
          .cart-table-price-all{
            order: 5;
            width: 100%;
            @include media(md){
              display: table-cell;
              width: 12%;
            }
            @include media(lg){
               width: 15%;
            }
            @include media(xl){
              width: 20%;
            }
            span{
              @include media(md){
                display: none;
              }
            }
          }
          .cart-table-options{
            order: 2;
            align-items: center;
            display: flex;
            width: 20%;
            justify-content: center;
            @include media(md){
              display: table-cell;
              width: 10%;
            }
            @include media(xl){
              display: table-cell;
              width: 15%;
              padding-right: 0;
            }
            .card-item{
              @include media(md){
                justify-content: space-around;
              }
            }
          }
        }
        .card-item{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          @include media(md){
            flex-wrap: nowrap;
            justify-content: space-between;
          }

          .main-label{
            @include media(md) {
              display: none;
            }
          }
          .card-item-in{
            display: flex;
            flex-direction: row;
            width: 75%;
            @include media(sm){
              width: 85%;
            }
            .card-item-img img{
                max-width: 100%;
              @include media(md) {
                min-width: 9rem;
              }
              @include media(lg) {
                min-width: 6rem;
              }
            }

            .card-item-body {
              display: flex;
              justify-content: center;
              flex-direction: column;
              width: 75%;
              padding-left: 1rem;
              margin: auto 0;
              @include media(sm) {
                padding: 0 2rem 0 2rem;
              }
              @include media(md) {
                padding: 0 1rem 0 1rem;
                width: 100%;
              }

              h2 {
                text-align: left;
                font-size: 2.5rem;
                padding: 0;
                @include media(md) {
                  font-size: 1.8rem;
                  line-height: 2.4rem;
                  margin: 0;
                }
              }
              h3 {
                @include media(md) {
                  font-size: 1.8rem;
                  line-height: 2.4rem;
                  padding: 0;
                }
              }
            }

            .main-price{
              font-size: 2.5rem;
            }


          }
          &-remove,
          &-info{
            margin: auto auto;
            display: flex;
            &::before {
              font-size: 4rem;
              @include media(md){
                font-size: 2rem;
              }
            }
            @include media(md){
              order: 6;
              margin: auto 0;
            }
          }

          &-remove{
            &::before {
              content: "\e901";
              color: $error-label-color;
            }
            &:hover::before{
              font-weight: bold;
              color: $decrement-color;
            }
          }

          &-info{
            &::before {
              content: "\e900";
              color: $main-color;
            }
            &:hover::before{
              font-weight: bold;
              color: $color-link;
            }
          }

          .product-purchase-inputs{
            width: 100%;
            padding: 0;
            @include media(md){
              width: initial;
            }

            &-inner{
              display: flex;
              flex-direction: column;
              @media( min-width: 360px ){
                flex-direction: row;
                align-items: center;
              }
              @include media(sm){
                justify-content: space-between;
              }
              span{
                margin: 0;
                padding: 0;
              }
              .selectric-wrapper{
                width: 75%;
                margin: 1rem 0 0 0;
                @media( min-width: 360px ){
                  margin: 1rem 0 1rem 1rem;
                  max-width: 20rem;
                }
                @include media(sm){
                  margin: 0;
                }
                @include media(md){
                  width: 14rem;
                  height: 6rem;
                }
                @include media(lg){
                  width: 15rem;
                  height: 6rem;
                }
              }
              select{
                font-size: 3rem;
              }
              .input-group{
                width: 75%;
                height: 7rem;
                padding: 0;
                margin: 1rem 0 0 0;
                @media( min-width: 360px ){
                  margin: 1rem 0 1rem 1rem;
                  max-width: 20rem;
                }
                @include media(sm){
                  margin: 0;
                }
                @include media(md){
                  width: 14rem;
                  height: 6rem;
                  margin: 0;
                }
                @include media(lg){
                  width: 15rem;
                  height: 6rem;
                }
                input{
                  @include media (md) {
                    font-size: 2rem;
                  }
                }
              }

            }

          }

          .card-item-price{
            display: flex;
            flex-direction: row;
            width: 100%;
            padding: 1rem 0;
            justify-content: space-between;
            h3{
              color: $base-black;
              font-size: 3.5rem;
              padding: 1rem 0;
              @media ( min-width: 360px ) {
                font-size: 4rem;
              }
              @include media(md){
                font-size: 2rem;
                margin: 0;
                padding: 0;
              }
            }
          }
        }
      ///////////////////////////////////////////         TAB SHIPPING        ////////////////////////////////////////

      .form{
        input{
          max-width: 600px;
          @include media(md){
            height: 6rem;
            margin-bottom: 1rem;
          }
        }
        h2{
          @include media(md){
            padding-top: 1rem;
            font-size: 2rem;
            margin-bottom: 1rem;
          }
        }
        .row{
          max-width: 600px;
          .col{
            @include media(md) {
              padding: 0 0.5rem 0 0;
            }
          }
          .col:first-child{
            width: 100%;
            flex-basis: auto;
            @include media(sm){
              flex-basis: 0;
              width: auto;
            }
            @include media(md){
              width: 100%;
              flex-basis: auto;
            }
            @include media(xl){
              flex-basis: 0;
              width: auto;
            }
          }
        }
        .checkbox > label{
          margin: 0;
        }
      }

      .form-second{
        width: 100%;
        flex-direction: column;
        @include media(md){
          padding-top: 1rem;
          padding-left: 3rem;
        }
        h2{
          @include media(md){
            padding-top: 1rem;
            font-size: 2rem;
            padding-bottom: 1rem;
          }
        }
        input{
          max-width: 600px;
          @include media(md){
            margin-bottom: 1rem;
          }
        }
        .row{
          max-width: 600px;
        }
        .checkbox label,
        .radio label{
          margin: 0;
        }

        .form-delivery label{
          margin: 1rem 0 0.5rem 0;
        }
      }

      .form-delivery{
        display: none;
        label{
          margin: 1rem 0 0.5rem 0;
        }
      }

      .checkbox {
        input[type="checkbox"] {
          &:checked ~ .remove-check {
            display: block;
          }
        }
      }
        ///////////////////////////////////////////         TAB SUMMARY        ////////////////////////////////////////
        .tab-summary{
          flex-wrap: wrap;
          @include media(md){
            flex-direction: row;
          }
          .cards-outer{
            width: 100%;
            flex-direction: column;

            .card-item-in{
              width: 100%;
              max-width: initial;
            }

            .card-item-body{
              align-items: flex-start;
              max-width: 55%;
              @media ( min-width: 360px ) {
                max-width: 61%;
              }
              @include media(md){
                max-width: 70%;
                flex-direction: row;
              }
              @include media(lg){
                max-width: 70%;
                padding-right: 0;
                align-items: center;
              }

              span{
                display: inline-flex;
                font-size: 2.3rem;
                @media ( min-width: 360px ) {
                  font-size: 2.5rem;
                }
              }

              .card-title{
                width: 100%;
                @include media(md){
                  padding: 0 2rem;
                }
                @include media(lg){
                  padding: 0;
                }
                @include media(xl){
                  padding: 0 2rem;
                }
              }
              .card-item-body-size{
                @include media(md) {
                  line-height: 3rem;
                  width: 30rem;
                  margin: auto;
                  text-align: center;
                }
                @include media(lg){
                  width: 30rem;
                }

                .main-label{
                  @include media(md) {
                    font-size: 2rem;
                    margin: 0 2rem;
                  }
                  @include media(lg) {
                    font-size: 1.8rem;
                    margin: 0 1rem;
                  }
                  @include media(xl){
                    margin: 0 2rem;
                  }
                }
              }
            }
          }

          .summary-table-container{
            @include media(lg){
              width: 40%;
            }
            @media ( min-width: 1120px ) {
              width: 50%;
            }
            @include media(xl){
              width: 50%;
            }
          }
          .summary-cards-container {
            @include media(lg){
              width: 60%;
            }
            @media ( min-width: 1120px ) {
              width: 50%;
            }
            @include media(xl){
              width: 50%;
            }
            h2:first-child {
              padding: 0;
            }
            .main-price{
              align-self: center;
              color: $base-black;
              @include media(md) {
                font-size: 2.5rem;
                padding: 0 2rem;
              }
              @include media(lg) {
                font-size: 2rem;
                padding: 0 2rem 0 0;
              }
              @include media(xl){
                padding: 0 2rem;
              }
            }
          }
          h2{
            padding: 4rem 0;
            font-weight: bold;
            font-size: 3rem;
            @include media(md){
              font-size: 2rem;
            }
            @include media(lg){
              padding: 0;
            }
          }
          .card-item{
            padding: 2rem 0;
            @include media(md){
              padding: 0;
              border: none;
            }
            @include media(lg){
              padding: 1rem 0;
            }
            &-in{
              @include media(md){
                width: 100%;
              }
            }
          }
          table {
            border-collapse: initial;
            tbody {
              tr{
                @include media(md) {
                  line-height: 1rem;
                }
              }
              td{
                width: 50%;
                padding-left: 0;
                margin: auto;
                align-items: center;
              }
            }
          }
          .summary-table-price {
            border-block: 1px solid $light-grey;
            h2 {
              text-align: left;
              padding: 0;
            }

            tr {
              line-height: 15rem;
              .final-price-h2 {
                font-weight: bold;
                color: $main-color;
                padding-left: 0;
                font-size: 3rem;
                @include media(md){
                  font-size: 2rem;
                }
              }
            }
          }

        }

    }
    //////////////////////////////////////////         FINAL PRICE BOXES     /////////////////////////////////////
    .cart-final-price{
      display: flex;
      flex-direction: column;
      @include media(md){
        flex-direction: row;
        justify-content: space-between;
        height: 17rem;
        padding: 2rem 0;
      }
      .cart-final-price-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 2rem 0;
        @include media(md) {
          flex-direction: column;
          width: 30%;
          border: 1px solid $base-grey;
          border-radius: 20px;
          padding: 1rem 1rem;
          text-align: center;
        }

        h2 {
          font-size: 3.5rem;
          @media (min-width: 360px) {
            font-size: 4rem;
          }
          @include media(md) {
            font-size: 3rem;
            text-align: center;
            padding: 1rem 0;
          }
        }

        .final-discount-h2,
        .final-shipping-h2 {
          font-weight: bold;
        }

        .final-price-h2 {
        color: $main-color;
          font-weight: bold;
        }

      }
    }
    //////////////////////////////////////////           CART FOOTER            /////////////////////////////////////
    .cart-footer {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 2rem 0 4rem 0;
      @include media(md) {
        flex-direction: row;
      }
      button {
        padding: 15px 25px;
        cursor: pointer;
      }

      .btn-primary {
        order: 1;
        padding: 2.5rem 3rem;
        @include media(md){
          order: 0;
        }
        @include media(lg){
          padding: 1.5rem 3rem;
        }
        &::before {
          content: none;
        }
      }

      .btn-default{
        display: inline;
        border: none;
        font-size: 3rem;
        font-weight: normal;
        order: 2;
        padding: 5px 5px;
        width: 30rem;
        margin: 2rem auto;
        @include media(md){
          font-size: 20px;
          font-weight: bold;
          border: 1px solid #000;
          order: 0;
          padding: 17px 30px;
          width: initial;
          margin: 0;
        }
        &:hover,
        &:focus,
        &:active{
          background: none;
          color: $base-black;
          text-decoration: underline;
          box-shadow: none;
          @include media(md){
            background: $base-black;
            color: $base-white;
            text-decoration: none;
            box-shadow: initial;
          }
        }
        &::before{
          content: "< ";
          margin: 0;
          @include media(md){
            content: "";
          }
        }
      }
    }
  }

}