
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_variables.scss";

@import "zfan-index.scss";
@import "zfan-layout.scss";
@import "zfan-typography.scss";
@import "zfan-product.scss";
@import "zfan-cart.scss";
@import "zfan-discounts.scss";
@import "zfan-form.scss";

// selectric
@import "../plugins/selectric/selectric.scss";

// datetimepicker
//@import "../plugins/datetimepicker/pux-datepicker.scss";

// cart
//@import "../plugins/pux/cart/scss/pux-cart.scss";
//@import "../plugins/pux/cart/scss/pux-add-to-cart.scss";

// modules
//@import "../plugins/pux/modules/slider/pux-slider.scss";
//@import "../plugins/pux/modules/topImage/pux-top-image.scss";
//@import "../plugins/pux/modules/social/pux-social.scss";
@import "../plugins/pux/modules/gallery/pux-gallery.scss";
//@import "../plugins/pux/modules/collapse/pux-collapse.scss";
//@import "../plugins/pux/modules/account/pux-account.scss";
@import "../plugins/pux/modules/modal/pux-modal.scss";
//@import "../plugins/pux/modules/search/pux-search.scss";

@import "print.scss";
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');