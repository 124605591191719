@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");
@keyframes rotating {
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1); } }

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4); }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1); } }

body {
  overflow-x: hidden; }

h1, h2 {
  text-align: center;
  padding: 3rem 0; }
  @media (min-width: 768px) {
    h1, h2 {
      text-align: left; } }

.main-top-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .main-top-box .main-breadcrumb-nav {
    padding: 4rem 0 4rem 0;
    z-index: 50;
    position: relative;
    width: 80%;
    font-size: 2.5rem; }
    @media (min-width: 768px) {
      .main-top-box .main-breadcrumb-nav {
        font-size: 2rem; } }
    .main-top-box .main-breadcrumb-nav a {
      text-transform: uppercase;
      color: #000; }
      .main-top-box .main-breadcrumb-nav a:hover {
        color: #0bc189; }
    .main-top-box .main-breadcrumb-nav span {
      text-transform: uppercase;
      color: #d3d3d3; }
    .main-top-box .main-breadcrumb-nav a:after {
      content: "";
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #d3d3d3;
      display: inline-block;
      margin: 0px 7px 3px 11px; }
  .main-top-box .full-width {
    width: 100%; }
  .main-top-box .main-search-bar {
    display: block;
    background: #fff;
    position: absolute;
    right: 10px;
    z-index: 70;
    height: 8rem;
    margin: 2rem 0;
    box-shadow: -6px 0px 5px 5px white; }
    @media (min-width: 768px) {
      .main-top-box .main-search-bar {
        width: 35rem;
        height: 7rem;
        position: relative;
        right: initial; } }
    @media (min-width: 992px) {
      .main-top-box .main-search-bar {
        height: 6rem; } }
    @media (min-width: 1200px) {
      .main-top-box .main-search-bar {
        height: 5rem; } }
    .main-top-box .main-search-bar form {
      border: 1px solid #d3d3d3;
      border-radius: 20px; }
    .main-top-box .main-search-bar .input-group {
      height: 100%;
      padding: 1.5rem 1.5rem; }
      @media (min-width: 1200px) {
        .main-top-box .main-search-bar .input-group {
          padding: 1rem 1.5rem; } }
      .main-top-box .main-search-bar .input-group input {
        font-size: 2.5rem;
        height: 100%;
        width: 0;
        transition: all 0.5s ease; }
        @media (min-width: 768px) {
          .main-top-box .main-search-bar .input-group input {
            width: initial;
            font-size: 1.5rem; } }
        .main-top-box .main-search-bar .input-group input::placeholder {
          font-size: 0; }
          @media (min-width: 768px) {
            .main-top-box .main-search-bar .input-group input::placeholder {
              font-size: 1.5rem; } }
      .main-top-box .main-search-bar .input-group input[type=search]:focus {
        width: 40rem; }
        @media (min-width: 768px) {
          .main-top-box .main-search-bar .input-group input[type=search]:focus {
            width: 0; } }
      .main-top-box .main-search-bar .input-group input[type=search]:focus::placeholder {
        font-size: 2.5rem; }
        @media (min-width: 768px) {
          .main-top-box .main-search-bar .input-group input[type=search]:focus::placeholder {
            font-size: 1.5rem; } }
    .main-top-box .main-search-bar button {
      cursor: pointer;
      padding: 0.7rem 0.5rem; }
      @media (min-width: 768px) {
        .main-top-box .main-search-bar button {
          padding: 1rem 0.5rem; } }
      @media (min-width: 992px) {
        .main-top-box .main-search-bar button {
          padding: 0.5rem 0.5rem; } }
      @media (min-width: 1200px) {
        .main-top-box .main-search-bar button {
          padding: 0 0.5rem; } }
      .main-top-box .main-search-bar button::before {
        content: "\e90e";
        font-size: 3.3rem;
        color: #000;
        margin: 0; }
        @media (min-width: 768px) {
          .main-top-box .main-search-bar button::before {
            font-size: 1.8rem; } }
      .main-top-box .main-search-bar button:hover::before {
        color: #0bc189; }
      .main-top-box .main-search-bar button img {
        width: 10px; }

.buttons-section {
  padding-top: 5rem;
  max-width: 800px; }
  .buttons-section h2 {
    padding-bottom: 3rem; }
  .buttons-section .components-button {
    display: inline-block;
    width: auto;
    margin-right: 3rem; }
    .buttons-section .components-button h4 {
      padding-bottom: 0.5rem; }

.hover {
  background-color: #0f8f68; }

.background-dark {
  background-color: #000;
  color: #fff;
  padding: 1rem 1rem; }

.colors-section {
  max-width: 90%;
  padding: 4rem 0 0 0; }
  .colors-section .color-badge {
    display: inline-block;
    width: 200px;
    height: 200px;
    padding: 1rem 1rem;
    margin: 1rem -0.3rem 0 0;
    color: #fff;
    background-color: #0bc189; }
    .colors-section .color-badge h4 {
      font-size: 1.5rem;
      font-weight: normal; }
    .colors-section .color-badge h4:last-of-type {
      padding-bottom: 10rem;
      font-size: 1.2rem; }
  .colors-section .badge-hover-primary {
    background-color: #0f8f68; }
  .colors-section .badge-text {
    background-color: #000; }
  .colors-section .badge-dark-grey {
    background-color: #818181; }
  .colors-section .badge-helper {
    background-color: #f7f7f7;
    color: #000; }
  .colors-section .badge-space {
    background-color: #fff;
    color: #000; }
  .colors-section .badge-redness {
    background-color: #ff0000; }
  .colors-section .badge-news {
    background-color: #E1A503; }

.form {
  width: 100%;
  padding-bottom: 2rem; }
  .form h2 {
    padding-top: 5rem; }
  .form label {
    width: 100%;
    font-size: 14px; }
  .form input {
    width: 100%;
    max-width: 400px;
    height: 9rem;
    border-radius: 12px;
    border: 1px solid #d3d3d3;
    padding: 0 3rem;
    margin-bottom: 2rem; }
  .form textarea {
    border-radius: 12px; }

.error-message span {
  color: #ff0000; }

.main-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: stretch; }

.main-wrapper-sidebar {
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 0;
  margin-left: -250px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f7f7f7;
  transition: all 0.5s ease;
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 4rem 0 4rem 3rem;
  color: white; }
  @media (min-width: 768px) {
    .main-wrapper-sidebar {
      width: 300px;
      left: -50px;
      margin-left: -300px; } }
  @media (min-width: 992px) {
    .main-wrapper-sidebar {
      position: relative;
      margin: 0;
      left: 0;
      padding: 2rem 0;
      background: none;
      z-index: 75;
      min-width: 200px;
      max-width: 200px; } }
  @media (min-width: 1200px) {
    .main-wrapper-sidebar {
      min-width: 250px;
      max-width: none; } }
  @media (min-width: 1400px) {
    .main-wrapper-sidebar {
      width: 300px; } }
  .main-wrapper-sidebar > a::before {
    content: "\e90f";
    position: absolute;
    right: 20px;
    font-size: 3rem;
    top: 18px;
    color: #000;
    transform: rotate(90deg); }
    @media (min-width: 360px) {
      .main-wrapper-sidebar > a::before {
        font-size: 4rem; } }
    @media (min-width: 545px) {
      .main-wrapper-sidebar > a::before {
        font-size: 3rem; } }
    @media (min-width: 768px) {
      .main-wrapper-sidebar > a::before {
        font-size: 2rem;
        top: 25px; } }
    @media (min-width: 992px) {
      .main-wrapper-sidebar > a::before {
        content: ""; } }
  .main-wrapper-sidebar > a:hover::before {
    color: #0bc189; }
  .main-wrapper-sidebar h4 {
    color: #000;
    padding-bottom: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0;
    font-size: 3rem; }
    @media (min-width: 360px) {
      .main-wrapper-sidebar h4 {
        font-size: 3.5rem; } }
    @media (min-width: 545px) {
      .main-wrapper-sidebar h4 {
        font-size: 3rem; } }
    @media (min-width: 768px) {
      .main-wrapper-sidebar h4 {
        font-size: 2.5rem; } }
    @media (min-width: 992px) {
      .main-wrapper-sidebar h4 {
        font-size: 2rem; } }
  .main-wrapper-sidebar .sidebar-nav-header {
    padding: 20px; }
  .main-wrapper-sidebar ul {
    padding: 2rem 0 4rem 0;
    list-style: none;
    font-size: 3rem; }
    @media (min-width: 360px) {
      .main-wrapper-sidebar ul {
        font-size: 3.5rem; } }
    @media (min-width: 545px) {
      .main-wrapper-sidebar ul {
        font-size: 3rem; } }
    @media (min-width: 768px) {
      .main-wrapper-sidebar ul {
        font-size: 2.5rem; } }
    @media (min-width: 992px) {
      .main-wrapper-sidebar ul {
        font-size: 2rem;
        padding: 0 0 4rem 0; } }
  .main-wrapper-sidebar ul li.active > a,
  .main-wrapper-sidebar a[aria-expanded="true"] {
    color: black;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 3rem; }
    @media (min-width: 360px) {
      .main-wrapper-sidebar ul li.active > a,
      .main-wrapper-sidebar a[aria-expanded="true"] {
        font-size: 3.5rem; } }
    @media (min-width: 545px) {
      .main-wrapper-sidebar ul li.active > a,
      .main-wrapper-sidebar a[aria-expanded="true"] {
        font-size: 3rem; } }
    @media (min-width: 768px) {
      .main-wrapper-sidebar ul li.active > a,
      .main-wrapper-sidebar a[aria-expanded="true"] {
        font-size: 2.5rem; } }
    @media (min-width: 992px) {
      .main-wrapper-sidebar ul li.active > a,
      .main-wrapper-sidebar a[aria-expanded="true"] {
        font-size: 2rem; } }
  .main-wrapper-sidebar ul li {
    padding: 2rem 0;
    margin: 0; }
    @media (min-width: 768px) {
      .main-wrapper-sidebar ul li {
        padding: 1rem 0; } }
    @media (min-width: 992px) {
      .main-wrapper-sidebar ul li {
        padding: 0.3rem 0; } }
    .main-wrapper-sidebar ul li a {
      line-height: 3.5rem;
      display: flex;
      align-items: center;
      color: #000; }
    .main-wrapper-sidebar ul li ul {
      padding: 2rem 0 0 0; }
    .main-wrapper-sidebar ul li a:hover {
      color: #0bc189; }
  .main-wrapper-sidebar ul li::before {
    content: none; }
  .main-wrapper-sidebar .more-colors {
    display: none; }
    .main-wrapper-sidebar .more-colors.open {
      display: block; }
  .main-wrapper-sidebar .color-helper {
    padding: 0 0 4rem 0; }
  .main-wrapper-sidebar .open-colors-box {
    padding: 1.5rem 0 0 0; }
  .main-wrapper-sidebar .sidebar-colors {
    padding: 0; }
    .main-wrapper-sidebar .sidebar-colors a::before {
      display: flex;
      content: "";
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      margin-right: 3rem; }
      @media (min-width: 768px) {
        .main-wrapper-sidebar .sidebar-colors a::before {
          width: 3rem;
          height: 3rem; } }
      @media (min-width: 992px) {
        .main-wrapper-sidebar .sidebar-colors a::before {
          width: 2.5rem;
          height: 2.5rem;
          margin-right: 2rem; } }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-blue a::before {
      background: #0b9deb; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-red a::before {
      background: #dd2525; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-black a::before {
      background: #000000; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-green a::before {
      background: #36c773; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-yellow a::before {
      background: #fdc300; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-gray a::before {
      background: #808080; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-white a::before {
      background: #fff;
      border: 1px solid #d3d3d3; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-orange a::before {
      background: #ff5d00; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-purple a::before {
      background: #9025ff; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-darkblue a::before {
      background: #0f2e6f; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-navy-blue a::before {
      background: #000080; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-olive a::before {
      background: #808000; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-azure a::before {
      background: #add8e6; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-charcoal a::before {
      background: #2b2d2f; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-pink a::before {
      background: #ffc0cb; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-khaki a::before {
      background: #c3b091; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-royal-blue a::before {
      background: #002366; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-brown a::before {
      background: #a52a2a; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-sand a::before {
      background: #c2b280; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-ash a::before {
      background: #b2beb5; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-cream a::before {
      background: #fffdd0; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-turquoise a::before {
      background: #40e0d0; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-bronze a::before {
      background: #cd7f32; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-burgundy a::before {
      background: #800020; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-gold a::before {
      background: #d4af37; }
    .main-wrapper-sidebar .sidebar-colors .sidebar-colors-camo a::before {
      background: url("../img/pictures/camo.jpg"); }

.page-content-wrapper .btn-default {
  display: flex;
  height: 9rem;
  padding: 2rem 4rem;
  border: none;
  background-color: #f7f7f7;
  text-transform: uppercase;
  font-size: 3rem; }
  @media (min-width: 768px) {
    .page-content-wrapper .btn-default {
      height: 6rem;
      font-size: 2.5rem; } }
  @media (min-width: 992px) {
    .page-content-wrapper .btn-default {
      display: none; } }
  .page-content-wrapper .btn-default:hover {
    background-color: #000;
    color: #fff; }

.main-wrapper.open .main-wrapper-sidebar {
  margin-left: 0; }
  @media (min-width: 768px) {
    .main-wrapper.open .main-wrapper-sidebar {
      margin-left: 50px; } }
  @media (min-width: 992px) {
    .main-wrapper.open .main-wrapper-sidebar {
      margin-left: 0; } }

.pux-container .main-wrapper [class^="col-"] {
  padding-left: 1rem;
  padding-right: 1rem; }

.main-wrapper-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  min-width: 0; }

a[data-toggle="collapse"] {
  position: relative; }

a, a:hover, a:focus {
  text-decoration: none;
  transition: all 0.3s; }

.dropdown-toggle::after {
  content: "\e90f";
  font-size: 1.5rem;
  display: block;
  position: absolute;
  right: 20px;
  width: auto;
  height: auto;
  border: none;
  margin: 0;
  transition: transform .2s ease-in-out; }
  @media (min-width: 992px) {
    .dropdown-toggle::after {
      font-size: 1rem; } }

.dropdown-toggle.open::after {
  transform: rotate(180deg); }

.collapse:not(.show) {
  display: none; }

.products-favourites {
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  height: 65rem; }
  @media (min-width: 545px) {
    .products-favourites {
      height: 62rem; } }
  @media (min-width: 768px) {
    .products-favourites {
      height: auto; } }
  .products-favourites h4 {
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 1.5rem; }
  .products-favourites .products-favourites-inner {
    display: flex;
    flex-direction: column; }
    @media (min-width: 768px) {
      .products-favourites .products-favourites-inner {
        flex-direction: row;
        flex-wrap: wrap; } }
  .products-favourites .card-item-outer {
    display: flex;
    width: 100%;
    padding: 2rem 1rem;
    max-height: 18rem; }
    @media (min-width: 545px) {
      .products-favourites .card-item-outer {
        padding: 3rem 1rem; } }
    @media (min-width: 768px) {
      .products-favourites .card-item-outer {
        max-height: 11rem; } }
    .products-favourites .card-item-outer .card-item {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 15rem;
      background-color: #fff;
      color: #000;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 20px;
      transition: box-shadow .3s; }
      @media (min-width: 360px) {
        .products-favourites .card-item-outer .card-item {
          min-width: 300px; } }
      @media (min-width: 768px) {
        .products-favourites .card-item-outer .card-item {
          height: 9rem; } }
      @media (min-width: 1200px) {
        .products-favourites .card-item-outer .card-item {
          min-width: 270px; } }
      .products-favourites .card-item-outer .card-item .card-item-img {
        display: flex;
        width: auto;
        margin: auto 2.5rem; }
        @media (min-width: 360px) {
          .products-favourites .card-item-outer .card-item .card-item-img {
            margin: auto 3rem; } }
        @media (min-width: 768px) {
          .products-favourites .card-item-outer .card-item .card-item-img {
            margin: auto 1.5rem; } }
        @media (min-width: 1200px) {
          .products-favourites .card-item-outer .card-item .card-item-img {
            margin: auto 1rem; } }
        .products-favourites .card-item-outer .card-item .card-item-img img {
          align-self: center;
          width: 12rem; }
          @media (min-width: 768px) {
            .products-favourites .card-item-outer .card-item .card-item-img img {
              width: 7rem; } }
      .products-favourites .card-item-outer .card-item .card-item-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        padding-left: 0; }
        .products-favourites .card-item-outer .card-item .card-item-body h4 {
          font-size: 3.2rem;
          text-transform: none;
          margin: 0; }
          @media (min-width: 360px) {
            .products-favourites .card-item-outer .card-item .card-item-body h4 {
              font-size: 3.5rem; } }
          @media (min-width: 768px) {
            .products-favourites .card-item-outer .card-item .card-item-body h4 {
              font-size: 2rem;
              padding-bottom: 0;
              padding-top: 0.5rem; } }
          @media (min-width: 1200px) {
            .products-favourites .card-item-outer .card-item .card-item-body h4 {
              font-size: 1.8rem; } }
        .products-favourites .card-item-outer .card-item .card-item-body h3 {
          font-size: 3rem;
          padding: 1rem 0 0 0; }
          @media (min-width: 768px) {
            .products-favourites .card-item-outer .card-item .card-item-body h3 {
              font-size: 1.8rem; } }
      .products-favourites .card-item-outer .card-item:hover {
        box-shadow: 0 0 11px rgba(33, 33, 33, 0.2); }

.products-sort {
  display: flex;
  justify-content: space-around;
  padding: 2rem 0; }
  @media (min-width: 545px) {
    .products-sort {
      justify-content: space-between; } }
  @media (min-width: 992px) {
    .products-sort {
      justify-content: flex-start; } }
  .products-sort .selectric-wrapper {
    width: 40rem;
    height: 9rem;
    margin: 0;
    line-height: 6rem; }
    @media (min-width: 360px) {
      .products-sort .selectric-wrapper {
        width: 42rem; } }
    @media (min-width: 768px) {
      .products-sort .selectric-wrapper {
        width: 35rem;
        height: 6rem;
        line-height: 4rem; } }
    @media (min-width: 992px) {
      .products-sort .selectric-wrapper {
        display: none; } }
    .products-sort .selectric-wrapper .selectric {
      border-radius: 40px;
      border: none;
      background-color: #f7f7f7; }
      .products-sort .selectric-wrapper .selectric .label {
        font-size: 2.7rem;
        color: #000;
        font-weight: bold; }
        @media (min-width: 360px) {
          .products-sort .selectric-wrapper .selectric .label {
            font-size: 3rem; } }
        @media (min-width: 768px) {
          .products-sort .selectric-wrapper .selectric .label {
            font-size: 2.5rem; } }
    .products-sort .selectric-wrapper .selectric-items {
      border-color: #f2f2f2;
      padding-top: 3rem; }
      .products-sort .selectric-wrapper .selectric-items ul li {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 2.7rem;
        padding-left: 3.5rem; }
        @media (min-width: 360px) {
          .products-sort .selectric-wrapper .selectric-items ul li {
            font-size: 3rem; } }
        @media (min-width: 768px) {
          .products-sort .selectric-wrapper .selectric-items ul li {
            font-size: 2.5rem; } }
  .products-sort .selectric:hover {
    background-color: #000;
    color: #fff; }
  .products-sort .selectric:hover .label {
    color: #fff; }
  .products-sort .selectric-items li:hover,
  .products-sort .selectric-items li:nth-child(odd):hover {
    transition: all .2s ease;
    background-color: #000;
    color: #fff; }
  .products-sort .page-content-wrapper .btn-default {
    display: flex;
    font-size: 2.7rem; }
    @media (min-width: 360px) {
      .products-sort .page-content-wrapper .btn-default {
        font-size: 3rem; } }
    @media (min-width: 768px) {
      .products-sort .page-content-wrapper .btn-default {
        font-size: 2.5rem; } }
    @media (min-width: 992px) {
      .products-sort .page-content-wrapper .btn-default {
        display: none; } }
  .products-sort .btn-default {
    display: none; }
    @media (min-width: 992px) {
      .products-sort .btn-default {
        display: flex;
        text-transform: uppercase;
        background-color: #f7f7f7;
        padding: 10px 20px;
        border: none;
        margin-right: 1rem; }
        .products-sort .btn-default:hover, .products-sort .btn-default.active {
          background-color: #000;
          color: #fff; } }

.product-slider {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 30px; }
  .product-slider .slick-slider {
    display: block; }
  .product-slider .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    border: none;
    background: none;
    font-size: 0;
    z-index: 88;
    outline: 0;
    margin-top: -1rem;
    cursor: pointer; }
    .product-slider .slick-arrow:before {
      font-size: 30px; }
  .product-slider .slick-prev {
    left: 0; }
    .product-slider .slick-prev:before {
      content: "\e910"; }
  .product-slider .slick-next {
    right: 0; }
    .product-slider .slick-next:before {
      content: "\e911"; }

.products-catalogue .product-slider .card-item-outer {
  max-width: 100%; }

.products-catalogue {
  display: flex;
  flex-wrap: wrap;
  padding: 3rem 0; }
  .products-catalogue .card-item-outer {
    display: flex;
    width: 90%;
    margin: auto;
    padding: 0 0.5em 1em 0.5em;
    text-align: center;
    max-width: 320px; }
    @media (min-width: 545px) {
      .products-catalogue .card-item-outer {
        width: 50%; } }
    @media (min-width: 768px) {
      .products-catalogue .card-item-outer {
        width: 33%;
        padding: 0 0.5em 1em 0.5em;
        text-align: center; } }
    @media (min-width: 1330px) {
      .products-catalogue .card-item-outer {
        width: 25%; } }
    .products-catalogue .card-item-outer .card-item {
      width: 100%;
      color: #000; }
  .products-catalogue .card-item {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: 100%;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 1.5rem;
    transition: box-shadow .3s; }
    .products-catalogue .card-item img {
      width: auto;
      align-self: center;
      margin: 10% auto 0 auto; }
    .products-catalogue .card-item .card-label {
      position: absolute;
      right: 3rem;
      top: 1em; }
      @media (min-width: 768px) {
        .products-catalogue .card-item .card-label {
          right: 2rem; } }
    .products-catalogue .card-item .card-item-body {
      padding: 2rem 2.5rem; }
    .products-catalogue .card-item .card-title {
      font-weight: bold;
      padding: 1rem 0 1rem 0;
      font-size: 3.5rem;
      margin: 0;
      line-height: 1.7; }
      @media (min-width: 545px) {
        .products-catalogue .card-item .card-title {
          font-size: 3rem; } }
      @media (min-width: 768px) {
        .products-catalogue .card-item .card-title {
          font-size: 2rem; } }
      @media (min-width: 992px) {
        .products-catalogue .card-item .card-title {
          font-size: 1.8rem; } }
      @media (min-width: 1200px) {
        .products-catalogue .card-item .card-title {
          font-size: 1.7rem; } }
    .products-catalogue .card-item .card-price {
      font-weight: bolder; }
    .products-catalogue .card-item .badge-primary,
    .products-catalogue .card-item .badge-secondary {
      font-size: 2.5rem;
      padding: 0.5rem 1rem;
      border-radius: 41px; }
      @media (min-width: 545px) {
        .products-catalogue .card-item .badge-primary,
        .products-catalogue .card-item .badge-secondary {
          font-size: 2.5rem; } }
      @media (min-width: 768px) {
        .products-catalogue .card-item .badge-primary,
        .products-catalogue .card-item .badge-secondary {
          font-size: 1.6rem; } }
    .products-catalogue .card-item .badge-primary {
      background-color: #E1A503; }
    .products-catalogue .card-item .main-label {
      display: block;
      font-size: 2.8rem; }
      @media (min-width: 545px) {
        .products-catalogue .card-item .main-label {
          font-size: 2.5rem; } }
      @media (min-width: 768px) {
        .products-catalogue .card-item .main-label {
          font-size: 1.8rem; } }
      @media (min-width: 992px) {
        .products-catalogue .card-item .main-label {
          font-size: 1.5rem; } }
    .products-catalogue .card-item:hover {
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.2); }

.products-pagination {
  display: flex;
  flex-direction: column;
  padding: 2rem 0 3rem 0;
  justify-content: center; }
  @media (min-width: 1200px) {
    .products-pagination {
      flex-direction: row;
      justify-content: space-between;
      align-items: center; } }
  @media (min-width: 1200px) {
    .products-pagination nav {
      padding-top: 1rem; } }
  .products-pagination nav ul li {
    font-size: 2.5rem; }
    @media (min-width: 360px) {
      .products-pagination nav ul li {
        font-size: 2.8rem; } }
    @media (min-width: 992px) {
      .products-pagination nav ul li {
        font-size: 2rem; } }
    .products-pagination nav ul li::before {
      display: none; }
    .products-pagination nav ul li .page-first,
    .products-pagination nav ul li .page-last {
      border-radius: 20px; }
    .products-pagination nav ul li a {
      background-color: #fff;
      color: #000;
      border-radius: 50%;
      border: none;
      font-weight: bold;
      padding: 0.5rem 1.1rem; }
      .products-pagination nav ul li a:hover {
        background-color: #000;
        color: #fff; }
  .products-pagination .pagination-items-per-page:first-child {
    visibility: hidden; }
  .products-pagination .pagination-items-per-page {
    display: flex;
    justify-content: center; }
    @media (min-width: 1200px) {
      .products-pagination .pagination-items-per-page {
        justify-content: right;
        width: 30%;
        flex-direction: column;
        align-items: end;
        padding-right: 1rem; } }
    .products-pagination .pagination-items-per-page > span {
      font-size: 2.4rem;
      line-height: 7rem; }
      @media (min-width: 768px) {
        .products-pagination .pagination-items-per-page > span {
          line-height: 5rem;
          padding-left: 0.5rem;
          font-size: 2rem; } }
      @media (min-width: 992px) {
        .products-pagination .pagination-items-per-page > span {
          font-size: 1.8rem; } }
      @media (min-width: 1200px) {
        .products-pagination .pagination-items-per-page > span {
          line-height: 2.6rem;
          font-size: 1.4rem;
          margin: 0 0 0 auto; } }
    .products-pagination .pagination-items-per-page .selectric-wrapper {
      margin: auto 0 auto 3rem;
      width: 14rem;
      height: 6rem; }
      @media (min-width: 360px) {
        .products-pagination .pagination-items-per-page .selectric-wrapper {
          margin: 0 0 0 3rem; } }
      @media (min-width: 768px) {
        .products-pagination .pagination-items-per-page .selectric-wrapper {
          width: 12rem;
          height: 5rem; } }
      @media (min-width: 1200px) {
        .products-pagination .pagination-items-per-page .selectric-wrapper {
          width: 8rem;
          height: 3rem;
          margin: 0 0 0 auto; } }
      @media (min-width: 768px) {
        .products-pagination .pagination-items-per-page .selectric-wrapper .selectric-items {
          padding-top: 1.5rem; } }
      .products-pagination .pagination-items-per-page .selectric-wrapper .selectric-items li {
        padding: 0.5rem 1.5rem; }
      .products-pagination .pagination-items-per-page .selectric-wrapper .selectric {
        padding: 0 1rem; }
        .products-pagination .pagination-items-per-page .selectric-wrapper .selectric span {
          padding: 0 0 0 0.5rem; }
        .products-pagination .pagination-items-per-page .selectric-wrapper .selectric .button::before {
          content: "\e90f"; }

.form-control:focus {
  box-shadow: none; }

.form-control::placeholder {
  font-size: 1.5rem;
  color: #818181; }

.product-purchase-inputs {
  display: flex;
  flex-direction: row;
  padding: 3rem 0; }
  @media (min-width: 992px) {
    .product-purchase-inputs {
      padding: 0; } }
  .product-purchase-inputs-inner {
    width: 100%; }
    .product-purchase-inputs-inner span {
      padding-left: 2rem; }
  .product-purchase-inputs .select-size {
    width: 95%;
    height: 10.5rem;
    font-size: 3rem;
    border: 1px solid gray;
    border-radius: 20px;
    margin: 1rem auto; }
    @media (min-width: 992px) {
      .product-purchase-inputs .select-size {
        height: 7rem; } }
  .product-purchase-inputs .input-group {
    width: 95%;
    height: 10.5rem;
    border: 1px solid gray;
    border-radius: 20px;
    margin: 1rem auto;
    padding: 0 2rem; }
    @media (min-width: 992px) {
      .product-purchase-inputs .input-group {
        height: 7rem;
        padding: 0 1rem; } }
    .product-purchase-inputs .input-group .input-group-prepend,
    .product-purchase-inputs .input-group .input-group-append {
      display: flex;
      width: 30%;
      height: inherit;
      justify-content: center;
      align-items: center;
      margin: auto; }
    .product-purchase-inputs .input-group .btn-decrement,
    .product-purchase-inputs .input-group .btn-increment {
      display: flex;
      width: 100%;
      background: none;
      border-radius: 50%;
      cursor: pointer;
      padding: 0;
      margin: auto auto; }
      .product-purchase-inputs .input-group .btn-decrement:disabled,
      .product-purchase-inputs .input-group .btn-increment:disabled {
        opacity: 1;
        cursor: auto; }
        .product-purchase-inputs .input-group .btn-decrement:disabled::before,
        .product-purchase-inputs .input-group .btn-increment:disabled::before {
          color: #d3d3d3; }
      .product-purchase-inputs .input-group .btn-decrement:not([disabled]):hover::before,
      .product-purchase-inputs .input-group .btn-increment:not([disabled]):hover::before {
        transition: font-size .2s;
        font-size: 4.5rem; }
        @media (min-width: 545px) {
          .product-purchase-inputs .input-group .btn-decrement:not([disabled]):hover::before,
          .product-purchase-inputs .input-group .btn-increment:not([disabled]):hover::before {
            font-size: 4rem; } }
        @media (min-width: 768px) {
          .product-purchase-inputs .input-group .btn-decrement:not([disabled]):hover::before,
          .product-purchase-inputs .input-group .btn-increment:not([disabled]):hover::before {
            font-size: 3.5rem; } }
        @media (min-width: 992px) {
          .product-purchase-inputs .input-group .btn-decrement:not([disabled]):hover::before,
          .product-purchase-inputs .input-group .btn-increment:not([disabled]):hover::before {
            font-size: 3rem; } }
        @media (min-width: 1200px) {
          .product-purchase-inputs .input-group .btn-decrement:not([disabled]):hover::before,
          .product-purchase-inputs .input-group .btn-increment:not([disabled]):hover::before {
            font-size: 2.5rem; } }
    .product-purchase-inputs .input-group .btn-increment::before {
      display: flex;
      content: "\e90a";
      color: #0bc189;
      margin: 0; }
    .product-purchase-inputs .input-group .btn-increment:not([disabled]):hover::before {
      color: #0ba36e; }
    .product-purchase-inputs .input-group .btn-decrement::before {
      display: flex;
      content: "\e909";
      color: #c52f2f;
      margin: 0; }
    .product-purchase-inputs .input-group .btn-decrement:not([disabled]):hover::before {
      color: #a72f2f; }
    .product-purchase-inputs .input-group input {
      font-size: 3rem;
      height: inherit;
      text-align: center;
      border: none;
      padding: 0;
      background: transparent; }

.product-menu li {
  position: relative; }
  .product-menu li ul {
    display: none; }
  .product-menu li.open ul {
    display: block; }
  .product-menu li.open .submenu-open:after {
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  .product-menu li a.active {
    color: #0bc189; }

.product-menu .submenu-open {
  position: absolute;
  height: 3.5rem;
  display: flex;
  align-items: center;
  width: 30px;
  justify-content: center;
  font-size: 0;
  top: 2rem;
  right: 10px;
  cursor: pointer;
  padding: 1rem 0;
  margin: 0; }
  @media (min-width: 768px) {
    .product-menu .submenu-open {
      top: 1rem;
      padding: 1.5rem 0; } }
  @media (min-width: 992px) {
    .product-menu .submenu-open {
      top: 0.3rem;
      padding: 2rem 0; } }
  .product-menu .submenu-open:after {
    content: "\e90f";
    font-size: 8px;
    color: #000;
    transition: 200ms; }

@keyframes rotating {
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1); } }

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4); }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1); } }

body {
  font-family: "Roboto", sans-serif; }

.pux-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 162rem; }
  .pux-container:after {
    display: block;
    content: "";
    clear: both; }
  @media (min-width: 768px) {
    .pux-container {
      padding-left: 4.5rem;
      padding-right: 4.5rem; } }

.pux-container .row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  @media (min-width: 768px) {
    .pux-container .row {
      margin-left: 0;
      margin-right: 0; } }

.pux-container [class^="col-"] {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .pux-container [class^="col-"] {
      padding-left: 4.5rem;
      padding-right: 4.5rem; } }

.pux-fluid-container {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .pux-fluid-container {
      padding-left: 4.5rem;
      padding-right: 4.5rem; } }
  @media (min-width: 1200px) {
    .pux-fluid-container {
      padding-left: 12rem;
      padding-right: 12rem; } }

header {
  position: relative;
  z-index: 999; }
  header .header-top {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    display: block;
    height: 12rem;
    background-color: #000; }
    @media (min-width: 768px) {
      header .header-top {
        height: 9rem; } }
    header .header-top .pux-container {
      height: 100%; }
    header .header-top .header-top-inner {
      display: flex;
      justify-content: space-between;
      height: 100%; }
      header .header-top .header-top-inner-left, header .header-top .header-top-inner-right {
        display: flex;
        align-items: center; }
      header .header-top .header-top-inner-right {
        margin-right: 7rem; }
        @media (min-width: 768px) {
          header .header-top .header-top-inner-right {
            margin: 0; } }
    header .header-top-logo {
      display: flex; }
      @media (min-width: 768px) {
        header .header-top-logo {
          margin: auto 2rem auto 0; } }
      @media (min-width: 992px) {
        header .header-top-logo {
          margin: auto 2rem auto 0; } }
      @media (min-width: 1050px) {
        header .header-top-logo {
          margin: auto 4rem auto 0; } }
      header .header-top-logo a {
        color: #fff;
        text-decoration: none;
        font-weight: bold;
        font-size: 5rem;
        line-height: 5rem; }
        @media (min-width: 768px) {
          header .header-top-logo a {
            font-size: 3rem;
            line-height: 3rem; } }
        header .header-top-logo a span {
          display: block;
          color: #fff;
          font-size: 2.5rem; }
          @media (min-width: 480px) {
            header .header-top-logo a span {
              font-size: 2.8rem; } }
          @media (min-width: 545px) {
            header .header-top-logo a span {
              font-size: 2.5rem; } }
          @media (min-width: 768px) {
            header .header-top-logo a span {
              font-size: 1.7rem; } }
          @media (min-width: 992px) {
            header .header-top-logo a span {
              font-size: 1.5rem; } }
        header .header-top-logo a img {
          max-width: none;
          height: 5rem !important; }
    header .header-top .header-top-phone,
    header .header-top .header-top-email {
      display: none; }
      @media (min-width: 768px) {
        header .header-top .header-top-phone,
        header .header-top .header-top-email {
          display: flex;
          margin: auto 1rem auto 0; } }
      @media (min-width: 930px) {
        header .header-top .header-top-phone,
        header .header-top .header-top-email {
          margin: auto 2rem auto 0; } }
      @media (min-width: 992px) {
        header .header-top .header-top-phone,
        header .header-top .header-top-email {
          margin: auto 2rem auto 0; } }
      @media (min-width: 1050px) {
        header .header-top .header-top-phone,
        header .header-top .header-top-email {
          margin: auto 4rem auto 0; } }
    header .header-top .header-top-signin,
    header .header-top .header-top-signup {
      display: flex;
      flex-direction: row;
      margin: auto 2rem auto 0; }
      @media (min-width: 360px) {
        header .header-top .header-top-signin,
        header .header-top .header-top-signup {
          margin: auto 5rem auto 0; } }
      @media (min-width: 768px) {
        header .header-top .header-top-signin,
        header .header-top .header-top-signup {
          margin: auto 3rem auto 0; } }
      @media (min-width: 992px) {
        header .header-top .header-top-signin,
        header .header-top .header-top-signup {
          margin: auto 2rem auto 0; } }
      @media (min-width: 1050px) {
        header .header-top .header-top-signin,
        header .header-top .header-top-signup {
          margin: auto 4rem auto 0; } }
    header .header-top .sign-in-box,
    header .header-top .sign-up-box {
      display: none;
      position: absolute;
      margin: 8rem 0 0 -30rem;
      z-index: 1000;
      width: 220px;
      height: auto;
      background-color: white;
      border-radius: 20px;
      padding: 3rem 2rem 3rem 2rem;
      text-align: center;
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.2); }
      @media (min-width: 360px) {
        header .header-top .sign-in-box,
        header .header-top .sign-up-box {
          margin: 8rem 0 0 -34rem; } }
      @media (min-width: 768px) {
        header .header-top .sign-in-box,
        header .header-top .sign-up-box {
          margin: 6.5rem 0 0 -8rem;
          width: 250px; } }
      @media (min-width: 992px) {
        header .header-top .sign-in-box,
        header .header-top .sign-up-box {
          margin: 6.3rem 0 0 -11rem;
          width: 250px; } }
      @media (min-width: 1200px) {
        header .header-top .sign-in-box,
        header .header-top .sign-up-box {
          margin: 6.3rem 0 0 -10rem;
          padding: 2rem 2rem 4rem 2rem;
          width: 250px; } }
      @media (min-width: 1400px) {
        header .header-top .sign-in-box,
        header .header-top .sign-up-box {
          margin: 6.3rem 0 0 -8rem;
          padding: 2rem 2rem 4rem 2rem; } }
      header .header-top .sign-in-box .btn-default,
      header .header-top .sign-up-box .btn-default {
        margin-top: 4rem;
        padding: 2rem 5rem;
        background-color: #0bc189;
        color: #fff;
        border: none; }
        @media (min-width: 768px) {
          header .header-top .sign-in-box .btn-default,
          header .header-top .sign-up-box .btn-default {
            padding: 2rem 4rem; } }
        @media (min-width: 992px) {
          header .header-top .sign-in-box .btn-default,
          header .header-top .sign-up-box .btn-default {
            font-size: 2rem; } }
        @media (min-width: 1200px) {
          header .header-top .sign-in-box .btn-default,
          header .header-top .sign-up-box .btn-default {
            font-size: 2rem;
            margin-top: 2rem;
            padding: 1.5rem 3rem; } }
        header .header-top .sign-in-box .btn-default:hover,
        header .header-top .sign-up-box .btn-default:hover {
          background-color: #0f8f68; }
    header .header-top .sign-in-box.open {
      display: block; }
    header .header-top .sign-up-box.open {
      display: block; }
    header .header-top .sign-in-form,
    header .header-top .password-form {
      text-align: center;
      padding-bottom: 4rem; }
      @media (min-width: 1200px) {
        header .header-top .sign-in-form,
        header .header-top .password-form {
          padding-bottom: 2rem; } }
      header .header-top .sign-in-form label,
      header .header-top .password-form label {
        padding: 2rem 0; }
        @media (min-width: 768px) {
          header .header-top .sign-in-form label,
          header .header-top .password-form label {
            padding: 1rem 0; } }
      header .header-top .sign-in-form input,
      header .header-top .password-form input {
        border-radius: 20px; }
    header .header-top .password-form {
      display: none;
      position: absolute;
      top: 20px;
      left: 0;
      background: #fff;
      overflow: hidden;
      padding: 0 2rem;
      height: 90%;
      width: 100%; }
    header .header-top .password-form.open {
      display: block; }
    @media (min-width: 545px) {
      header .header-top .header-top-signup .sign-up-box .sign-in-form {
        padding-bottom: 2rem; } }
    header .header-top .header-top-phone > a,
    header .header-top .header-top-email > a,
    header .header-top .header-top-signin > a,
    header .header-top .header-top-signup > a {
      line-height: 0;
      font-size: 0; }
      @media (min-width: 930px) {
        header .header-top .header-top-phone > a,
        header .header-top .header-top-email > a,
        header .header-top .header-top-signin > a,
        header .header-top .header-top-signup > a {
          color: #fff;
          font-size: 1.8rem;
          padding: 5px 5px;
          line-height: initial; } }
      header .header-top .header-top-phone > a:hover,
      header .header-top .header-top-email > a:hover,
      header .header-top .header-top-signin > a:hover,
      header .header-top .header-top-signup > a:hover {
        color: #0bc189; }
      header .header-top .header-top-phone > a:hover::before,
      header .header-top .header-top-email > a:hover::before,
      header .header-top .header-top-signin > a:hover::before,
      header .header-top .header-top-signup > a:hover::before {
        color: #0bc189; }
    @media (min-width: 768px) {
      header .header-top .header-top-phone > a,
      header .header-top .header-top-email > a {
        color: #fff;
        font-size: 1.8rem;
        padding: 5px 5px;
        line-height: initial; } }
    header .header-top .header-top-phone a::before,
    header .header-top .header-top-email a::before,
    header .header-top .header-top-signin a::before,
    header .header-top .header-top-signup a::before {
      font-size: 4.5rem;
      color: #fff; }
      @media (min-width: 768px) {
        header .header-top .header-top-phone a::before,
        header .header-top .header-top-email a::before,
        header .header-top .header-top-signin a::before,
        header .header-top .header-top-signup a::before {
          font-size: 2rem;
          padding-right: 1rem; } }
    header .header-top .header-top-phone a::before {
      content: "\e907"; }
    header .header-top .header-top-email a::before {
      content: "\e906"; }
    header .header-top .header-top-signin > a::before {
      content: "\e905"; }
    header .header-top .header-top-signup > a::before {
      content: "\e908"; }
  header .header-bottom {
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    background: transparent;
    position: absolute;
    width: 100%;
    height: 12rem; }
    @media (min-width: 768px) {
      header .header-bottom {
        position: relative;
        height: 6rem;
        background: #f4f4f4; } }
    header .header-bottom .pux-container {
      padding: 0; }
      @media (min-width: 768px) {
        header .header-bottom .pux-container {
          height: 100%;
          padding: 0 4.5rem; } }
    header .header-bottom-menu {
      display: none;
      top: 0;
      position: absolute;
      height: 100%;
      z-index: 77;
      left: 0;
      width: 100%;
      max-height: -webkit-calc(100vh - 5rem);
      max-height: -moz-calc(100vh - 5rem);
      max-height: calc(100vh - 5rem); }
      @media (min-width: 768px) {
        header .header-bottom-menu {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          height: 6rem;
          position: static;
          width: 100%; } }
      header .header-bottom-menu > ul {
        width: 100%;
        margin: 0 auto;
        padding: 0;
        list-style: none;
        text-transform: uppercase;
        background-color: #f4f4f4; }
        @media (min-width: 768px) {
          header .header-bottom-menu > ul {
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            height: 100%;
            background-color: transparent; } }
        header .header-bottom-menu > ul > li {
          display: flex;
          position: relative;
          flex-direction: column; }
          @media (min-width: 768px) {
            header .header-bottom-menu > ul > li {
              display: block;
              position: static;
              padding: 0;
              height: 80%;
              margin: auto 1%;
              width: 17%;
              border-radius: 41px;
              transition: all 0.2s ease-in-out;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -webkit-box-align: center;
              -moz-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center; }
              header .header-bottom-menu > ul > li:after {
                content: "";
                border-top-color: transparent;
                transition: border-top-color 0.2s ease-in-out; } }
          header .header-bottom-menu > ul > li a {
            font-size: 3.5rem;
            padding: 1rem 3rem;
            width: 100%;
            text-align: left;
            margin: auto auto;
            display: flex;
            align-items: center;
            color: #000;
            border-bottom: 1px solid #e3e3e3;
            height: 60px; }
            @media (min-width: 360px) {
              header .header-bottom-menu > ul > li a {
                font-size: 3.5rem; } }
            @media (min-width: 545px) {
              header .header-bottom-menu > ul > li a {
                font-size: 4rem; } }
            @media (min-width: 768px) {
              header .header-bottom-menu > ul > li a {
                font-size: 2rem;
                width: 100%;
                height: 100%;
                color: #000;
                border-radius: 41px;
                text-align: center;
                line-height: 0;
                padding: 0;
                display: block;
                border-bottom: none; } }
            header .header-bottom-menu > ul > li a.Highlighted {
              color: #0f8f68;
              background-color: #e3e3e3;
              text-decoration: none; }
              @media (min-width: 768px) {
                header .header-bottom-menu > ul > li a.Highlighted {
                  color: #fff;
                  background-color: #000; } }
          header .header-bottom-menu > ul > li > a::before {
            content: ' ';
            background-size: 30px 30px;
            height: 30px;
            width: 30px;
            display: inline-flex;
            margin-right: 3rem;
            vertical-align: middle; }
            @media (min-width: 360px) {
              header .header-bottom-menu > ul > li > a::before {
                background-size: 40px 40px;
                height: 40px;
                width: 40px; } }
            @media (min-width: 768px) {
              header .header-bottom-menu > ul > li > a::before {
                background-size: 30px 30px;
                height: 30px;
                width: 30px;
                margin: 0 0.5rem 0.5rem 0; } }
          @media (min-width: 768px) {
            header .header-bottom-menu > ul > li > a::after {
              display: block;
              width: 100%;
              content: "";
              height: 1rem; } }
          @media (min-width: 768px) {
            header .header-bottom-menu > ul > li:hover {
              color: #fff;
              background-color: #000; } }
          @media (min-width: 768px) {
            header .header-bottom-menu > ul > li:hover span {
              color: #fff; } }
          @media (min-width: 768px) {
            header .header-bottom-menu > ul > li:hover:after {
              content: "";
              width: 0;
              height: 0;
              border-left: 15px solid transparent;
              border-right: 15px solid transparent;
              border-top: 15px solid #000;
              z-index: 101; } }
          @media (min-width: 768px) {
            header .header-bottom-menu > ul > li:hover .menu-level-2 {
              display: block; } }
          @media (min-width: 768px) {
            header .header-bottom-menu > ul > li span {
              text-align: center;
              line-height: 4.7rem;
              padding: 0; } }
          @media (max-width: 767px) {
            header .header-bottom-menu > ul > li.open .menu-level-2 {
              display: block; } }
          header .header-bottom-menu > ul > li .menu-level-2 {
            display: none;
            position: relative;
            left: 0;
            width: 100%;
            top: 0;
            height: auto; }
            @media (min-width: 768px) {
              header .header-bottom-menu > ul > li .menu-level-2 {
                display: none;
                position: absolute;
                top: 6rem;
                left: 0;
                width: 100%;
                z-index: 100; } }
            @media (min-width: 1200px) {
              header .header-bottom-menu > ul > li .menu-level-2 {
                left: 4%;
                width: 92%; } }
            @media (min-width: 1400px) {
              header .header-bottom-menu > ul > li .menu-level-2 {
                width: 92%;
                left: 4%; } }
            @media (min-width: 1600px) {
              header .header-bottom-menu > ul > li .menu-level-2 {
                width: 84%;
                left: 8%; } }
            header .header-bottom-menu > ul > li .menu-level-2 > ul {
              box-shadow: 0 0 11px rgba(33, 33, 33, 0.2); }
              @media (min-width: 768px) {
                header .header-bottom-menu > ul > li .menu-level-2 > ul {
                  background-color: #fff;
                  min-height: 25rem; } }
              @media (min-width: 768px) {
                header .header-bottom-menu > ul > li .menu-level-2 > ul > li:nth-child(even) {
                  background-color: #f7f7f7; } }
              @media (min-width: 768px) {
                header .header-bottom-menu > ul > li .menu-level-2 > ul > li:nth-child(odd) {
                  background-color: #fff; } }
            header .header-bottom-menu > ul > li .menu-level-2 ul {
              display: block;
              width: 100%;
              padding: 0;
              list-style: none; }
              @media (min-width: 768px) {
                header .header-bottom-menu > ul > li .menu-level-2 ul {
                  display: flex;
                  align-items: flex-start; } }
              @media (min-width: 768px) {
                header .header-bottom-menu > ul > li .menu-level-2 ul > li {
                  text-align: center; } }
              @media (min-width: 768px) {
                header .header-bottom-menu > ul > li .menu-level-2 ul > li > a {
                  padding-top: 1rem; } }
              header .header-bottom-menu > ul > li .menu-level-2 ul li {
                width: 100%;
                margin: 0;
                padding: 0;
                height: 100%;
                align-items: start;
                flex-wrap: wrap;
                position: relative; }
                @media (min-width: 768px) {
                  header .header-bottom-menu > ul > li .menu-level-2 ul li {
                    width: 25%; } }
                header .header-bottom-menu > ul > li .menu-level-2 ul li.open .menu-level-3 {
                  display: block; }
                header .header-bottom-menu > ul > li .menu-level-2 ul li span {
                  margin: auto;
                  font-weight: bold;
                  font-size: 1.6rem; }
                header .header-bottom-menu > ul > li .menu-level-2 ul li > a {
                  padding: 0 20px 0 70px;
                  display: flex;
                  align-items: center;
                  height: 60px; }
                  @media (min-width: 768px) {
                    header .header-bottom-menu > ul > li .menu-level-2 ul li > a {
                      display: inline;
                      padding: 0;
                      display: block;
                      font-weight: bold;
                      line-height: 4rem;
                      height: auto; } }
                @media (min-width: 768px) {
                  header .header-bottom-menu > ul > li .menu-level-2 ul li > a:hover {
                    background: none;
                    color: #000;
                    cursor: initial; } }
                header .header-bottom-menu > ul > li .menu-level-2 ul li .menu-level-3 {
                  width: 100%;
                  height: auto;
                  display: none;
                  flex-wrap: wrap;
                  text-transform: none;
                  list-style: none;
                  padding-left: 0; }
                  @media (min-width: 768px) {
                    header .header-bottom-menu > ul > li .menu-level-2 ul li .menu-level-3 {
                      display: flex;
                      padding: 0;
                      position: relative; } }
                  header .header-bottom-menu > ul > li .menu-level-2 ul li .menu-level-3 li {
                    width: 100%;
                    padding: 0.5rem 0 0.5rem 0;
                    height: auto;
                    text-align: left; }
                    @media (min-width: 768px) {
                      header .header-bottom-menu > ul > li .menu-level-2 ul li .menu-level-3 li {
                        padding: 0.5rem 0 0.5rem 1rem; } }
                    header .header-bottom-menu > ul > li .menu-level-2 ul li .menu-level-3 li a {
                      display: inline-flex; }
                      @media (min-width: 768px) {
                        header .header-bottom-menu > ul > li .menu-level-2 ul li .menu-level-3 li a {
                          font-weight: normal; } }
                      header .header-bottom-menu > ul > li .menu-level-2 ul li .menu-level-3 li a:hover {
                        color: #0f8f68;
                        background-color: #e3e3e3; }
                        @media (min-width: 768px) {
                          header .header-bottom-menu > ul > li .menu-level-2 ul li .menu-level-3 li a:hover {
                            background: none;
                            color: #000;
                            font-weight: bold;
                            cursor: pointer; } }
                      header .header-bottom-menu > ul > li .menu-level-2 ul li .menu-level-3 li a .menu-level-3-icon {
                        display: block;
                        width: 9rem; }
                        @media (min-width: 545px) {
                          header .header-bottom-menu > ul > li .menu-level-2 ul li .menu-level-3 li a .menu-level-3-icon {
                            margin-right: 1rem; } }
                        @media (min-width: 768px) {
                          header .header-bottom-menu > ul > li .menu-level-2 ul li .menu-level-3 li a .menu-level-3-icon {
                            height: 4rem;
                            width: 4rem; } }
                        header .header-bottom-menu > ul > li .menu-level-2 ul li .menu-level-3 li a .menu-level-3-icon img {
                          width: 60%;
                          padding: 0; }
                          @media (min-width: 360px) {
                            header .header-bottom-menu > ul > li .menu-level-2 ul li .menu-level-3 li a .menu-level-3-icon img {
                              width: 70%; } }
                          @media (min-width: 768px) {
                            header .header-bottom-menu > ul > li .menu-level-2 ul li .menu-level-3 li a .menu-level-3-icon img {
                              width: 80%; } }
        header .header-bottom-menu > ul .menu-level-1-icon-nhl::before {
          background-image: url("../img/svg/menu_nhl_icon.svg"); }
        header .header-bottom-menu > ul .menu-level-1-icon-nfl::before {
          background-image: url("../img/svg/menu_nfl_icon.svg"); }
        header .header-bottom-menu > ul .menu-level-1-icon-mlb::before {
          background-image: url("../img/svg/menu_mlb_icon.svg"); }
        header .header-bottom-menu > ul .menu-level-1-icon-nba::before {
          background-image: url("../img/svg/menu_nba_icon.svg"); }
        header .header-bottom-menu > ul .menu-level-1-icon-ufc::before {
          background-image: url("../img/svg/menu_ufc_icon.svg"); }
        header .header-bottom-menu > ul .menu-level-1-icon-mls::before {
          background-image: url("../img/svg/menu_mls_icon.svg"); }
        header .header-bottom-menu > ul.Highlighted a {
          color: #0bc189; }
        header .header-bottom-menu > ul ul {
          width: 100%;
          opacity: 1;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
          filter: alpha(opacity=100);
          visibility: visible;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          @media (min-width: 768px) {
            header .header-bottom-menu > ul ul {
              position: absolute;
              width: 20rem;
              visibility: visible;
              opacity: 1;
              -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
              filter: alpha(opacity=100);
              display: block;
              top: 100%; } }
        header .header-bottom-menu > ul.hovered > a, header .header-bottom-menu > ul:hover > a {
          color: #fff;
          background-color: #212121;
          font-weight: bold; }
        header .header-bottom-menu > ul.hovered > ul, header .header-bottom-menu > ul:hover > ul {
          visibility: visible;
          opacity: 1;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
          filter: alpha(opacity=100); }
        header .header-bottom-menu > ul.open .menu-openner:after {
          -ms-transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }
        header .header-bottom-menu > ul.open ul {
          display: block; }
    header .header-bottom .menu-openner:hover {
      color: #0f8f68; }
      @media (min-width: 768px) {
        header .header-bottom .menu-openner:hover {
          color: #fff; } }
    @media (max-width: 767px) {
      header .header-bottom .menu-openner:after {
        content: "\e90b";
        font-size: 2rem;
        position: absolute;
        right: 5rem;
        margin-top: 3.5rem;
        transition: transform .3s ease; } }
  @media (max-width: 767px) and (min-width: 360px) {
    header .header-bottom .menu-openner:after {
      margin-top: 4rem; } }
    header .header-bottom .menu-openner.open:after {
      transform: rotate(180deg); }
  header .header-hamburger {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -12rem;
    width: 11.5rem;
    height: 12rem;
    padding: 3rem 2rem; }
    @media (min-width: 768px) {
      header .header-hamburger {
        display: none; } }
    @media (min-width: 360px) {
      header .header-hamburger {
        width: 12.5rem;
        padding: 3rem 3rem; } }
    header .header-hamburger .open-menu {
      width: 6rem;
      height: 6rem;
      position: relative;
      display: block; }
      header .header-hamburger .open-menu span {
        width: 5rem;
        position: absolute;
        left: 0.5rem;
        height: 3px;
        background: #fff;
        margin-top: -1px;
        top: 50%;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
        header .header-hamburger .open-menu span:before, header .header-hamburger .open-menu span:after {
          content: "";
          width: 5rem;
          left: 0;
          height: 3px;
          background: #fff;
          position: absolute;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
        header .header-hamburger .open-menu span:before {
          top: -1.5rem; }
        header .header-hamburger .open-menu span:after {
          bottom: -1.5rem; }
  header.open .header-hamburger {
    background-color: #f4f4f4; }
    header.open .header-hamburger .open-menu span {
      background: none; }
      header.open .header-hamburger .open-menu span:before {
        top: 0;
        height: 4px;
        background: #0f8f68;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg); }
      header.open .header-hamburger .open-menu span:after {
        top: 0;
        height: 4px;
        background: #0f8f68;
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg); }
  header.open .header-bottom-menu {
    display: block; }

.EditMode header,
.DesignMode header {
  position: static; }

footer {
  padding: 4.5rem 0;
  min-height: 33rem;
  height: auto;
  background-color: #000; }
  footer .footer {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    height: auto; }
    @media (min-width: 768px) {
      footer .footer {
        flex-direction: row;
        height: auto; } }
    footer .footer hr {
      border-color: #818181;
      width: 100%; }
      @media (min-width: 768px) {
        footer .footer hr {
          display: none; } }
    footer .footer .footer-contact {
      padding: 2rem 0; }
    footer .footer .footer-contact-phone,
    footer .footer .footer-contact-email {
      padding: 2rem 0; }
      @media (min-width: 768px) {
        footer .footer .footer-contact-phone,
        footer .footer .footer-contact-email {
          display: none; } }
      footer .footer .footer-contact-phone a,
      footer .footer .footer-contact-email a {
        color: #fff;
        font-size: 3rem;
        padding: 5px 5px; }
        @media (min-width: 768px) {
          footer .footer .footer-contact-phone a,
          footer .footer .footer-contact-email a {
            font-size: 1.8rem; } }
        footer .footer .footer-contact-phone a:hover,
        footer .footer .footer-contact-email a:hover {
          color: #0bc189; }
        footer .footer .footer-contact-phone a::before,
        footer .footer .footer-contact-email a::before {
          padding-right: 2rem;
          font-size: 3rem; }
    footer .footer .footer-contact-phone a::before {
      content: "\e907"; }
    footer .footer .footer-contact-email a::before {
      content: "\e906"; }
    footer .footer .footer-menu,
    footer .footer .footer-contact,
    footer .footer .footer-newsletter,
    footer .footer .footer-social {
      display: flex;
      padding: 5rem 0;
      flex-direction: column; }
      @media (min-width: 768px) {
        footer .footer .footer-menu,
        footer .footer .footer-contact,
        footer .footer .footer-newsletter,
        footer .footer .footer-social {
          padding: 0; } }
      footer .footer .footer-menu h4,
      footer .footer .footer-contact h4,
      footer .footer .footer-newsletter h4,
      footer .footer .footer-social h4 {
        font-size: 3.5rem;
        padding-bottom: 1rem; }
        @media (min-width: 360px) {
          footer .footer .footer-menu h4,
          footer .footer .footer-contact h4,
          footer .footer .footer-newsletter h4,
          footer .footer .footer-social h4 {
            font-size: 4rem; } }
        @media (min-width: 768px) {
          footer .footer .footer-menu h4,
          footer .footer .footer-contact h4,
          footer .footer .footer-newsletter h4,
          footer .footer .footer-social h4 {
            font-size: 2rem;
            padding-bottom: .9rem; } }
    footer .footer .footer-menu {
      color: #fff;
      height: auto;
      width: 100%;
      padding-left: 0; }
      footer .footer .footer-menu ul {
        list-style: none;
        width: 100%;
        color: #fff;
        padding: 0 0;
        line-height: 4rem; }
        footer .footer .footer-menu ul li a {
          font-size: 3rem;
          line-height: 8rem;
          color: #fff;
          text-decoration: underline; }
          @media (min-width: 768px) {
            footer .footer .footer-menu ul li a {
              font-size: 1.8rem;
              line-height: 4rem; } }
          footer .footer .footer-menu ul li a:hover {
            color: #0bc189; }
    footer .footer .footer-contact {
      flex-direction: column; }
    footer .footer .footer-newsletter {
      width: 100%;
      color: #fff;
      flex-direction: column; }
      footer .footer .footer-newsletter label {
        display: none; }
        @media (min-width: 768px) {
          footer .footer .footer-newsletter label {
            display: flex; } }
      footer .footer .footer-newsletter .footer-newsletter-input {
        display: flex;
        flex-direction: row;
        padding-top: 3rem; }
        @media (min-width: 768px) {
          footer .footer .footer-newsletter .footer-newsletter-input {
            padding-top: 1rem; } }
        footer .footer .footer-newsletter .footer-newsletter-input h4 {
          line-height: 2.5rem; }
          @media (min-width: 992px) {
            footer .footer .footer-newsletter .footer-newsletter-input h4 {
              line-height: 1.8rem; } }
      footer .footer .footer-newsletter input {
        height: 10rem;
        width: 70%;
        border-radius: 41px;
        background-color: #000;
        border: 1px solid #fff;
        color: #fff;
        margin-right: 1.5rem; }
        @media (min-width: 480px) {
          footer .footer .footer-newsletter input {
            margin-right: 2rem; } }
        @media (min-width: 480px) {
          footer .footer .footer-newsletter input {
            width: 50rem; } }
        @media (min-width: 768px) {
          footer .footer .footer-newsletter input {
            width: 70%;
            height: 6rem;
            margin-right: 0.5rem; } }
        @media (min-width: 992px) {
          footer .footer .footer-newsletter input {
            width: 50rem; } }
        @media (min-width: 1200px) {
          footer .footer .footer-newsletter input {
            margin-right: 2rem; } }
        footer .footer .footer-newsletter input::placeholder {
          color: #fff;
          opacity: 1; }
      footer .footer .footer-newsletter .btn-default {
        padding: 0;
        width: 30%;
        height: 10rem;
        font-size: 2.5rem; }
        @media (min-width: 480px) {
          footer .footer .footer-newsletter .btn-default {
            width: 20rem; } }
        @media (min-width: 768px) {
          footer .footer .footer-newsletter .btn-default {
            width: 30%;
            height: 6rem;
            font-size: 1.8rem; } }
        @media (min-width: 992px) {
          footer .footer .footer-newsletter .btn-default {
            font-size: 1.8rem;
            width: 20rem;
            letter-spacing: 0; } }
        @media (min-width: 1200px) {
          footer .footer .footer-newsletter .btn-default {
            width: 15rem; } }
    footer .footer .footer-social {
      width: 100%;
      list-style: none;
      color: #fff;
      text-align: left;
      flex-direction: column; }
      @media (min-width: 768px) {
        footer .footer .footer-social {
          text-align: right; } }
      footer .footer .footer-social h4 {
        display: none; }
        @media (min-width: 768px) {
          footer .footer .footer-social h4 {
            display: flex;
            justify-content: flex-end; } }
      footer .footer .footer-social .footer-social-icons {
        display: flex;
        flex-direction: row; }
        @media (min-width: 768px) {
          footer .footer .footer-social .footer-social-icons {
            justify-content: end;
            margin: 0 0 0 auto; } }
        footer .footer .footer-social .footer-social-icons a {
          font-size: 0;
          padding: 0 4rem 0 1rem;
          color: #fff; }
          @media (min-width: 768px) {
            footer .footer .footer-social .footer-social-icons a {
              padding-right: 1rem; } }
          footer .footer .footer-social .footer-social-icons a:before {
            font-size: 4rem; }
        footer .footer .footer-social .footer-social-icons .icon-footer_facebook_icon:hover {
          color: #3b5998; }
        footer .footer .footer-social .footer-social-icons .icon-footer_instagram_icon:hover {
          color: #c32aa3; }
    footer .footer .footer-bottom {
      display: flex;
      flex-direction: column;
      width: 100%; }
      @media (min-width: 768px) {
        footer .footer .footer-bottom {
          flex-direction: row;
          justify-content: space-between; } }
      footer .footer .footer-bottom .text-small {
        display: flex;
        color: #fff; }

.is-xxl {
  display: none; }
  @media (min-width: 1400px) {
    .is-xxl {
      display: block; } }

.full-page-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  display: none; }
  .full-page-spinner.loading {
    position: relative; }
    .full-page-spinner.loading:before {
      content: '';
      z-index: 44;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white; }
    .full-page-spinner.loading .spinner {
      width: 50px;
      height: 50px;
      text-align: center;
      font-size: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 55;
      transform: translateY(-50%) translateX(-50%); }
      .full-page-spinner.loading .spinner > div {
        background-color: #0bc189;
        height: 100%;
        width: 6px;
        display: inline-block;
        -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
        animation: sk-stretchdelay 1.2s infinite ease-in-out; }
        .full-page-spinner.loading .spinner > div.spinner-2 {
          -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }
        .full-page-spinner.loading .spinner > div.spinner-3 {
          -webkit-animation-delay: -1.0s;
          animation-delay: -1.0s; }
        .full-page-spinner.loading .spinner > div.spinner-4 {
          -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }
        .full-page-spinner.loading .spinner > div.spinner-5 {
          -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }

body.loading .full-page-spinner {
  display: block; }
  body.loading .full-page-spinner .spinner {
    display: block; }

body.loading {
  position: relative; }
  body.loading:before {
    content: '';
    z-index: 44;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); }
  body.loading .spinner {
    width: 8rem;
    height: 8rem;
    text-align: center;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 55;
    transform: translateY(-50%) translateX(-50%); }
    body.loading .spinner > div {
      background-color: #0bc189;
      height: 100%;
      width: 1rem;
      display: inline-block;
      -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
      animation: sk-stretchdelay 1.2s infinite ease-in-out; }
      body.loading .spinner > div.spinner-2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s; }
      body.loading .spinner > div.spinner-3 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s; }
      body.loading .spinner > div.spinner-4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s; }
      body.loading .spinner > div.spinner-5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s; }

body.loading:before {
  position: fixed;
  z-index: 999; }

body.loading .spinner {
  position: fixed;
  z-index: 9999; }

@keyframes animateLoaded__ {
  0% {
    opacity: 0;
    filter: blur(10px); }
  100% {
    opacity: 1;
    filter: blur(0); } }

[data-lazy-type].loaded {
  animation: animateLoaded__ 0.1s ease-out forwards; }

.menu-level-1-li.no-submenu:after {
  display: none !important; }

.menu-openner {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0 !important; }
  @media (min-width: 768px) {
    .menu-openner {
      display: none; } }
  .menu-openner:hover {
    color: #0f8f68; }
    @media (min-width: 768px) {
      .menu-openner:hover {
        color: #fff; } }
  .menu-openner:after {
    margin: 0 !important; }
  @media (max-width: 767px) {
    .menu-openner:after {
      content: "\e90b";
      font-size: 2rem;
      transition: transform .3s ease;
      margin: 0; } }
  .menu-openner.open:after {
    transform: rotate(180deg); }

@keyframes rotating {
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1); } }

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4); }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1); } }

.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
  font-family: 'Core-icons' !important; }

*:before,
*:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.helper-4k {
  max-width: 200rem;
  margin: auto; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.bold {
  font-weight: bold; }

.underline {
  text-decoration: underline; }

.line-through {
  text-decoration: line-through; }

html {
  font-size: 5px; }
  @media (min-width: 480px) {
    html {
      font-size: 5.5px; } }
  @media (min-width: 545px) {
    html {
      font-size: 6px; } }
  @media (min-width: 768px) {
    html {
      font-size: 7.5px; } }
  @media (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media (min-width: 1200px) {
    html {
      font-size: 9px; } }
  @media (min-width: 1400px) {
    html {
      font-size: 10px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (-o-min-device-pixel-ratio: 5 / 4), only screen and (min-resolution: 120dpi), only screen and (min-resolution: 1.25dppx) {
  html {
    font-size: 5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 480px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 480px), only screen and (min-resolution: 120dpi) and (min-width: 480px), only screen and (min-resolution: 1.25dppx) and (min-width: 480px) {
    html {
      font-size: 5.5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 545px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 545px), only screen and (min-resolution: 120dpi) and (min-width: 545px), only screen and (min-resolution: 1.25dppx) and (min-width: 545px) {
    html {
      font-size: 6px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 768px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 768px), only screen and (min-resolution: 120dpi) and (min-width: 768px), only screen and (min-resolution: 1.25dppx) and (min-width: 768px) {
    html {
      font-size: 7.5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 992px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 992px), only screen and (min-resolution: 120dpi) and (min-width: 992px), only screen and (min-resolution: 1.25dppx) and (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1200px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1200px), only screen and (min-resolution: 120dpi) and (min-width: 1200px), only screen and (min-resolution: 1.25dppx) and (min-width: 1200px) {
    html {
      font-size: 7.2px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1400px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1400px), only screen and (min-resolution: 120dpi) and (min-width: 1400px), only screen and (min-resolution: 1.25dppx) and (min-width: 1400px) {
    html {
      font-size: 8px; } }

form,
html,
body {
  font-family: "Roboto", sans-serif;
  height: 100%;
  color: #000; }

@media (min-width: 320px) {
  body {
    font-size: 2.8rem; } }

@media (min-width: 480px) {
  body {
    font-size: 2.54545rem; } }

@media (min-width: 545px) {
  body {
    font-size: 2.33333rem; } }

@media (min-width: 768px) {
  body {
    font-size: 1.86667rem; } }

@media (min-width: 992px) {
  body {
    font-size: 1.75rem; } }

@media (min-width: 1200px) {
  body {
    font-size: 1.6rem; } }

img {
  max-width: 100%;
  height: auto !important; }

@media (min-width: 320px) {
  .pt-5 {
    padding-top: 1rem !important; } }

@media (min-width: 480px) {
  .pt-5 {
    padding-top: 0.90909rem !important; } }

@media (min-width: 545px) {
  .pt-5 {
    padding-top: 0.83333rem !important; } }

@media (min-width: 768px) {
  .pt-5 {
    padding-top: 0.66667rem !important; } }

@media (min-width: 992px) {
  .pt-5 {
    padding-top: 0.625rem !important; } }

@media (min-width: 1200px) {
  .pt-5 {
    padding-top: 0.55556rem !important; } }

@media (min-width: 1400px) {
  .pt-5 {
    padding-top: 0.5rem !important; } }

@media (min-width: 320px) {
  .pb-5 {
    padding-bottom: 1rem !important; } }

@media (min-width: 480px) {
  .pb-5 {
    padding-bottom: 0.90909rem !important; } }

@media (min-width: 545px) {
  .pb-5 {
    padding-bottom: 0.83333rem !important; } }

@media (min-width: 768px) {
  .pb-5 {
    padding-bottom: 0.66667rem !important; } }

@media (min-width: 992px) {
  .pb-5 {
    padding-bottom: 0.625rem !important; } }

@media (min-width: 1200px) {
  .pb-5 {
    padding-bottom: 0.55556rem !important; } }

@media (min-width: 1400px) {
  .pb-5 {
    padding-bottom: 0.5rem !important; } }

@media (min-width: 320px) {
  .mt-5 {
    margin-top: 1rem !important; } }

@media (min-width: 480px) {
  .mt-5 {
    margin-top: 0.90909rem !important; } }

@media (min-width: 545px) {
  .mt-5 {
    margin-top: 0.83333rem !important; } }

@media (min-width: 768px) {
  .mt-5 {
    margin-top: 0.66667rem !important; } }

@media (min-width: 992px) {
  .mt-5 {
    margin-top: 0.625rem !important; } }

@media (min-width: 1200px) {
  .mt-5 {
    margin-top: 0.55556rem !important; } }

@media (min-width: 1400px) {
  .mt-5 {
    margin-top: 0.5rem !important; } }

@media (min-width: 320px) {
  .mb-5 {
    margin-bottom: 1rem !important; } }

@media (min-width: 480px) {
  .mb-5 {
    margin-bottom: 0.90909rem !important; } }

@media (min-width: 545px) {
  .mb-5 {
    margin-bottom: 0.83333rem !important; } }

@media (min-width: 768px) {
  .mb-5 {
    margin-bottom: 0.66667rem !important; } }

@media (min-width: 992px) {
  .mb-5 {
    margin-bottom: 0.625rem !important; } }

@media (min-width: 1200px) {
  .mb-5 {
    margin-bottom: 0.55556rem !important; } }

@media (min-width: 1400px) {
  .mb-5 {
    margin-bottom: 0.5rem !important; } }

@media (min-width: 320px) {
  .pt-10 {
    padding-top: 2rem !important; } }

@media (min-width: 480px) {
  .pt-10 {
    padding-top: 1.81818rem !important; } }

@media (min-width: 545px) {
  .pt-10 {
    padding-top: 1.66667rem !important; } }

@media (min-width: 768px) {
  .pt-10 {
    padding-top: 1.33333rem !important; } }

@media (min-width: 992px) {
  .pt-10 {
    padding-top: 1.25rem !important; } }

@media (min-width: 1200px) {
  .pt-10 {
    padding-top: 1.11111rem !important; } }

@media (min-width: 1400px) {
  .pt-10 {
    padding-top: 1rem !important; } }

@media (min-width: 320px) {
  .pb-10 {
    padding-bottom: 2rem !important; } }

@media (min-width: 480px) {
  .pb-10 {
    padding-bottom: 1.81818rem !important; } }

@media (min-width: 545px) {
  .pb-10 {
    padding-bottom: 1.66667rem !important; } }

@media (min-width: 768px) {
  .pb-10 {
    padding-bottom: 1.33333rem !important; } }

@media (min-width: 992px) {
  .pb-10 {
    padding-bottom: 1.25rem !important; } }

@media (min-width: 1200px) {
  .pb-10 {
    padding-bottom: 1.11111rem !important; } }

@media (min-width: 1400px) {
  .pb-10 {
    padding-bottom: 1rem !important; } }

@media (min-width: 320px) {
  .mt-10 {
    margin-top: 2rem !important; } }

@media (min-width: 480px) {
  .mt-10 {
    margin-top: 1.81818rem !important; } }

@media (min-width: 545px) {
  .mt-10 {
    margin-top: 1.66667rem !important; } }

@media (min-width: 768px) {
  .mt-10 {
    margin-top: 1.33333rem !important; } }

@media (min-width: 992px) {
  .mt-10 {
    margin-top: 1.25rem !important; } }

@media (min-width: 1200px) {
  .mt-10 {
    margin-top: 1.11111rem !important; } }

@media (min-width: 1400px) {
  .mt-10 {
    margin-top: 1rem !important; } }

@media (min-width: 320px) {
  .mb-10 {
    margin-bottom: 2rem !important; } }

@media (min-width: 480px) {
  .mb-10 {
    margin-bottom: 1.81818rem !important; } }

@media (min-width: 545px) {
  .mb-10 {
    margin-bottom: 1.66667rem !important; } }

@media (min-width: 768px) {
  .mb-10 {
    margin-bottom: 1.33333rem !important; } }

@media (min-width: 992px) {
  .mb-10 {
    margin-bottom: 1.25rem !important; } }

@media (min-width: 1200px) {
  .mb-10 {
    margin-bottom: 1.11111rem !important; } }

@media (min-width: 1400px) {
  .mb-10 {
    margin-bottom: 1rem !important; } }

@media (min-width: 320px) {
  .pt-15 {
    padding-top: 3rem !important; } }

@media (min-width: 480px) {
  .pt-15 {
    padding-top: 2.72727rem !important; } }

@media (min-width: 545px) {
  .pt-15 {
    padding-top: 2.5rem !important; } }

@media (min-width: 768px) {
  .pt-15 {
    padding-top: 2rem !important; } }

@media (min-width: 992px) {
  .pt-15 {
    padding-top: 1.875rem !important; } }

@media (min-width: 1200px) {
  .pt-15 {
    padding-top: 1.66667rem !important; } }

@media (min-width: 1400px) {
  .pt-15 {
    padding-top: 1.5rem !important; } }

@media (min-width: 320px) {
  .pb-15 {
    padding-bottom: 3rem !important; } }

@media (min-width: 480px) {
  .pb-15 {
    padding-bottom: 2.72727rem !important; } }

@media (min-width: 545px) {
  .pb-15 {
    padding-bottom: 2.5rem !important; } }

@media (min-width: 768px) {
  .pb-15 {
    padding-bottom: 2rem !important; } }

@media (min-width: 992px) {
  .pb-15 {
    padding-bottom: 1.875rem !important; } }

@media (min-width: 1200px) {
  .pb-15 {
    padding-bottom: 1.66667rem !important; } }

@media (min-width: 1400px) {
  .pb-15 {
    padding-bottom: 1.5rem !important; } }

@media (min-width: 320px) {
  .mt-15 {
    margin-top: 3rem !important; } }

@media (min-width: 480px) {
  .mt-15 {
    margin-top: 2.72727rem !important; } }

@media (min-width: 545px) {
  .mt-15 {
    margin-top: 2.5rem !important; } }

@media (min-width: 768px) {
  .mt-15 {
    margin-top: 2rem !important; } }

@media (min-width: 992px) {
  .mt-15 {
    margin-top: 1.875rem !important; } }

@media (min-width: 1200px) {
  .mt-15 {
    margin-top: 1.66667rem !important; } }

@media (min-width: 1400px) {
  .mt-15 {
    margin-top: 1.5rem !important; } }

@media (min-width: 320px) {
  .mb-15 {
    margin-bottom: 3rem !important; } }

@media (min-width: 480px) {
  .mb-15 {
    margin-bottom: 2.72727rem !important; } }

@media (min-width: 545px) {
  .mb-15 {
    margin-bottom: 2.5rem !important; } }

@media (min-width: 768px) {
  .mb-15 {
    margin-bottom: 2rem !important; } }

@media (min-width: 992px) {
  .mb-15 {
    margin-bottom: 1.875rem !important; } }

@media (min-width: 1200px) {
  .mb-15 {
    margin-bottom: 1.66667rem !important; } }

@media (min-width: 1400px) {
  .mb-15 {
    margin-bottom: 1.5rem !important; } }

@media (min-width: 320px) {
  .pt-20 {
    padding-top: 4rem !important; } }

@media (min-width: 480px) {
  .pt-20 {
    padding-top: 3.63636rem !important; } }

@media (min-width: 545px) {
  .pt-20 {
    padding-top: 3.33333rem !important; } }

@media (min-width: 768px) {
  .pt-20 {
    padding-top: 2.66667rem !important; } }

@media (min-width: 992px) {
  .pt-20 {
    padding-top: 2.5rem !important; } }

@media (min-width: 1200px) {
  .pt-20 {
    padding-top: 2.22222rem !important; } }

@media (min-width: 1400px) {
  .pt-20 {
    padding-top: 2rem !important; } }

@media (min-width: 320px) {
  .pb-20 {
    padding-bottom: 4rem !important; } }

@media (min-width: 480px) {
  .pb-20 {
    padding-bottom: 3.63636rem !important; } }

@media (min-width: 545px) {
  .pb-20 {
    padding-bottom: 3.33333rem !important; } }

@media (min-width: 768px) {
  .pb-20 {
    padding-bottom: 2.66667rem !important; } }

@media (min-width: 992px) {
  .pb-20 {
    padding-bottom: 2.5rem !important; } }

@media (min-width: 1200px) {
  .pb-20 {
    padding-bottom: 2.22222rem !important; } }

@media (min-width: 1400px) {
  .pb-20 {
    padding-bottom: 2rem !important; } }

@media (min-width: 320px) {
  .mt-20 {
    margin-top: 4rem !important; } }

@media (min-width: 480px) {
  .mt-20 {
    margin-top: 3.63636rem !important; } }

@media (min-width: 545px) {
  .mt-20 {
    margin-top: 3.33333rem !important; } }

@media (min-width: 768px) {
  .mt-20 {
    margin-top: 2.66667rem !important; } }

@media (min-width: 992px) {
  .mt-20 {
    margin-top: 2.5rem !important; } }

@media (min-width: 1200px) {
  .mt-20 {
    margin-top: 2.22222rem !important; } }

@media (min-width: 1400px) {
  .mt-20 {
    margin-top: 2rem !important; } }

@media (min-width: 320px) {
  .mb-20 {
    margin-bottom: 4rem !important; } }

@media (min-width: 480px) {
  .mb-20 {
    margin-bottom: 3.63636rem !important; } }

@media (min-width: 545px) {
  .mb-20 {
    margin-bottom: 3.33333rem !important; } }

@media (min-width: 768px) {
  .mb-20 {
    margin-bottom: 2.66667rem !important; } }

@media (min-width: 992px) {
  .mb-20 {
    margin-bottom: 2.5rem !important; } }

@media (min-width: 1200px) {
  .mb-20 {
    margin-bottom: 2.22222rem !important; } }

@media (min-width: 1400px) {
  .mb-20 {
    margin-bottom: 2rem !important; } }

@media (min-width: 320px) {
  .pt-25 {
    padding-top: 4rem !important; } }

@media (min-width: 480px) {
  .pt-25 {
    padding-top: 3.63636rem !important; } }

@media (min-width: 545px) {
  .pt-25 {
    padding-top: 3.33333rem !important; } }

@media (min-width: 768px) {
  .pt-25 {
    padding-top: 2.66667rem !important; } }

@media (min-width: 992px) {
  .pt-25 {
    padding-top: 2.5rem !important; } }

@media (min-width: 320px) {
  .pb-25 {
    padding-bottom: 4rem !important; } }

@media (min-width: 480px) {
  .pb-25 {
    padding-bottom: 3.63636rem !important; } }

@media (min-width: 545px) {
  .pb-25 {
    padding-bottom: 3.33333rem !important; } }

@media (min-width: 768px) {
  .pb-25 {
    padding-bottom: 2.66667rem !important; } }

@media (min-width: 992px) {
  .pb-25 {
    padding-bottom: 2.5rem !important; } }

@media (min-width: 320px) {
  .mt-25 {
    margin-top: 4rem !important; } }

@media (min-width: 480px) {
  .mt-25 {
    margin-top: 3.63636rem !important; } }

@media (min-width: 545px) {
  .mt-25 {
    margin-top: 3.33333rem !important; } }

@media (min-width: 768px) {
  .mt-25 {
    margin-top: 2.66667rem !important; } }

@media (min-width: 992px) {
  .mt-25 {
    margin-top: 2.5rem !important; } }

@media (min-width: 320px) {
  .mb-25 {
    margin-bottom: 4rem !important; } }

@media (min-width: 480px) {
  .mb-25 {
    margin-bottom: 3.63636rem !important; } }

@media (min-width: 545px) {
  .mb-25 {
    margin-bottom: 3.33333rem !important; } }

@media (min-width: 768px) {
  .mb-25 {
    margin-bottom: 2.66667rem !important; } }

@media (min-width: 992px) {
  .mb-25 {
    margin-bottom: 2.5rem !important; } }

@media (min-width: 320px) {
  .pt-30 {
    padding-top: 4rem !important; } }

@media (min-width: 480px) {
  .pt-30 {
    padding-top: 3.63636rem !important; } }

@media (min-width: 545px) {
  .pt-30 {
    padding-top: 3.33333rem !important; } }

@media (min-width: 768px) {
  .pt-30 {
    padding-top: 3rem !important; } }

@media (min-width: 320px) {
  .pb-30 {
    padding-bottom: 4rem !important; } }

@media (min-width: 480px) {
  .pb-30 {
    padding-bottom: 3.63636rem !important; } }

@media (min-width: 545px) {
  .pb-30 {
    padding-bottom: 3.33333rem !important; } }

@media (min-width: 768px) {
  .pb-30 {
    padding-bottom: 3rem !important; } }

@media (min-width: 320px) {
  .mt-30 {
    margin-top: 4rem !important; } }

@media (min-width: 480px) {
  .mt-30 {
    margin-top: 3.63636rem !important; } }

@media (min-width: 545px) {
  .mt-30 {
    margin-top: 3.33333rem !important; } }

@media (min-width: 768px) {
  .mt-30 {
    margin-top: 3rem !important; } }

@media (min-width: 320px) {
  .mb-30 {
    margin-bottom: 4rem !important; } }

@media (min-width: 480px) {
  .mb-30 {
    margin-bottom: 3.63636rem !important; } }

@media (min-width: 545px) {
  .mb-30 {
    margin-bottom: 3.33333rem !important; } }

@media (min-width: 768px) {
  .mb-30 {
    margin-bottom: 3rem !important; } }

@media (min-width: 320px) {
  .pt-35 {
    padding-top: 4rem !important; } }

@media (min-width: 480px) {
  .pt-35 {
    padding-top: 3.63636rem !important; } }

@media (min-width: 545px) {
  .pt-35 {
    padding-top: 3.5rem !important; } }

@media (min-width: 320px) {
  .pb-35 {
    padding-bottom: 4rem !important; } }

@media (min-width: 480px) {
  .pb-35 {
    padding-bottom: 3.63636rem !important; } }

@media (min-width: 545px) {
  .pb-35 {
    padding-bottom: 3.5rem !important; } }

@media (min-width: 320px) {
  .mt-35 {
    margin-top: 4rem !important; } }

@media (min-width: 480px) {
  .mt-35 {
    margin-top: 3.63636rem !important; } }

@media (min-width: 545px) {
  .mt-35 {
    margin-top: 3.5rem !important; } }

@media (min-width: 320px) {
  .mb-35 {
    margin-bottom: 4rem !important; } }

@media (min-width: 480px) {
  .mb-35 {
    margin-bottom: 3.63636rem !important; } }

@media (min-width: 545px) {
  .mb-35 {
    margin-bottom: 3.5rem !important; } }

@media (min-width: 320px) {
  .pt-40 {
    padding-top: 4rem !important; } }

@media (min-width: 320px) {
  .pb-40 {
    padding-bottom: 4rem !important; } }

@media (min-width: 320px) {
  .mt-40 {
    margin-top: 4rem !important; } }

@media (min-width: 320px) {
  .mb-40 {
    margin-bottom: 4rem !important; } }

@media (min-width: 320px) {
  .pt-45 {
    padding-top: 4.5rem !important; } }

@media (min-width: 320px) {
  .pb-45 {
    padding-bottom: 4.5rem !important; } }

@media (min-width: 320px) {
  .mt-45 {
    margin-top: 4.5rem !important; } }

@media (min-width: 320px) {
  .mb-45 {
    margin-bottom: 4.5rem !important; } }

@media (min-width: 320px) {
  .pt-50 {
    padding-top: 5rem !important; } }

@media (min-width: 320px) {
  .pb-50 {
    padding-bottom: 5rem !important; } }

@media (min-width: 320px) {
  .mt-50 {
    margin-top: 5rem !important; } }

@media (min-width: 320px) {
  .mb-50 {
    margin-bottom: 5rem !important; } }

@media (min-width: 320px) {
  .pt-55 {
    padding-top: 5.5rem !important; } }

@media (min-width: 320px) {
  .pb-55 {
    padding-bottom: 5.5rem !important; } }

@media (min-width: 320px) {
  .mt-55 {
    margin-top: 5.5rem !important; } }

@media (min-width: 320px) {
  .mb-55 {
    margin-bottom: 5.5rem !important; } }

@media (min-width: 320px) {
  .pt-60 {
    padding-top: 6rem !important; } }

@media (min-width: 320px) {
  .pb-60 {
    padding-bottom: 6rem !important; } }

@media (min-width: 320px) {
  .mt-60 {
    margin-top: 6rem !important; } }

@media (min-width: 320px) {
  .mb-60 {
    margin-bottom: 6rem !important; } }

@media (min-width: 320px) {
  .pt-65 {
    padding-top: 6.5rem !important; } }

@media (min-width: 320px) {
  .pb-65 {
    padding-bottom: 6.5rem !important; } }

@media (min-width: 320px) {
  .mt-65 {
    margin-top: 6.5rem !important; } }

@media (min-width: 320px) {
  .mb-65 {
    margin-bottom: 6.5rem !important; } }

@media (min-width: 320px) {
  .pt-70 {
    padding-top: 7rem !important; } }

@media (min-width: 320px) {
  .pb-70 {
    padding-bottom: 7rem !important; } }

@media (min-width: 320px) {
  .mt-70 {
    margin-top: 7rem !important; } }

@media (min-width: 320px) {
  .mb-70 {
    margin-bottom: 7rem !important; } }

@media (min-width: 320px) {
  .pt-75 {
    padding-top: 7.5rem !important; } }

@media (min-width: 320px) {
  .pb-75 {
    padding-bottom: 7.5rem !important; } }

@media (min-width: 320px) {
  .mt-75 {
    margin-top: 7.5rem !important; } }

@media (min-width: 320px) {
  .mb-75 {
    margin-bottom: 7.5rem !important; } }

@media (min-width: 320px) {
  .pt-80 {
    padding-top: 8rem !important; } }

@media (min-width: 320px) {
  .pb-80 {
    padding-bottom: 8rem !important; } }

@media (min-width: 320px) {
  .mt-80 {
    margin-top: 8rem !important; } }

@media (min-width: 320px) {
  .mb-80 {
    margin-bottom: 8rem !important; } }

@media (min-width: 320px) {
  .pt-85 {
    padding-top: 8.5rem !important; } }

@media (min-width: 320px) {
  .pb-85 {
    padding-bottom: 8.5rem !important; } }

@media (min-width: 320px) {
  .mt-85 {
    margin-top: 8.5rem !important; } }

@media (min-width: 320px) {
  .mb-85 {
    margin-bottom: 8.5rem !important; } }

@media (min-width: 320px) {
  .pt-90 {
    padding-top: 9rem !important; } }

@media (min-width: 320px) {
  .pb-90 {
    padding-bottom: 9rem !important; } }

@media (min-width: 320px) {
  .mt-90 {
    margin-top: 9rem !important; } }

@media (min-width: 320px) {
  .mb-90 {
    margin-bottom: 9rem !important; } }

@media (min-width: 320px) {
  .pt-95 {
    padding-top: 9.5rem !important; } }

@media (min-width: 320px) {
  .pb-95 {
    padding-bottom: 9.5rem !important; } }

@media (min-width: 320px) {
  .mt-95 {
    margin-top: 9.5rem !important; } }

@media (min-width: 320px) {
  .mb-95 {
    margin-bottom: 9.5rem !important; } }

@media (min-width: 320px) {
  .pt-100 {
    padding-top: 10rem !important; } }

@media (min-width: 320px) {
  .pb-100 {
    padding-bottom: 10rem !important; } }

@media (min-width: 320px) {
  .mt-100 {
    margin-top: 10rem !important; } }

@media (min-width: 320px) {
  .mb-100 {
    margin-bottom: 10rem !important; } }

h1,
.h1 {
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.5rem 0 2.5rem 0; }
  @media (min-width: 320px) {
    h1,
    .h1 {
      font-size: 4rem; } }
  @media (min-width: 320px) {
    h1,
    .h1 {
      line-height: 4rem; } }

h2,
.h2 {
  padding: 0.5rem 0 1rem 0; }
  @media (min-width: 320px) {
    h2,
    .h2 {
      font-size: 3rem; } }
  @media (min-width: 320px) {
    h2,
    .h2 {
      line-height: 3rem; } }

h3,
.h3 {
  padding: 0.5rem 0 1rem 0; }
  @media (min-width: 320px) {
    h3,
    .h3 {
      font-size: 2.4rem; } }
  @media (min-width: 320px) {
    h3,
    .h3 {
      line-height: 2.4rem; } }

@media (min-width: 320px) {
  h4,
  .h4 {
    font-size: 2.8rem; } }

@media (min-width: 480px) {
  h4,
  .h4 {
    font-size: 2.54545rem; } }

@media (min-width: 545px) {
  h4,
  .h4 {
    font-size: 2.33333rem; } }

@media (min-width: 768px) {
  h4,
  .h4 {
    font-size: 1.86667rem; } }

@media (min-width: 992px) {
  h4,
  .h4 {
    font-size: 1.8rem; } }

@media (min-width: 320px) {
  h4,
  .h4 {
    line-height: 2.8rem; } }

@media (min-width: 480px) {
  h4,
  .h4 {
    line-height: 2.54545rem; } }

@media (min-width: 545px) {
  h4,
  .h4 {
    line-height: 2.33333rem; } }

@media (min-width: 768px) {
  h4,
  .h4 {
    line-height: 1.86667rem; } }

@media (min-width: 992px) {
  h4,
  .h4 {
    line-height: 1.8rem; } }

big,
.perex {
  padding: 0.5rem 0; }
  @media (min-width: 320px) {
    big,
    .perex {
      font-size: 2.8rem; } }
  @media (min-width: 480px) {
    big,
    .perex {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    big,
    .perex {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    big,
    .perex {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    big,
    .perex {
      font-size: 1.8rem; } }
  @media (min-width: 320px) {
    big,
    .perex {
      line-height: 3.6rem; } }
  @media (min-width: 480px) {
    big,
    .perex {
      line-height: 3.27273rem; } }
  @media (min-width: 545px) {
    big,
    .perex {
      line-height: 3rem; } }
  @media (min-width: 768px) {
    big,
    .perex {
      line-height: 2.4rem; } }
  @media (min-width: 992px) {
    big,
    .perex {
      line-height: 2.25rem; } }
  @media (min-width: 1200px) {
    big,
    .perex {
      line-height: 2.2rem; } }

small,
.text-small {
  color: #000;
  line-height: 2.8rem;
  padding: 1rem 0; }
  @media (min-width: 320px) {
    small,
    .text-small {
      font-size: 2.4rem; } }
  @media (min-width: 480px) {
    small,
    .text-small {
      font-size: 2.18182rem; } }
  @media (min-width: 545px) {
    small,
    .text-small {
      font-size: 2rem; } }
  @media (min-width: 768px) {
    small,
    .text-small {
      font-size: 1.6rem; } }
  @media (min-width: 992px) {
    small,
    .text-small {
      font-size: 1.5rem; } }
  @media (min-width: 1200px) {
    small,
    .text-small {
      font-size: 1.4rem; } }
  @media (min-width: 320px) {
    small,
    .text-small {
      line-height: 4rem; } }
  @media (min-width: 480px) {
    small,
    .text-small {
      line-height: 3.63636rem; } }
  @media (min-width: 545px) {
    small,
    .text-small {
      line-height: 3.33333rem; } }
  @media (min-width: 768px) {
    small,
    .text-small {
      line-height: 2.8rem; } }

small,
.text-small-dark {
  color: #fff;
  line-height: 2.8rem;
  padding: 1rem 0; }
  @media (min-width: 320px) {
    small,
    .text-small-dark {
      font-size: 2.4rem; } }
  @media (min-width: 480px) {
    small,
    .text-small-dark {
      font-size: 2.18182rem; } }
  @media (min-width: 545px) {
    small,
    .text-small-dark {
      font-size: 2rem; } }
  @media (min-width: 768px) {
    small,
    .text-small-dark {
      font-size: 1.6rem; } }
  @media (min-width: 992px) {
    small,
    .text-small-dark {
      font-size: 1.5rem; } }
  @media (min-width: 1200px) {
    small,
    .text-small-dark {
      font-size: 1.4rem; } }
  @media (min-width: 320px) {
    small,
    .text-small-dark {
      line-height: 4rem; } }
  @media (min-width: 480px) {
    small,
    .text-small-dark {
      line-height: 3.63636rem; } }
  @media (min-width: 545px) {
    small,
    .text-small-dark {
      line-height: 3.33333rem; } }
  @media (min-width: 768px) {
    small,
    .text-small-dark {
      line-height: 2.8rem; } }

a {
  color: #0f8f68;
  cursor: pointer; }
  a:focus, a:hover {
    color: #0a6146;
    outline: none;
    text-decoration: none; }

.link {
  display: inline-flex;
  align-items: center;
  color: #0f8f68;
  cursor: pointer;
  text-decoration: underline;
  padding: 1.5rem 0; }
  .link:before {
    font-size: 4rem;
    margin: 0 10px 0 0; }
    @media (min-width: 480px) {
      .link:before {
        font-size: 3.63636rem; } }
    @media (min-width: 545px) {
      .link:before {
        font-size: 3.33333rem; } }
    @media (min-width: 768px) {
      .link:before {
        font-size: 2.66667rem; } }
    @media (min-width: 992px) {
      .link:before {
        font-size: 2.5rem; } }
    @media (min-width: 1200px) {
      .link:before {
        font-size: 2.22222rem; } }
    @media (min-width: 1400px) {
      .link:before {
        font-size: 2rem; } }
  .link:hover, .link:focus {
    text-decoration: none;
    color: #0a6146; }
  .link.no-text {
    font-size: 0; }
    .link.no-text:before {
      margin: 0; }
  .link.loading:before {
    content: "";
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top: 3px solid #0bc189;
    animation: rotating 1.2s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86); }

.main-price {
  color: #0bc189;
  font-weight: bold;
  font-size: 4rem; }
  @media (min-width: 768px) {
    .main-price {
      font-size: 2.5rem; } }

.main-label {
  font-size: 14px; }

.btn {
  display: inline-flex;
  align-items: center;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  justify-content: center;
  border: none;
  padding: 1.5rem 3rem;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  letter-spacing: 0.1rem; }
  @media (min-width: 320px) {
    .btn {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    .btn {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .btn {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .btn {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .btn {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .btn {
      font-size: 1.8rem; } }
  .btn:before {
    font-size: 4rem;
    margin: 0 10px 0 0; }
    @media (min-width: 480px) {
      .btn:before {
        font-size: 3.63636rem; } }
    @media (min-width: 545px) {
      .btn:before {
        font-size: 3.33333rem; } }
    @media (min-width: 768px) {
      .btn:before {
        font-size: 2.66667rem; } }
    @media (min-width: 992px) {
      .btn:before {
        font-size: 2.5rem; } }
    @media (min-width: 1200px) {
      .btn:before {
        font-size: 2.22222rem; } }
    @media (min-width: 1400px) {
      .btn:before {
        font-size: 2rem; } }
  .btn:focus, .btn:hover {
    text-decoration: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
  .btn.loading:before {
    content: "";
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top: 3px solid #0bc189;
    animation: rotating 1.2s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86); }

.btn-primary, .btn-primary-cart {
  color: #fff;
  background-color: #0bc189;
  font-size: 20px;
  font-weight: bold;
  padding: 1.5rem 3rem;
  border-radius: 41px;
  border: none; }
  .btn-primary:focus, .btn-primary-cart:focus, .btn-primary:hover, .btn-primary-cart:hover {
    text-decoration: none;
    background-color: #0f8f68;
    color: #fff; }
  .btn-primary::before, .btn-primary-cart::before {
    content: "\e904";
    font-size: 2.6rem;
    padding: 0;
    margin: 0 5px 0 0; }
    @media (min-width: 360px) {
      .btn-primary::before, .btn-primary-cart::before {
        padding: 0 1.5rem 0 0;
        margin: 0 10px 0 0; } }
  .btn-primary.loading:before, .loading.btn-primary-cart:before {
    border-color: rgba(255, 255, 255, 0.3);
    border-top-color: #b7b7b7; }

.btn-primary-cart {
  display: flex;
  background-color: transparent;
  font-size: 0;
  color: #fff;
  margin: 0;
  padding: 0;
  line-height: 5rem;
  margin-right: 1rem; }
  @media (min-width: 768px) {
    .btn-primary-cart {
      width: 22rem;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      color: #000;
      padding: 0 3rem;
      font-weight: normal;
      font-size: 1.8rem;
      letter-spacing: 0;
      height: 5.5rem;
      margin: auto auto; } }
  @media (min-width: 992px) {
    .btn-primary-cart {
      line-height: 4rem; } }
  @media (min-width: 1200px) {
    .btn-primary-cart {
      line-height: 4rem; } }
  .btn-primary-cart::before {
    display: block;
    vertical-align: middle;
    font-size: 4rem;
    margin: 0 0.6rem 0.3rem 0; }
    @media (min-width: 360px) {
      .btn-primary-cart::before {
        font-size: 4rem;
        margin: 0 0.3rem 0.3rem 0; } }
    @media (min-width: 768px) {
      .btn-primary-cart::before {
        font-size: 3rem; } }
  .btn-primary-cart:hover::before {
    color: #0bc189; }
    @media (min-width: 768px) {
      .btn-primary-cart:hover::before {
        color: #fff; } }
  .btn-primary-cart:hover {
    background-color: transparent; }
    @media (min-width: 768px) {
      .btn-primary-cart:hover {
        background-color: #0bc189;
        color: #fff; } }

#btn-contains-items {
  color: #fff; }
  @media (min-width: 768px) {
    #btn-contains-items {
      background-color: #0bc189; } }
  #btn-contains-items:focus, #btn-contains-items:hover {
    text-decoration: none;
    background-color: transparent; }
    @media (min-width: 768px) {
      #btn-contains-items:focus, #btn-contains-items:hover {
        color: #fff;
        background-color: #0f8f68; } }
  #btn-contains-items:hover::before {
    color: #0bc189; }
    @media (min-width: 768px) {
      #btn-contains-items:hover::before {
        color: #fff; } }
  #btn-contains-items::after {
    display: flex;
    justify-content: center;
    position: relative;
    left: -8px;
    top: -12px;
    content: "3";
    width: 18px;
    height: 18px;
    border-radius: 50%;
    padding-top: 2px;
    margin-left: -10px;
    margin-top: 7px;
    font-size: 2.5rem;
    font-weight: bold;
    background-color: #0bc189;
    color: #fff; }
    @media (min-width: 480px) {
      #btn-contains-items::after {
        padding-top: 1px;
        left: -14px;
        top: -13px; } }
    @media (min-width: 545px) {
      #btn-contains-items::after {
        padding-top: 0; } }
    @media (min-width: 768px) {
      #btn-contains-items::after {
        content: none; } }

.btn-default {
  font-size: 20px;
  font-weight: bold;
  padding: 17px 30px;
  border-radius: 41px;
  border: 1px solid #000;
  text-indent: initial;
  background: #fff;
  color: #000; }
  .btn-default:focus, .btn-default:hover {
    text-decoration: none;
    background: #000;
    color: #fff; }
  .btn-default.loading:before {
    border-color: rgba(255, 255, 255, 0.3);
    border-top-color: #0bc189; }

.sec-hover {
  background-color: #000;
  color: #fff; }

.dark-bcg {
  color: #000;
  font-weight: normal;
  border: none; }
  .dark-bcg:hover {
    background-color: #0bc189; }

.darkbcg-hover {
  background-color: #0bc189;
  color: #fff;
  font-weight: normal;
  border: none; }

.darkbcg-hover:hover {
  background-color: #0bc189; }

.image-top-center {
  position: top center !important; }

.image-top-left {
  position: top left !important; }

.image-top-right {
  position: top right !important; }

.image-center-center {
  position: center center !important; }

.image-bottom-center {
  position: bottom center !important; }

.image-bottom-left {
  position: bottom left !important; }

.image-bottom-right {
  position: bottom right !important; }

main {
  min-height: 40rem; }
  main ol {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: my-ol; }
    main ol li {
      position: relative;
      margin: 0 0 1.5rem 0;
      padding: 0 0 0 2rem; }
      main ol li:before {
        content: counter(my-ol) ".";
        counter-increment: my-ol;
        color: #0bc189;
        position: absolute;
        left: 0;
        top: 3px;
        font-weight: bold;
        font-family: "Roboto", sans-serif !important; }
    main ol[start="next"] {
      counter-reset: continue; }
      main ol[start="next"] li:before {
        counter-increment: continue; }
    main ol[start="1"] {
      counter-reset: my-ol 1; }
    main ol[start="2"] {
      counter-reset: my-ol 2; }
    main ol[start="3"] {
      counter-reset: my-ol 3; }
    main ol[start="4"] {
      counter-reset: my-ol 4; }
    main ol[start="5"] {
      counter-reset: my-ol 5; }
    main ol[start="6"] {
      counter-reset: my-ol 6; }
    main ol[start="7"] {
      counter-reset: my-ol 7; }
    main ol[start="8"] {
      counter-reset: my-ol 8; }
    main ol[start="9"] {
      counter-reset: my-ol 9; }
    main ol[start="10"] {
      counter-reset: my-ol 10; }
    main ol[start="11"] {
      counter-reset: my-ol 11; }
    main ol[start="12"] {
      counter-reset: my-ol 12; }
    main ol[start="13"] {
      counter-reset: my-ol 13; }
    main ol[start="14"] {
      counter-reset: my-ol 14; }
    main ol[start="15"] {
      counter-reset: my-ol 15; }
    main ol[start="16"] {
      counter-reset: my-ol 16; }
    main ol[start="17"] {
      counter-reset: my-ol 17; }
    main ol[start="18"] {
      counter-reset: my-ol 18; }
    main ol[start="19"] {
      counter-reset: my-ol 19; }
    main ol[start="20"] {
      counter-reset: my-ol 20; }
  main ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    main ul li {
      margin: 0 0 1.5rem 0;
      padding: 0 0 0 1.5rem;
      position: relative; }
      main ul li:before {
        content: "";
        width: 5px;
        height: 5px;
        background: #000;
        position: absolute;
        top: 10px;
        left: 0;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%; }
  main table {
    width: 100%; }
    main table tr {
      border-bottom: 1px solid #d3d3d3;
      line-height: 5rem; }
      main table tr td {
        padding: 1rem 1.5rem; }
  main .respo-table table {
    width: 100%; }
    main .respo-table table thead {
      display: none; }
      @media (min-width: 768px) {
        main .respo-table table thead {
          display: table-header-group; } }
      main .respo-table table thead tr {
        border-bottom: 1px solid #d3d3d3; }
        main .respo-table table thead tr th {
          font-weight: bold;
          padding: 1rem 1.5rem; }
    main .respo-table table tbody tr {
      display: block;
      border: 1px solid #d3d3d3;
      border-bottom: none;
      margin: 0 0 2rem 0; }
      main .respo-table table tbody tr:nth-child(even) {
        background: #d3d3d3; }
      @media (min-width: 768px) {
        main .respo-table table tbody tr {
          display: table-row;
          margin: 0;
          border: none;
          border-bottom: 1px solid #d3d3d3; } }
      main .respo-table table tbody tr td {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: space-between;
        -moz-box-pack: space-between;
        -ms-flex-pack: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #d3d3d3; }
        @media (min-width: 768px) {
          main .respo-table table tbody tr td {
            display: table-cell;
            border: none;
            width: auto; } }
        main .respo-table table tbody tr td:before {
          content: attr(data-th);
          display: block;
          text-align: left;
          font-family: "Roboto", sans-serif !important;
          margin: 0 1.5rem 0 0; }
          @media (min-width: 768px) {
            main .respo-table table tbody tr td:before {
              display: none; } }

@keyframes rotating {
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1); } }

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4); }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1); } }

.row:after {
  content: "";
  display: table;
  clear: both; }

.container {
  position: relative;
  display: block; }

.wrapper-product {
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .wrapper-product {
      flex-direction: row;
      flex-wrap: wrap; } }
  @media (min-width: 545px) {
    .wrapper-product .product-gallery {
      padding-bottom: 2rem; } }
  @media (min-width: 768px) {
    .wrapper-product .product-gallery {
      padding: 5rem 3rem;
      width: 50%; } }
  @media (min-width: 1200px) {
    .wrapper-product .product-gallery {
      padding: 5rem 5rem;
      width: 50%; } }
  @media (min-width: 1400px) {
    .wrapper-product .product-gallery {
      padding: 5rem 7rem; } }
  .wrapper-product .product-gallery .product-gallery-detail {
    display: block;
    width: 95%;
    margin: auto;
    height: 55rem; }
    @media (min-width: 360px) {
      .wrapper-product .product-gallery .product-gallery-detail {
        height: 65rem; } }
    @media (min-width: 545px) {
      .wrapper-product .product-gallery .product-gallery-detail {
        height: 70rem; } }
    @media (min-width: 768px) {
      .wrapper-product .product-gallery .product-gallery-detail {
        height: 55rem; } }
    @media (min-width: 1200px) {
      .wrapper-product .product-gallery .product-gallery-detail {
        height: 53rem; } }
    .wrapper-product .product-gallery .product-gallery-detail img {
      display: block;
      margin: auto;
      width: 55rem; }
      @media (min-width: 360px) {
        .wrapper-product .product-gallery .product-gallery-detail img {
          width: 65rem; } }
      @media (min-width: 545px) {
        .wrapper-product .product-gallery .product-gallery-detail img {
          width: 70rem; } }
      @media (min-width: 992px) {
        .wrapper-product .product-gallery .product-gallery-detail img {
          width: 50rem; } }
  .wrapper-product .product-detail {
    display: flex;
    flex-direction: column; }
    @media (min-width: 768px) {
      .wrapper-product .product-detail {
        width: 50%; } }
    @media (min-width: 1200px) {
      .wrapper-product .product-detail {
        width: 50%; } }
    .wrapper-product .product-detail h1 {
      text-align: left;
      padding-bottom: 0;
      line-height: 5rem; }
      @media (min-width: 768px) {
        .wrapper-product .product-detail h1 {
          font-size: 2.8rem; } }
      @media (min-width: 992px) {
        .wrapper-product .product-detail h1 {
          font-size: 3rem; } }
      @media (min-width: 1200px) {
        .wrapper-product .product-detail h1 {
          font-size: 4rem; } }
    .wrapper-product .product-detail h2 {
      text-align: left;
      font-weight: normal;
      font-size: 2.5rem;
      padding: 1rem 0; }
      @media (min-width: 768px) {
        .wrapper-product .product-detail h2 {
          font-size: 2.3rem; } }
      @media (min-width: 1200px) {
        .wrapper-product .product-detail h2 {
          font-size: 3rem; } }
    .wrapper-product .product-detail .product-detail-club {
      width: 5rem;
      color: #000;
      font-weight: bold;
      padding: 0;
      margin: 3rem auto 4rem 0;
      white-space: nowrap; }
      @media (min-width: 545px) {
        .wrapper-product .product-detail .product-detail-club {
          font-size: 2.3rem; } }
      @media (min-width: 768px) {
        .wrapper-product .product-detail .product-detail-club {
          margin: 1rem auto 1rem 0; } }
      .wrapper-product .product-detail .product-detail-club .product-detail-club-icon {
        display: inline-block;
        width: 10rem; }
        @media (min-width: 768px) {
          .wrapper-product .product-detail .product-detail-club .product-detail-club-icon {
            width: 8rem; } }
        @media (min-width: 1200px) {
          .wrapper-product .product-detail .product-detail-club .product-detail-club-icon {
            width: 7rem; } }
        .wrapper-product .product-detail .product-detail-club .product-detail-club-icon img {
          width: 100%;
          max-width: 10rem;
          padding-right: 2rem; }
          @media (min-width: 992px) {
            .wrapper-product .product-detail .product-detail-club .product-detail-club-icon img {
              max-width: 8rem;
              padding-right: 1rem; } }
    .wrapper-product .product-detail table {
      margin: 6rem 0;
      order: 5; }
      @media (min-width: 768px) {
        .wrapper-product .product-detail table {
          order: 0;
          margin: 1rem 0 2rem 0; } }
      .wrapper-product .product-detail table tr {
        line-height: 10rem; }
        @media (min-width: 768px) {
          .wrapper-product .product-detail table tr {
            line-height: 3rem; } }
        .wrapper-product .product-detail table tr .sold-out {
          color: #ff0000; }
        @media (min-width: 768px) {
          .wrapper-product .product-detail table tr .table-head {
            width: 50%; } }
        @media (min-width: 992px) {
          .wrapper-product .product-detail table tr .table-head {
            width: 35%; } }
        @media (min-width: 1200px) {
          .wrapper-product .product-detail table tr .table-head {
            width: 25%; } }
    @media (min-width: 768px) {
      .wrapper-product .product-detail > .main-label {
        padding-top: 2rem; } }
    .wrapper-product .product-detail .product-purchase-inputs {
      justify-content: space-between; }
      @media (min-width: 768px) {
        .wrapper-product .product-detail .product-purchase-inputs {
          padding-bottom: 1rem;
          justify-content: flex-start; } }
      .wrapper-product .product-detail .product-purchase-inputs .product-purchase-inputs-inner {
        width: 50%; }
        @media (min-width: 768px) {
          .wrapper-product .product-detail .product-purchase-inputs .product-purchase-inputs-inner {
            padding-right: 5rem; } }
        @media (min-width: 768px) {
          .wrapper-product .product-detail .product-purchase-inputs .product-purchase-inputs-inner .main-label {
            padding-left: 0; } }
        .wrapper-product .product-detail .product-purchase-inputs .product-purchase-inputs-inner .selectric-wrapper {
          width: 100%;
          height: 10rem;
          margin: 1rem 0;
          line-height: 7rem;
          font-size: 3rem; }
          @media (min-width: 360px) {
            .wrapper-product .product-detail .product-purchase-inputs .product-purchase-inputs-inner .selectric-wrapper {
              height: 11rem; } }
          @media (min-width: 545px) {
            .wrapper-product .product-detail .product-purchase-inputs .product-purchase-inputs-inner .selectric-wrapper {
              line-height: 8rem;
              font-size: 3rem; } }
          @media (min-width: 768px) {
            .wrapper-product .product-detail .product-purchase-inputs .product-purchase-inputs-inner .selectric-wrapper {
              height: 6rem;
              font-size: 2rem;
              line-height: 3rem; } }
          .wrapper-product .product-detail .product-purchase-inputs .product-purchase-inputs-inner .selectric-wrapper .label {
            font-size: 3rem; }
            @media (min-width: 768px) {
              .wrapper-product .product-detail .product-purchase-inputs .product-purchase-inputs-inner .selectric-wrapper .label {
                font-size: 2rem;
                padding: 0 0.5rem; } }
          .wrapper-product .product-detail .product-purchase-inputs .product-purchase-inputs-inner .selectric-wrapper .selectric-items ul li {
            padding-left: 3.5rem;
            margin-top: 1rem; }
            @media (min-width: 992px) {
              .wrapper-product .product-detail .product-purchase-inputs .product-purchase-inputs-inner .selectric-wrapper .selectric-items ul li {
                padding-left: 2.5rem;
                margin-top: 0; } }
        .wrapper-product .product-detail .product-purchase-inputs .product-purchase-inputs-inner .input-group {
          width: 25rem;
          height: 10rem;
          margin: 1rem 0; }
          @media (min-width: 360px) {
            .wrapper-product .product-detail .product-purchase-inputs .product-purchase-inputs-inner .input-group {
              width: 30rem;
              height: 11rem; } }
          @media (min-width: 768px) {
            .wrapper-product .product-detail .product-purchase-inputs .product-purchase-inputs-inner .input-group {
              height: 6rem;
              width: 17rem; } }
          .wrapper-product .product-detail .product-purchase-inputs .product-purchase-inputs-inner .input-group input {
            margin: auto auto; }
            @media (min-width: 768px) {
              .wrapper-product .product-detail .product-purchase-inputs .product-purchase-inputs-inner .input-group input {
                padding: 0;
                font-size: 2rem; } }
            .wrapper-product .product-detail .product-purchase-inputs .product-purchase-inputs-inner .input-group input:disabled {
              background: none; }
    .wrapper-product .product-detail .btn-default {
      order: 3;
      width: 80%;
      margin: 3rem auto;
      font-size: 3.5rem; }
      @media (min-width: 768px) {
        .wrapper-product .product-detail .btn-default {
          order: 0;
          width: 37rem;
          margin: 0;
          font-size: 2rem; } }
      @media (min-width: 1200px) {
        .wrapper-product .product-detail .btn-default {
          font-size: 2rem;
          width: 30rem; } }
    .wrapper-product .product-detail .main-price {
      font-size: 5rem;
      line-height: 6rem; }
      @media (min-width: 768px) {
        .wrapper-product .product-detail .main-price {
          font-size: 4rem;
          line-height: 5rem; } }
      @media (min-width: 1200px) {
        .wrapper-product .product-detail .main-price {
          font-size: 3rem; } }
      .wrapper-product .product-detail .main-price.sold-out {
        color: #000; }
    .wrapper-product .product-detail .btn-primary, .wrapper-product .product-detail .btn-primary-cart {
      width: 80%;
      padding: 17px 30px;
      margin: 2rem auto;
      order: 4;
      font-size: 3.5rem;
      line-height: 5rem; }
      @media (min-width: 768px) {
        .wrapper-product .product-detail .btn-primary, .wrapper-product .product-detail .btn-primary-cart {
          margin: 1rem 0 0 0;
          line-height: 4rem;
          width: 33rem;
          font-size: 2rem; } }
      @media (min-width: 1200px) {
        .wrapper-product .product-detail .btn-primary, .wrapper-product .product-detail .btn-primary-cart {
          width: 30rem;
          font-size: 2rem;
          line-height: 3rem; } }
      .wrapper-product .product-detail .btn-primary::before, .wrapper-product .product-detail .btn-primary-cart::before {
        display: block;
        vertical-align: middle;
        font-size: 3rem;
        margin: 0 0.6rem 0.3rem 0; }
        @media (min-width: 360px) {
          .wrapper-product .product-detail .btn-primary::before, .wrapper-product .product-detail .btn-primary-cart::before {
            font-size: 4rem;
            margin: 0 0.3rem 0.3rem 0; } }
        @media (min-width: 992px) {
          .wrapper-product .product-detail .btn-primary::before, .wrapper-product .product-detail .btn-primary-cart::before {
            font-size: 3rem; } }
      .wrapper-product .product-detail .btn-primary.sold-out, .wrapper-product .product-detail .sold-out.btn-primary-cart {
        background-color: #818181; }
    .wrapper-product .product-detail .product-gallery-tabs {
      padding: 3rem 3rem; }
      @media (min-width: 992px) {
        .wrapper-product .product-detail .product-gallery-tabs {
          max-width: 400px; } }
  .wrapper-product .product-related {
    margin: auto;
    width: 100%; }
    .wrapper-product .product-related .products-catalogue {
      display: flex; }
    .wrapper-product .product-related .card-item-outer {
      width: 90%; }
      @media (min-width: 545px) {
        .wrapper-product .product-related .card-item-outer {
          width: 50%; } }
      @media (min-width: 768px) {
        .wrapper-product .product-related .card-item-outer {
          width: 50%;
          margin: auto; } }
      @media (min-width: 860px) {
        .wrapper-product .product-related .card-item-outer {
          width: 33%; } }
      @media (min-width: 992px) {
        .wrapper-product .product-related .card-item-outer {
          width: 25%; } }
    .wrapper-product .product-related h2 {
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      padding: 6rem 0 2rem 0; }

.slider-title {
  padding: 0 0 0 30px; }

@keyframes rotating {
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1); } }

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4); }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1); } }

.main-top-box-headline {
  display: flex; }
  @media (min-width: 768px) {
    .main-top-box-headline {
      justify-content: space-between; } }
  .main-top-box-headline .required-info-top {
    display: none; }
    @media (min-width: 768px) {
      .main-top-box-headline .required-info-top {
        display: block;
        padding-top: 1rem; }
        .main-top-box-headline .required-info-top label {
          display: inline;
          color: #ff0000; }
          .main-top-box-headline .required-info-top label:before {
            content: "*"; } }

.required-info-bottom {
  display: flex; }
  .required-info-bottom label {
    display: inline;
    color: #ff0000;
    font-size: 2.5rem; }
    .required-info-bottom label:before {
      content: "*"; }
  @media (min-width: 768px) {
    .required-info-bottom {
      display: none; } }

.main-wrapper .cart-container {
  width: 100%;
  background: #fff; }
  .main-wrapper .cart-container .cart-header {
    display: flex;
    column-count: 3;
    justify-content: center;
    align-items: start;
    border-bottom: 1px solid #d3d3d3; }
    @media (min-width: 768px) {
      .main-wrapper .cart-container .cart-header {
        justify-content: flex-start;
        border-bottom: none; } }
    .main-wrapper .cart-container .cart-header .step {
      width: 100%;
      text-align: center;
      line-height: 4rem;
      margin: 10px 0 20px;
      padding: 0 2rem; }
      @media (min-width: 545px) {
        .main-wrapper .cart-container .cart-header .step {
          padding: 0 4rem; } }
      @media (min-width: 992px) {
        .main-wrapper .cart-container .cart-header .step {
          width: 40rem;
          padding: 0;
          text-align: left; } }
      .main-wrapper .cart-container .cart-header .step:after {
        content: ""; }
        @media (min-width: 992px) {
          .main-wrapper .cart-container .cart-header .step:after {
            content: "\e90d";
            color: #000;
            font-size: 2rem;
            padding-left: 5rem; } }
      .main-wrapper .cart-container .cart-header .step a, .main-wrapper .cart-container .cart-header .step span {
        font-size: 2.5rem; }
        @media (min-width: 360px) {
          .main-wrapper .cart-container .cart-header .step a, .main-wrapper .cart-container .cart-header .step span {
            font-size: 2.8rem; } }
        @media (min-width: 992px) {
          .main-wrapper .cart-container .cart-header .step a, .main-wrapper .cart-container .cart-header .step span {
            font-size: 2.7rem; } }
      .main-wrapper .cart-container .cart-header .step span {
        color: #d3d3d3; }
      .main-wrapper .cart-container .cart-header .step a::before, .main-wrapper .cart-container .cart-header .step span::before {
        display: block;
        color: #000;
        font-size: 6rem; }
      .main-wrapper .cart-container .cart-header .step .step1::before {
        content: "\e912"; }
        @media (min-width: 992px) {
          .main-wrapper .cart-container .cart-header .step .step1::before {
            content: none; } }
      .main-wrapper .cart-container .cart-header .step .step2::before {
        content: "\e911"; }
        @media (min-width: 992px) {
          .main-wrapper .cart-container .cart-header .step .step2::before {
            content: none; } }
      .main-wrapper .cart-container .cart-header .step .step3::before {
        content: "\e910"; }
        @media (min-width: 992px) {
          .main-wrapper .cart-container .cart-header .step .step3::before {
            content: none; } }
      .main-wrapper .cart-container .cart-header .step:last-child::after {
        display: none; }
    .main-wrapper .cart-container .cart-header .step.active span {
      color: #000; }
    .main-wrapper .cart-container .cart-header .step:visited {
      color: #0bc189; }
  .main-wrapper .cart-container .cart-body {
    padding: 4rem 0 4rem 0;
    min-height: 150px;
    display: flex;
    justify-items: center;
    align-items: center; }
    @media (min-width: 545px) {
      .main-wrapper .cart-container .cart-body {
        padding: 1rem 0; } }
    .main-wrapper .cart-container .cart-body .tab {
      display: flex;
      flex-direction: column;
      width: 100%; }
    .main-wrapper .cart-container .cart-body .tab-main {
      flex-wrap: wrap; }
    .main-wrapper .cart-container .cart-body .tab-shipping {
      flex-direction: column; }
      @media (min-width: 768px) {
        .main-wrapper .cart-container .cart-body .tab-shipping {
          flex-direction: row; } }
    .main-wrapper .cart-container .cart-body .tab-summary table tr {
      border-bottom: none; }
    .main-wrapper .cart-container .cart-body table {
      display: flex;
      width: 100%; }
      @media (min-width: 768px) {
        .main-wrapper .cart-container .cart-body table {
          display: table; } }
    .main-wrapper .cart-container .cart-body thead {
      display: none; }
      @media (min-width: 768px) {
        .main-wrapper .cart-container .cart-body thead {
          display: table-header-group; } }
      .main-wrapper .cart-container .cart-body thead th {
        font-weight: normal; }
    .main-wrapper .cart-container .cart-body tbody {
      display: flex;
      flex-direction: column;
      width: 100%; }
      @media (min-width: 768px) {
        .main-wrapper .cart-container .cart-body tbody {
          display: table-row-group; } }
    .main-wrapper .cart-container .cart-body table tr {
      display: flex;
      flex-wrap: wrap; }
      @media (min-width: 768px) {
        .main-wrapper .cart-container .cart-body table tr {
          display: table-row;
          line-height: 3rem; } }
      .main-wrapper .cart-container .cart-body table tr th {
        padding-bottom: 1rem; }
        @media (min-width: 1200px) {
          .main-wrapper .cart-container .cart-body table tr th:last-child {
            padding-left: 3rem; } }
      .main-wrapper .cart-container .cart-body table tr th, .main-wrapper .cart-container .cart-body table tr td {
        display: flex;
        padding-left: 0;
        padding-right: 0; }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-body table tr th, .main-wrapper .cart-container .cart-body table tr td {
            display: table-cell;
            padding-right: 1.5rem; } }
      .main-wrapper .cart-container .cart-body table tr .cart-table-item {
        order: 1;
        width: 80%; }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-body table tr .cart-table-item {
            width: 40%; } }
        @media (min-width: 860px) {
          .main-wrapper .cart-container .cart-body table tr .cart-table-item {
            width: 35%; } }
        .main-wrapper .cart-container .cart-body table tr .cart-table-item .card-item-in {
          width: 100%; }
          @media (min-width: 768px) {
            .main-wrapper .cart-container .cart-body table tr .cart-table-item .card-item-in {
              width: 30rem; } }
          @media (min-width: 860px) {
            .main-wrapper .cart-container .cart-body table tr .cart-table-item .card-item-in {
              width: 100%; } }
        .main-wrapper .cart-container .cart-body table tr .cart-table-item .card-item-img {
          width: 25%; }
          @media (min-width: 480px) {
            .main-wrapper .cart-container .cart-body table tr .cart-table-item .card-item-img {
              width: 15rem; } }
          @media (min-width: 768px) {
            .main-wrapper .cart-container .cart-body table tr .cart-table-item .card-item-img {
              width: 40%; } }
          @media (min-width: 860px) {
            .main-wrapper .cart-container .cart-body table tr .cart-table-item .card-item-img {
              width: 30%; } }
          @media (min-width: 1200px) {
            .main-wrapper .cart-container .cart-body table tr .cart-table-item .card-item-img {
              width: 25%; } }
      .main-wrapper .cart-container .cart-body table tr .cart-table-size {
        order: 3;
        width: 50%;
        padding-right: 1rem; }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-body table tr .cart-table-size {
            width: 15%; } }
        @media (min-width: 1200px) {
          .main-wrapper .cart-container .cart-body table tr .cart-table-size {
            width: 13%; } }
      .main-wrapper .cart-container .cart-body table tr .cart-table-number {
        order: 4;
        width: 50%;
        padding-left: 1rem; }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-body table tr .cart-table-number {
            width: 15%;
            padding-left: 0;
            padding-right: 1rem; } }
        @media (min-width: 1200px) {
          .main-wrapper .cart-container .cart-body table tr .cart-table-number {
            width: 13%; } }
      .main-wrapper .cart-container .cart-body table tr .cart-table-price-one {
        display: none; }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-body table tr .cart-table-price-one {
            display: table-cell;
            width: 12%; } }
        @media (min-width: 992px) {
          .main-wrapper .cart-container .cart-body table tr .cart-table-price-one {
            width: 15%; } }
        @media (min-width: 1200px) {
          .main-wrapper .cart-container .cart-body table tr .cart-table-price-one {
            display: table-cell;
            width: 10%; } }
        .main-wrapper .cart-container .cart-body table tr .cart-table-price-one .main-price {
          font-weight: normal; }
      .main-wrapper .cart-container .cart-body table tr .cart-table-price-all {
        order: 5;
        width: 100%; }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-body table tr .cart-table-price-all {
            display: table-cell;
            width: 12%; } }
        @media (min-width: 992px) {
          .main-wrapper .cart-container .cart-body table tr .cart-table-price-all {
            width: 15%; } }
        @media (min-width: 1200px) {
          .main-wrapper .cart-container .cart-body table tr .cart-table-price-all {
            width: 20%; } }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-body table tr .cart-table-price-all span {
            display: none; } }
      .main-wrapper .cart-container .cart-body table tr .cart-table-options {
        order: 2;
        align-items: center;
        display: flex;
        width: 20%;
        justify-content: center; }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-body table tr .cart-table-options {
            display: table-cell;
            width: 10%; } }
        @media (min-width: 1200px) {
          .main-wrapper .cart-container .cart-body table tr .cart-table-options {
            display: table-cell;
            width: 15%;
            padding-right: 0; } }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-body table tr .cart-table-options .card-item {
            justify-content: space-around; } }
    .main-wrapper .cart-container .cart-body .card-item {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%; }
      @media (min-width: 768px) {
        .main-wrapper .cart-container .cart-body .card-item {
          flex-wrap: nowrap;
          justify-content: space-between; } }
      @media (min-width: 768px) {
        .main-wrapper .cart-container .cart-body .card-item .main-label {
          display: none; } }
      .main-wrapper .cart-container .cart-body .card-item .card-item-in {
        display: flex;
        flex-direction: row;
        width: 75%; }
        @media (min-width: 545px) {
          .main-wrapper .cart-container .cart-body .card-item .card-item-in {
            width: 85%; } }
        .main-wrapper .cart-container .cart-body .card-item .card-item-in .card-item-img img {
          max-width: 100%; }
          @media (min-width: 768px) {
            .main-wrapper .cart-container .cart-body .card-item .card-item-in .card-item-img img {
              min-width: 9rem; } }
          @media (min-width: 992px) {
            .main-wrapper .cart-container .cart-body .card-item .card-item-in .card-item-img img {
              min-width: 6rem; } }
        .main-wrapper .cart-container .cart-body .card-item .card-item-in .card-item-body {
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 75%;
          padding-left: 1rem;
          margin: auto 0; }
          @media (min-width: 545px) {
            .main-wrapper .cart-container .cart-body .card-item .card-item-in .card-item-body {
              padding: 0 2rem 0 2rem; } }
          @media (min-width: 768px) {
            .main-wrapper .cart-container .cart-body .card-item .card-item-in .card-item-body {
              padding: 0 1rem 0 1rem;
              width: 100%; } }
          .main-wrapper .cart-container .cart-body .card-item .card-item-in .card-item-body h2 {
            text-align: left;
            font-size: 2.5rem;
            padding: 0; }
            @media (min-width: 768px) {
              .main-wrapper .cart-container .cart-body .card-item .card-item-in .card-item-body h2 {
                font-size: 1.8rem;
                line-height: 2.4rem;
                margin: 0; } }
          @media (min-width: 768px) {
            .main-wrapper .cart-container .cart-body .card-item .card-item-in .card-item-body h3 {
              font-size: 1.8rem;
              line-height: 2.4rem;
              padding: 0; } }
        .main-wrapper .cart-container .cart-body .card-item .card-item-in .main-price {
          font-size: 2.5rem; }
      .main-wrapper .cart-container .cart-body .card-item-remove, .main-wrapper .cart-container .cart-body .card-item-info {
        margin: auto auto;
        display: flex; }
        .main-wrapper .cart-container .cart-body .card-item-remove::before, .main-wrapper .cart-container .cart-body .card-item-info::before {
          font-size: 4rem; }
          @media (min-width: 768px) {
            .main-wrapper .cart-container .cart-body .card-item-remove::before, .main-wrapper .cart-container .cart-body .card-item-info::before {
              font-size: 2rem; } }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-body .card-item-remove, .main-wrapper .cart-container .cart-body .card-item-info {
            order: 6;
            margin: auto 0; } }
      .main-wrapper .cart-container .cart-body .card-item-remove::before {
        content: "\e901";
        color: #ff0000; }
      .main-wrapper .cart-container .cart-body .card-item-remove:hover::before {
        font-weight: bold;
        color: #c52f2f; }
      .main-wrapper .cart-container .cart-body .card-item-info::before {
        content: "\e900";
        color: #0bc189; }
      .main-wrapper .cart-container .cart-body .card-item-info:hover::before {
        font-weight: bold;
        color: #0f8f68; }
      .main-wrapper .cart-container .cart-body .card-item .product-purchase-inputs {
        width: 100%;
        padding: 0; }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-body .card-item .product-purchase-inputs {
            width: initial; } }
        .main-wrapper .cart-container .cart-body .card-item .product-purchase-inputs-inner {
          display: flex;
          flex-direction: column; }
          @media (min-width: 360px) {
            .main-wrapper .cart-container .cart-body .card-item .product-purchase-inputs-inner {
              flex-direction: row;
              align-items: center; } }
          @media (min-width: 545px) {
            .main-wrapper .cart-container .cart-body .card-item .product-purchase-inputs-inner {
              justify-content: space-between; } }
          .main-wrapper .cart-container .cart-body .card-item .product-purchase-inputs-inner span {
            margin: 0;
            padding: 0; }
          .main-wrapper .cart-container .cart-body .card-item .product-purchase-inputs-inner .selectric-wrapper {
            width: 75%;
            margin: 1rem 0 0 0; }
            @media (min-width: 360px) {
              .main-wrapper .cart-container .cart-body .card-item .product-purchase-inputs-inner .selectric-wrapper {
                margin: 1rem 0 1rem 1rem;
                max-width: 20rem; } }
            @media (min-width: 545px) {
              .main-wrapper .cart-container .cart-body .card-item .product-purchase-inputs-inner .selectric-wrapper {
                margin: 0; } }
            @media (min-width: 768px) {
              .main-wrapper .cart-container .cart-body .card-item .product-purchase-inputs-inner .selectric-wrapper {
                width: 14rem;
                height: 6rem; } }
            @media (min-width: 992px) {
              .main-wrapper .cart-container .cart-body .card-item .product-purchase-inputs-inner .selectric-wrapper {
                width: 15rem;
                height: 6rem; } }
          .main-wrapper .cart-container .cart-body .card-item .product-purchase-inputs-inner select {
            font-size: 3rem; }
          .main-wrapper .cart-container .cart-body .card-item .product-purchase-inputs-inner .input-group {
            width: 75%;
            height: 7rem;
            padding: 0;
            margin: 1rem 0 0 0; }
            @media (min-width: 360px) {
              .main-wrapper .cart-container .cart-body .card-item .product-purchase-inputs-inner .input-group {
                margin: 1rem 0 1rem 1rem;
                max-width: 20rem; } }
            @media (min-width: 545px) {
              .main-wrapper .cart-container .cart-body .card-item .product-purchase-inputs-inner .input-group {
                margin: 0; } }
            @media (min-width: 768px) {
              .main-wrapper .cart-container .cart-body .card-item .product-purchase-inputs-inner .input-group {
                width: 14rem;
                height: 6rem;
                margin: 0; } }
            @media (min-width: 992px) {
              .main-wrapper .cart-container .cart-body .card-item .product-purchase-inputs-inner .input-group {
                width: 15rem;
                height: 6rem; } }
            @media (min-width: 768px) {
              .main-wrapper .cart-container .cart-body .card-item .product-purchase-inputs-inner .input-group input {
                font-size: 2rem; } }
      .main-wrapper .cart-container .cart-body .card-item .card-item-price {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 1rem 0;
        justify-content: space-between; }
        .main-wrapper .cart-container .cart-body .card-item .card-item-price h3 {
          color: #000;
          font-size: 3.5rem;
          padding: 1rem 0; }
          @media (min-width: 360px) {
            .main-wrapper .cart-container .cart-body .card-item .card-item-price h3 {
              font-size: 4rem; } }
          @media (min-width: 768px) {
            .main-wrapper .cart-container .cart-body .card-item .card-item-price h3 {
              font-size: 2rem;
              margin: 0;
              padding: 0; } }
    .main-wrapper .cart-container .cart-body .form input {
      max-width: 600px; }
      @media (min-width: 768px) {
        .main-wrapper .cart-container .cart-body .form input {
          height: 6rem;
          margin-bottom: 1rem; } }
    @media (min-width: 768px) {
      .main-wrapper .cart-container .cart-body .form h2 {
        padding-top: 1rem;
        font-size: 2rem;
        margin-bottom: 1rem; } }
    .main-wrapper .cart-container .cart-body .form .row {
      max-width: 600px; }
      @media (min-width: 768px) {
        .main-wrapper .cart-container .cart-body .form .row .col {
          padding: 0 0.5rem 0 0; } }
      .main-wrapper .cart-container .cart-body .form .row .col:first-child {
        width: 100%;
        flex-basis: auto; }
        @media (min-width: 545px) {
          .main-wrapper .cart-container .cart-body .form .row .col:first-child {
            flex-basis: 0;
            width: auto; } }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-body .form .row .col:first-child {
            width: 100%;
            flex-basis: auto; } }
        @media (min-width: 1200px) {
          .main-wrapper .cart-container .cart-body .form .row .col:first-child {
            flex-basis: 0;
            width: auto; } }
    .main-wrapper .cart-container .cart-body .form .checkbox > label {
      margin: 0; }
    .main-wrapper .cart-container .cart-body .form-second {
      width: 100%;
      flex-direction: column; }
      @media (min-width: 768px) {
        .main-wrapper .cart-container .cart-body .form-second {
          padding-top: 1rem;
          padding-left: 3rem; } }
      @media (min-width: 768px) {
        .main-wrapper .cart-container .cart-body .form-second h2 {
          padding-top: 1rem;
          font-size: 2rem;
          padding-bottom: 1rem; } }
      .main-wrapper .cart-container .cart-body .form-second input {
        max-width: 600px; }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-body .form-second input {
            margin-bottom: 1rem; } }
      .main-wrapper .cart-container .cart-body .form-second .row {
        max-width: 600px; }
      .main-wrapper .cart-container .cart-body .form-second .checkbox label,
      .main-wrapper .cart-container .cart-body .form-second .radio label {
        margin: 0; }
      .main-wrapper .cart-container .cart-body .form-second .form-delivery label {
        margin: 1rem 0 0.5rem 0; }
    .main-wrapper .cart-container .cart-body .form-delivery {
      display: none; }
      .main-wrapper .cart-container .cart-body .form-delivery label {
        margin: 1rem 0 0.5rem 0; }
    .main-wrapper .cart-container .cart-body .checkbox input[type="checkbox"]:checked ~ .remove-check {
      display: block; }
    .main-wrapper .cart-container .cart-body .tab-summary {
      flex-wrap: wrap; }
      @media (min-width: 768px) {
        .main-wrapper .cart-container .cart-body .tab-summary {
          flex-direction: row; } }
      .main-wrapper .cart-container .cart-body .tab-summary .cards-outer {
        width: 100%;
        flex-direction: column; }
        .main-wrapper .cart-container .cart-body .tab-summary .cards-outer .card-item-in {
          width: 100%;
          max-width: initial; }
        .main-wrapper .cart-container .cart-body .tab-summary .cards-outer .card-item-body {
          align-items: flex-start;
          max-width: 55%; }
          @media (min-width: 360px) {
            .main-wrapper .cart-container .cart-body .tab-summary .cards-outer .card-item-body {
              max-width: 61%; } }
          @media (min-width: 768px) {
            .main-wrapper .cart-container .cart-body .tab-summary .cards-outer .card-item-body {
              max-width: 70%;
              flex-direction: row; } }
          @media (min-width: 992px) {
            .main-wrapper .cart-container .cart-body .tab-summary .cards-outer .card-item-body {
              max-width: 70%;
              padding-right: 0;
              align-items: center; } }
          .main-wrapper .cart-container .cart-body .tab-summary .cards-outer .card-item-body span {
            display: inline-flex;
            font-size: 2.3rem; }
            @media (min-width: 360px) {
              .main-wrapper .cart-container .cart-body .tab-summary .cards-outer .card-item-body span {
                font-size: 2.5rem; } }
          .main-wrapper .cart-container .cart-body .tab-summary .cards-outer .card-item-body .card-title {
            width: 100%; }
            @media (min-width: 768px) {
              .main-wrapper .cart-container .cart-body .tab-summary .cards-outer .card-item-body .card-title {
                padding: 0 2rem; } }
            @media (min-width: 992px) {
              .main-wrapper .cart-container .cart-body .tab-summary .cards-outer .card-item-body .card-title {
                padding: 0; } }
            @media (min-width: 1200px) {
              .main-wrapper .cart-container .cart-body .tab-summary .cards-outer .card-item-body .card-title {
                padding: 0 2rem; } }
          @media (min-width: 768px) {
            .main-wrapper .cart-container .cart-body .tab-summary .cards-outer .card-item-body .card-item-body-size {
              line-height: 3rem;
              width: 30rem;
              margin: auto;
              text-align: center; } }
          @media (min-width: 992px) {
            .main-wrapper .cart-container .cart-body .tab-summary .cards-outer .card-item-body .card-item-body-size {
              width: 30rem; } }
          @media (min-width: 768px) {
            .main-wrapper .cart-container .cart-body .tab-summary .cards-outer .card-item-body .card-item-body-size .main-label {
              font-size: 2rem;
              margin: 0 2rem; } }
          @media (min-width: 992px) {
            .main-wrapper .cart-container .cart-body .tab-summary .cards-outer .card-item-body .card-item-body-size .main-label {
              font-size: 1.8rem;
              margin: 0 1rem; } }
          @media (min-width: 1200px) {
            .main-wrapper .cart-container .cart-body .tab-summary .cards-outer .card-item-body .card-item-body-size .main-label {
              margin: 0 2rem; } }
      @media (min-width: 992px) {
        .main-wrapper .cart-container .cart-body .tab-summary .summary-table-container {
          width: 40%; } }
      @media (min-width: 1120px) {
        .main-wrapper .cart-container .cart-body .tab-summary .summary-table-container {
          width: 50%; } }
      @media (min-width: 1200px) {
        .main-wrapper .cart-container .cart-body .tab-summary .summary-table-container {
          width: 50%; } }
      @media (min-width: 992px) {
        .main-wrapper .cart-container .cart-body .tab-summary .summary-cards-container {
          width: 60%; } }
      @media (min-width: 1120px) {
        .main-wrapper .cart-container .cart-body .tab-summary .summary-cards-container {
          width: 50%; } }
      @media (min-width: 1200px) {
        .main-wrapper .cart-container .cart-body .tab-summary .summary-cards-container {
          width: 50%; } }
      .main-wrapper .cart-container .cart-body .tab-summary .summary-cards-container h2:first-child {
        padding: 0; }
      .main-wrapper .cart-container .cart-body .tab-summary .summary-cards-container .main-price {
        align-self: center;
        color: #000; }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-body .tab-summary .summary-cards-container .main-price {
            font-size: 2.5rem;
            padding: 0 2rem; } }
        @media (min-width: 992px) {
          .main-wrapper .cart-container .cart-body .tab-summary .summary-cards-container .main-price {
            font-size: 2rem;
            padding: 0 2rem 0 0; } }
        @media (min-width: 1200px) {
          .main-wrapper .cart-container .cart-body .tab-summary .summary-cards-container .main-price {
            padding: 0 2rem; } }
      .main-wrapper .cart-container .cart-body .tab-summary h2 {
        padding: 4rem 0;
        font-weight: bold;
        font-size: 3rem; }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-body .tab-summary h2 {
            font-size: 2rem; } }
        @media (min-width: 992px) {
          .main-wrapper .cart-container .cart-body .tab-summary h2 {
            padding: 0; } }
      .main-wrapper .cart-container .cart-body .tab-summary .card-item {
        padding: 2rem 0; }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-body .tab-summary .card-item {
            padding: 0;
            border: none; } }
        @media (min-width: 992px) {
          .main-wrapper .cart-container .cart-body .tab-summary .card-item {
            padding: 1rem 0; } }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-body .tab-summary .card-item-in {
            width: 100%; } }
      .main-wrapper .cart-container .cart-body .tab-summary table {
        border-collapse: initial; }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-body .tab-summary table tbody tr {
            line-height: 1rem; } }
        .main-wrapper .cart-container .cart-body .tab-summary table tbody td {
          width: 50%;
          padding-left: 0;
          margin: auto;
          align-items: center; }
      .main-wrapper .cart-container .cart-body .tab-summary .summary-table-price {
        border-block: 1px solid #f7f7f7; }
        .main-wrapper .cart-container .cart-body .tab-summary .summary-table-price h2 {
          text-align: left;
          padding: 0; }
        .main-wrapper .cart-container .cart-body .tab-summary .summary-table-price tr {
          line-height: 15rem; }
          .main-wrapper .cart-container .cart-body .tab-summary .summary-table-price tr .final-price-h2 {
            font-weight: bold;
            color: #0bc189;
            padding-left: 0;
            font-size: 3rem; }
            @media (min-width: 768px) {
              .main-wrapper .cart-container .cart-body .tab-summary .summary-table-price tr .final-price-h2 {
                font-size: 2rem; } }
  .main-wrapper .cart-container .cart-final-price {
    display: flex;
    flex-direction: column; }
    @media (min-width: 768px) {
      .main-wrapper .cart-container .cart-final-price {
        flex-direction: row;
        justify-content: space-between;
        height: 17rem;
        padding: 2rem 0; } }
    .main-wrapper .cart-container .cart-final-price .cart-final-price-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 2rem 0; }
      @media (min-width: 768px) {
        .main-wrapper .cart-container .cart-final-price .cart-final-price-box {
          flex-direction: column;
          width: 30%;
          border: 1px solid #d3d3d3;
          border-radius: 20px;
          padding: 1rem 1rem;
          text-align: center; } }
      .main-wrapper .cart-container .cart-final-price .cart-final-price-box h2 {
        font-size: 3.5rem; }
        @media (min-width: 360px) {
          .main-wrapper .cart-container .cart-final-price .cart-final-price-box h2 {
            font-size: 4rem; } }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-final-price .cart-final-price-box h2 {
            font-size: 3rem;
            text-align: center;
            padding: 1rem 0; } }
      .main-wrapper .cart-container .cart-final-price .cart-final-price-box .final-discount-h2,
      .main-wrapper .cart-container .cart-final-price .cart-final-price-box .final-shipping-h2 {
        font-weight: bold; }
      .main-wrapper .cart-container .cart-final-price .cart-final-price-box .final-price-h2 {
        color: #0bc189;
        font-weight: bold; }
  .main-wrapper .cart-container .cart-footer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 2rem 0 4rem 0; }
    @media (min-width: 768px) {
      .main-wrapper .cart-container .cart-footer {
        flex-direction: row; } }
    .main-wrapper .cart-container .cart-footer button {
      padding: 15px 25px;
      cursor: pointer; }
    .main-wrapper .cart-container .cart-footer .btn-primary, .main-wrapper .cart-container .cart-footer .btn-primary-cart {
      order: 1;
      padding: 2.5rem 3rem; }
      @media (min-width: 768px) {
        .main-wrapper .cart-container .cart-footer .btn-primary, .main-wrapper .cart-container .cart-footer .btn-primary-cart {
          order: 0; } }
      @media (min-width: 992px) {
        .main-wrapper .cart-container .cart-footer .btn-primary, .main-wrapper .cart-container .cart-footer .btn-primary-cart {
          padding: 1.5rem 3rem; } }
      .main-wrapper .cart-container .cart-footer .btn-primary::before, .main-wrapper .cart-container .cart-footer .btn-primary-cart::before {
        content: none; }
    .main-wrapper .cart-container .cart-footer .btn-default {
      display: inline;
      border: none;
      font-size: 3rem;
      font-weight: normal;
      order: 2;
      padding: 5px 5px;
      width: 30rem;
      margin: 2rem auto; }
      @media (min-width: 768px) {
        .main-wrapper .cart-container .cart-footer .btn-default {
          font-size: 20px;
          font-weight: bold;
          border: 1px solid #000;
          order: 0;
          padding: 17px 30px;
          width: initial;
          margin: 0; } }
      .main-wrapper .cart-container .cart-footer .btn-default:hover, .main-wrapper .cart-container .cart-footer .btn-default:focus, .main-wrapper .cart-container .cart-footer .btn-default:active {
        background: none;
        color: #000;
        text-decoration: underline;
        box-shadow: none; }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-footer .btn-default:hover, .main-wrapper .cart-container .cart-footer .btn-default:focus, .main-wrapper .cart-container .cart-footer .btn-default:active {
            background: #000;
            color: #fff;
            text-decoration: none;
            box-shadow: initial; } }
      .main-wrapper .cart-container .cart-footer .btn-default::before {
        content: "< ";
        margin: 0; }
        @media (min-width: 768px) {
          .main-wrapper .cart-container .cart-footer .btn-default::before {
            content: ""; } }

@keyframes rotating {
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1); } }

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4); }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1); } }

.wrapper-discounts {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 5rem; }
  .wrapper-discounts .discounts-perex {
    font-size: 3.5rem;
    text-align: center;
    margin: auto; }
    @media (min-width: 360px) {
      .wrapper-discounts .discounts-perex {
        font-size: 3.8rem; } }
    @media (min-width: 768px) {
      .wrapper-discounts .discounts-perex {
        width: 75%;
        margin: auto; } }
    @media (min-width: 992px) {
      .wrapper-discounts .discounts-perex {
        margin: 0;
        text-align: left;
        line-height: 5rem; } }
    @media (min-width: 1200px) {
      .wrapper-discounts .discounts-perex {
        font-size: 3rem;
        line-height: 4rem; } }
  .wrapper-discounts .discounts-text {
    margin-top: 4rem;
    padding: 0 5px 0 5px; }
    @media (min-width: 360px) {
      .wrapper-discounts .discounts-text {
        padding: 0 15px 0 15px; } }
    @media (min-width: 1400px) {
      .wrapper-discounts .discounts-text {
        padding-right: 15rem; } }
    .wrapper-discounts .discounts-text h2 {
      font-size: 4rem;
      font-weight: bold; }
      @media (min-width: 768px) {
        .wrapper-discounts .discounts-text h2 {
          font-size: 3.5rem; } }
      @media (min-width: 1200px) {
        .wrapper-discounts .discounts-text h2 {
          font-size: 2.5rem; } }
    .wrapper-discounts .discounts-text ul li {
      padding-left: 2rem;
      margin-bottom: 3.5rem;
      line-height: 5rem; }
      @media (min-width: 992px) {
        .wrapper-discounts .discounts-text ul li {
          line-height: 3rem;
          margin-bottom: 2.5rem; } }
      @media (min-width: 1200px) {
        .wrapper-discounts .discounts-text ul li {
          line-height: 2rem;
          margin-bottom: 2rem; } }
      @media (min-width: 768px) {
        .wrapper-discounts .discounts-text ul li ::before {
          top: 14px; } }
    @media (min-width: 480px) {
      .wrapper-discounts .discounts-text ul li, .wrapper-discounts .discounts-text ul a {
        font-size: 2.5rem; } }
    @media (min-width: 992px) {
      .wrapper-discounts .discounts-text ul li, .wrapper-discounts .discounts-text ul a {
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      .wrapper-discounts .discounts-text ul li, .wrapper-discounts .discounts-text ul a {
        font-size: 1.8rem; } }
    .wrapper-discounts .discounts-text h3 {
      font-weight: bold;
      font-size: 3rem;
      line-height: 5rem; }
      @media (min-width: 992px) {
        .wrapper-discounts .discounts-text h3 {
          font-size: 2.5rem;
          line-height: 3rem; } }
      @media (min-width: 1200px) {
        .wrapper-discounts .discounts-text h3 {
          font-size: 2rem; } }
    .wrapper-discounts .discounts-text .link {
      padding: 0; }

@keyframes rotating {
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1); } }

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4); }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1); } }

.label-form,
label {
  display: block;
  margin: 1rem 0 0.5rem 0;
  font-size: 2.8rem; }
  @media (min-width: 480px) {
    .label-form,
    label {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .label-form,
    label {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .label-form,
    label {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .label-form,
    label {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .label-form,
    label {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .label-form,
    label {
      font-size: 1.4rem; } }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

.form-control,
select,
select.form-control,
textarea,
textarea.form-control,
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 0 1.5rem;
  border: 1px solid #818181;
  background: #fff;
  color: #000;
  font-size: 2.8rem;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px; }
  @media (min-width: 320px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 8rem; } }
  @media (min-width: 480px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 7.27273rem; } }
  @media (min-width: 545px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 6.66667rem; } }
  @media (min-width: 768px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 5.33333rem; } }
  @media (min-width: 992px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 5rem; } }
  @media (min-width: 480px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.4rem; } }
  .form-control::-webkit-input-placeholder,
  select::-webkit-input-placeholder,
  select.form-control::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder,
  textarea.form-control::-webkit-input-placeholder,
  input[type="text"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #818181; }
  .form-control:-moz-placeholder,
  select:-moz-placeholder,
  select.form-control:-moz-placeholder,
  textarea:-moz-placeholder,
  textarea.form-control:-moz-placeholder,
  input[type="text"]:-moz-placeholder,
  input[type="number"]:-moz-placeholder,
  input[type="email"]:-moz-placeholder,
  input[type="password"]:-moz-placeholder {
    color: #818181; }
  .form-control::-moz-placeholder,
  select::-moz-placeholder,
  select.form-control::-moz-placeholder,
  textarea::-moz-placeholder,
  textarea.form-control::-moz-placeholder,
  input[type="text"]::-moz-placeholder,
  input[type="number"]::-moz-placeholder,
  input[type="email"]::-moz-placeholder,
  input[type="password"]::-moz-placeholder {
    color: #818181; }
  .form-control:-ms-input-placeholder,
  select:-ms-input-placeholder,
  select.form-control:-ms-input-placeholder,
  textarea:-ms-input-placeholder,
  textarea.form-control:-ms-input-placeholder,
  input[type="text"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #818181; }
  .form-control:focus,
  select:focus,
  select.form-control:focus,
  textarea:focus,
  textarea.form-control:focus,
  input[type="text"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }

textarea.form-control,
textarea {
  padding: 1.5rem; }
  @media (min-width: 320px) {
    textarea.form-control,
    textarea {
      height: 20rem; } }
  @media (min-width: 480px) {
    textarea.form-control,
    textarea {
      height: 18.18182rem; } }
  @media (min-width: 545px) {
    textarea.form-control,
    textarea {
      height: 16.66667rem; } }
  @media (min-width: 768px) {
    textarea.form-control,
    textarea {
      height: 15rem; } }
  textarea.form-control:focus,
  textarea:focus {
    outline: none; }

input[type="submit"] {
  cursor: pointer; }

.required label:before {
  content: "*";
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  font-size: 2rem; }
  @media (min-width: 480px) {
    .required label:before {
      font-size: 1.81818rem; } }
  @media (min-width: 545px) {
    .required label:before {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    .required label:before {
      font-size: 1.33333rem; } }
  @media (min-width: 992px) {
    .required label:before {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    .required label:before {
      font-size: 1.11111rem; } }
  @media (min-width: 1400px) {
    .required label:before {
      font-size: 1rem; } }

label.required:before,
label.required span:before {
  content: "*";
  display: inline-block;
  vertical-align: 0.2rem;
  margin: 0 2px 0 0;
  color: #ff0000;
  font-size: 2.4rem; }
  @media (min-width: 480px) {
    label.required:before,
    label.required span:before {
      font-size: 2.18182rem; } }
  @media (min-width: 545px) {
    label.required:before,
    label.required span:before {
      font-size: 2rem; } }
  @media (min-width: 768px) {
    label.required:before,
    label.required span:before {
      font-size: 1.6rem; } }
  @media (min-width: 992px) {
    label.required:before,
    label.required span:before {
      font-size: 1.5rem; } }
  @media (min-width: 1200px) {
    label.required:before,
    label.required span:before {
      font-size: 1.33333rem; } }
  @media (min-width: 1400px) {
    label.required:before,
    label.required span:before {
      font-size: 1.2rem; } }

.file .uploader-upload {
  display: none; }

.file label {
  display: inline-block;
  padding: 1rem 2rem;
  text-transform: uppercase;
  color: #fff;
  background: #0bc189;
  cursor: pointer;
  font-size: 2.8rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  max-width: 24rem;
  margin: 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  letter-spacing: 1px;
  outline: 0 !important; }
  @media (min-width: 480px) {
    .file label {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .file label {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .file label {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .file label {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .file label {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .file label {
      font-size: 1.4rem; } }

.file .file-name {
  padding: 0.5rem;
  background: #b7b7b7;
  color: #000; }

.form-validation-error select,
.form-validation-error .selectric,
.form-validation-error input[type="text"],
.form-validation-error input[type="password"],
.form-validation-error input[type="email"],
.form-validation-error input[type="number"],
.form-validation-error textarea,
.Error:not(.form-validation-valid) select,
.Error:not(.form-validation-valid) .selectric,
.Error:not(.form-validation-valid) input[type="text"],
.Error:not(.form-validation-valid) input[type="password"],
.Error:not(.form-validation-valid) input[type="email"],
.Error:not(.form-validation-valid) input[type="number"],
.Error:not(.form-validation-valid) textarea {
  border: 1px solid #d40511 !important;
  -webkit-box-shadow: inset 0 0 0 1px #d40511;
  -moz-box-shadow: inset 0 0 0 1px #d40511;
  box-shadow: inset 0 0 0 1px #d40511;
  -webkit-animation-name: blink;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-direction: normal;
  -moz-animation-name: blink;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: ease;
  -moz-animation-iteration-count: 2;
  -moz-animation-direction: normal;
  -o-animation-name: blink;
  -o-animation-duration: 1s;
  -o-animation-timing-function: ease;
  -o-animation-iteration-count: 2;
  -o-animation-direction: normal;
  animation-name: blink;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 2;
  animation-direction: normal; }

@-webkit-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-moz-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-ms-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

div.form-validation-valid .FormErrorLabel,
div.form-validation-valid .EditingFormErrorLabel {
  display: none; }

.ErrorLabel,
.form-control-error,
div.form-validation-error,
.EditingFormErrorLabel {
  font-size: 2.8rem;
  color: #ff0000;
  display: block;
  margin: 0.5rem 0 0 0; }
  @media (min-width: 480px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.4rem; } }

.radio {
  padding: 2rem 0; }
  @media (min-width: 768px) {
    .radio {
      padding: 0 0 2rem 0; } }
  .radio input[type="radio"] {
    display: none; }
    .radio input[type="radio"] + label {
      position: relative;
      display: inline-block;
      padding: 3px 0 1rem 30px;
      margin: 0 0 0 0; }
      @media (min-width: 768px) {
        .radio input[type="radio"] + label {
          font-size: 1.8rem; } }
      @media (min-width: 1400px) {
        .radio input[type="radio"] + label {
          padding: 0 0 1rem 30px; } }
      .radio input[type="radio"] + label:before {
        content: "";
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background: #fff;
        display: block;
        position: absolute;
        left: 0;
        top: 3px;
        border: 1px solid #d3d3d3;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
      .radio input[type="radio"] + label:after {
        content: "";
        display: block;
        left: 4px;
        top: 7px;
        width: 12px;
        height: 12px;
        background: #0bc189;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        position: absolute;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
    .radio input[type="radio"]:checked + label:before {
      border: 1px solid #0bc189; }
    .radio input[type="radio"]:checked + label:after {
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100); }

.checkbox input[type="checkbox"] {
  display: none; }
  .checkbox input[type="checkbox"] + label {
    position: relative;
    padding: 3px 0 1rem 30px;
    margin: 0 0 0 3.5rem;
    display: inline-block; }
    .checkbox input[type="checkbox"] + label:before {
      content: "";
      width: 20px;
      height: 20px;
      background: #fff;
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      border: 1px solid #d3d3d3;
      border-radius: 5px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .checkbox input[type="checkbox"] + label:after {
      content: "\2713";
      display: block;
      left: 2px;
      top: 2px;
      position: absolute;
      color: #0bc189;
      font-weight: bold;
      font-size: 3.5rem;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      @media (min-width: 768px) {
        .checkbox input[type="checkbox"] + label:after {
          font-size: 2.5rem; } }
  .checkbox input[type="checkbox"]:checked + label:before {
    border: 1px solid #0bc189; }
  .checkbox input[type="checkbox"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.row-form {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -1rem; }

.row-form [class^="col-"] {
  padding-left: 1rem;
  padding-right: 1rem; }

.selectric-wrapper {
  position: relative;
  cursor: pointer;
  width: 17rem;
  height: 7rem;
  margin: auto; }

.selectric-responsive {
  width: 100%; }

.selectric {
  height: 100%;
  background: #fff;
  position: relative;
  overflow: hidden;
  border: 1px solid #818181;
  font-weight: 900;
  text-transform: uppercase;
  padding: 0 2rem;
  border-radius: 20px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between; }
  .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
    color: #000;
    user-select: none;
    padding: 0 2rem 0 2rem; }
    @media (min-width: 320px) {
      .selectric .label {
        line-height: 8rem; } }
    @media (min-width: 480px) {
      .selectric .label {
        line-height: 7.27273rem; } }
    @media (min-width: 545px) {
      .selectric .label {
        line-height: 6.66667rem; } }
    @media (min-width: 768px) {
      .selectric .label {
        line-height: 5.33333rem; } }
    @media (min-width: 992px) {
      .selectric .label {
        line-height: 5rem; } }
    @media (min-width: 320px) {
      .selectric .label {
        height: 8rem; } }
    @media (min-width: 480px) {
      .selectric .label {
        height: 7.27273rem; } }
    @media (min-width: 545px) {
      .selectric .label {
        height: 6.66667rem; } }
    @media (min-width: 768px) {
      .selectric .label {
        height: 5.33333rem; } }
    @media (min-width: 992px) {
      .selectric .label {
        height: 5rem; } }
  .selectric .button {
    display: block;
    position: relative;
    right: 0;
    top: 0;
    width: 6rem;
    height: 100%;
    text-align: center;
    transition: all ease 200ms;
    font-size: 0;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    @media (min-width: 480px) {
      .selectric .button {
        width: 5.45455rem; } }
    @media (min-width: 545px) {
      .selectric .button {
        width: 5rem; } }
    @media (min-width: 768px) {
      .selectric .button {
        width: 4rem; } }
    @media (min-width: 992px) {
      .selectric .button {
        width: 3.75rem; } }
    @media (min-width: 1200px) {
      .selectric .button {
        width: 3.33333rem; } }
    @media (min-width: 1400px) {
      .selectric .button {
        width: 3rem; } }
    .selectric .button:before {
      content: "\e90b";
      font-size: .8rem;
      vertical-align: middle; }

.selectric-focus .selectric {
  border-color: #818181; }

.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric {
    border-color: #818181;
    border-bottom: 1px solid #818181; }
    .selectric-open .selectric .button {
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
  .selectric-open .selectric-items {
    display: block;
    width: 100% !important;
    border-color: #818181;
    border-top: 1px solid #818181; }

.selectric-disabled {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */
.selectric-items {
  overflow: hidden;
  display: none;
  position: absolute;
  top: 60%;
  left: 0;
  background-color: #f2f2f2;
  border: 1px solid #818181;
  border-top: 1px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: -1;
  padding-top: 2.5rem; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 60%;
    padding-top: 0;
    padding-bottom: 2.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden; }
  .selectric-items ul, .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: inherit;
    min-height: 2rem; }
  .selectric-items li {
    display: block;
    padding: 1rem 2rem;
    color: #000;
    background: #fff;
    cursor: pointer;
    transition: all ease 200ms; }
    .selectric-items li:before {
      display: none; }
    .selectric-items li.selected {
      color: #000; }
    .selectric-items li.highlighted {
      color: #000; }
    .selectric-items li:hover {
      background: #fafafa;
      color: #0f8f68; }
    .selectric-items li:nth-child(odd) {
      background: #f2f2f2; }
      .selectric-items li:nth-child(odd):hover {
        background: #ededed; }
  .selectric-items .disabled {
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    cursor: default !important;
    background: none !important;
    color: #000 !important;
    user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 1rem;
    cursor: default;
    user-select: none;
    background: none;
    color: #000; }
  .selectric-items .selectric-group.disabled li {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }
  .selectric-items .selectric-group li {
    padding-left: 2.5rem; }

.list-box .selectric-scroll ul li {
  padding: 1rem 2rem 1rem 1rem; }
  .list-box .selectric-scroll ul li:before {
    content: "";
    width: 12px;
    height: 12px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    display: inline-block;
    margin: 0 1rem 0 0;
    background: #cccccc;
    vertical-align: middle;
    position: static;
    border: 2px solid #cccccc; }
  .list-box .selectric-scroll ul li.selected:before {
    background: #0bc189; }
  .list-box .selectric-scroll ul li:first-child:before {
    display: none; }

@keyframes rotating {
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1); } }

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4); }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.pux-gallery-main {
  display: block;
  background-color: #fff;
  padding: 1rem;
  margin: 0 0 2rem 0;
  height: 52rem;
  width: 100%;
  cursor: pointer; }
  @media (min-width: 768px) {
    .pux-gallery-main {
      display: block;
      height: 52rem;
      padding: 2rem; } }
  @media (min-width: 1200px) {
    .pux-gallery-main {
      height: 52rem; } }
  .pux-gallery-main-image {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 48rem;
    z-index: 1; }
    @media (min-width: 768px) {
      .pux-gallery-main-image {
        height: 48rem; } }
    @media (min-width: 1200px) {
      .pux-gallery-main-image {
        height: 48rem; } }
    .pux-gallery-main-image img {
      max-width: 100%;
      max-height: 100%;
      height: auto; }
  .pux-gallery-main.loading {
    position: relative; }
    .pux-gallery-main.loading:before {
      content: '';
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: #fff;
      opacity: 0.8; }
    .pux-gallery-main.loading:after {
      content: '';
      z-index: 3;
      border: 1rem solid #b7b7b7;
      /* Light grey */
      border-top: 1rem solid #0bc189;
      /* Blue */
      border-radius: 50%;
      width: 6rem;
      height: 6rem;
      animation: loader 2s linear infinite;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -3rem;
      margin-left: -3rem; }

.IE .pux-gallery-main-image img {
  width: 100%; }

.pux-gallery-container {
  overflow: hidden; }

.pux-gallery-more {
  margin-left: -1rem;
  margin-right: -1rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .pux-gallery-more-thumb {
    width: 25%;
    transition: all 350ms linear;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 2rem;
    display: none; }
    .pux-gallery-more-thumb figure {
      width: 100%;
      margin: 0;
      border: 0;
      overflow: hidden;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; }
      .pux-gallery-more-thumb figure a {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        padding: 0;
        background-color: #fff;
        border: 0.1rem solid #fff;
        -webkit-flex-basis: 100%;
        -moz-flex-basis: 100%;
        -ms-flex-basis: 100%;
        -webkit-flex-basis: 100%;
        flex-basis: 100%;
        height: 15rem; }
        @media (min-width: 480px) {
          .pux-gallery-more-thumb figure a {
            height: 20rem; } }
        @media (min-width: 545px) {
          .pux-gallery-more-thumb figure a {
            height: 20rem; } }
        @media (min-width: 768px) {
          .pux-gallery-more-thumb figure a {
            height: 12rem; } }
        @media (min-width: 992px) {
          .pux-gallery-more-thumb figure a {
            height: 12rem; } }
        @media (min-width: 1200px) {
          .pux-gallery-more-thumb figure a {
            height: 15rem; } }
        .pux-gallery-more-thumb figure a:after {
          display: none; }
        .pux-gallery-more-thumb figure a img {
          width: 100%;
          height: 100% !important;
          object-fit: contain;
          font-family: 'object-fit: contain'; }
        .pux-gallery-more-thumb figure a.active {
          position: relative; }
          .pux-gallery-more-thumb figure a.active:before {
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            z-index: 22;
            border: 4px solid #0bc189;
            border-radius: 20px; }
    .pux-gallery-more-thumb.load-more {
      display: none !important; }
      .pux-gallery-more-thumb.load-more.show {
        display: -ms-flexbox !important;
        display: -webkit-flex !important;
        display: flex !important; }
    .pux-gallery-more-thumb:nth-of-type(1) {
      display: block; }
    .pux-gallery-more-thumb:nth-of-type(2) {
      display: block; }
    .pux-gallery-more-thumb:nth-of-type(3) {
      display: block; }
  .pux-gallery-more-btn {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    color: #fff;
    font-weight: 700;
    min-height: 10rem;
    overflow: hidden;
    transition: all 350ms linear;
    width: 100%; }
    @media (min-width: 320px) {
      .pux-gallery-more-btn {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      .pux-gallery-more-btn {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      .pux-gallery-more-btn {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .pux-gallery-more-btn {
        font-size: 2.2rem; } }
    .pux-gallery-more-btn-inner {
      min-height: 10rem;
      width: 100%;
      background-color: #fff;
      color: #818181;
      transition: 200ms;
      cursor: pointer;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center; }
      .pux-gallery-more-btn-inner:hover {
        background-color: #f2f2f2; }
  .pux-gallery-more.open .pux-gallery-more-thumb {
    display: block; }
  .pux-gallery-more.open .pux-gallery-more-btn {
    display: none; }

@keyframes rotating {
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1); } }

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4); }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1); } }

.pux-modal-leave .modal-dialog,
.pux-modal .modal-dialog {
  position: fixed;
  opacity: 0;
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  margin: 0;
  max-width: 100%;
  z-index: 1000;
  transition: opacity 0.2s ease; }
  .pux-modal-leave .modal-dialog:target,
  .pux-modal .modal-dialog:target {
    opacity: 1;
    pointer-events: auto; }

.pux-modal-leave .modal-content,
.pux-modal .modal-content {
  text-align: center;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat !important;
  max-width: 500px;
  width: 80%;
  margin: 0;
  padding: 15px 15px; }
  @media (min-width: 768px) {
    .pux-modal-leave .modal-content,
    .pux-modal .modal-content {
      width: 100%; } }
  .pux-modal-leave .modal-content h2,
  .pux-modal .modal-content h2 {
    font-size: 3.5rem;
    text-align: center;
    font-weight: bold;
    padding: 0;
    line-height: 5rem; }
    @media (min-width: 360px) {
      .pux-modal-leave .modal-content h2,
      .pux-modal .modal-content h2 {
        padding: 0 10rem; } }
    @media (min-width: 768px) {
      .pux-modal-leave .modal-content h2,
      .pux-modal .modal-content h2 {
        font-size: 2.7rem;
        line-height: initial;
        padding: 0 5rem; } }
  .pux-modal-leave .modal-content ul,
  .pux-modal .modal-content ul {
    text-align: center;
    padding: 2rem 0; }
    .pux-modal-leave .modal-content ul li,
    .pux-modal .modal-content ul li {
      padding: 0; }
    .pux-modal-leave .modal-content ul li::before,
    .pux-modal .modal-content ul li::before {
      content: none; }
  .pux-modal-leave .modal-content a:last-child,
  .pux-modal .modal-content a:last-child {
    color: #000;
    text-decoration: underline;
    font-size: 3rem;
    font-weight: bold; }
    @media (min-width: 768px) {
      .pux-modal-leave .modal-content a:last-child,
      .pux-modal .modal-content a:last-child {
        font-size: 2rem; } }
  .pux-modal-leave .modal-content .close,
  .pux-modal .modal-content .close {
    outline: 0;
    position: absolute;
    right: 10px;
    top: 15px;
    width: 30px;
    height: 30px;
    text-shadow: none;
    cursor: pointer;
    z-index: 22;
    font-size: 2rem;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }
    @media (min-width: 768px) {
      .pux-modal-leave .modal-content .close,
      .pux-modal .modal-content .close {
        font-size: 1.5rem;
        right: 15px; } }
    .pux-modal-leave .modal-content .close::before,
    .pux-modal .modal-content .close::before {
      content: "\e90c"; }
  .pux-modal-leave .modal-content.modal-light,
  .pux-modal .modal-content.modal-light {
    background-color: #fff;
    color: #000;
    border: none; }
    .pux-modal-leave .modal-content.modal-light .close,
    .pux-modal .modal-content.modal-light .close {
      background: #5a5a5a;
      color: red; }
      .pux-modal-leave .modal-content.modal-light .close:focus, .pux-modal-leave .modal-content.modal-light .close:hover,
      .pux-modal .modal-content.modal-light .close:focus,
      .pux-modal .modal-content.modal-light .close:hover {
        background: #404040; }
  .pux-modal-leave .modal-content.modal-dark,
  .pux-modal .modal-content.modal-dark {
    background-color: #585857;
    color: #fff;
    border: none; }
    .pux-modal-leave .modal-content.modal-dark .close,
    .pux-modal .modal-content.modal-dark .close {
      background: grey;
      color: red; }
      .pux-modal-leave .modal-content.modal-dark .close:focus, .pux-modal-leave .modal-content.modal-dark .close:hover,
      .pux-modal .modal-content.modal-dark .close:focus,
      .pux-modal .modal-content.modal-dark .close:hover {
        background: #676767; }
  .pux-modal-leave .modal-content .modal-body,
  .pux-modal .modal-content .modal-body {
    padding: 30px; }
    .pux-modal-leave .modal-content .modal-body-title,
    .pux-modal .modal-content .modal-body-title {
      font-size: 22px;
      font-size: 1.375rem;
      padding: 0 0 15px 0;
      font-weight: bold; }
    .pux-modal-leave .modal-content .modal-body-subtitle,
    .pux-modal .modal-content .modal-body-subtitle {
      font-size: 18px;
      font-size: 1.125rem;
      padding: 0 0 10px 0; }
    .pux-modal-leave .modal-content .modal-body.no-padding,
    .pux-modal .modal-content .modal-body.no-padding {
      padding: 0; }
  .pux-modal-leave .modal-content.background-cover,
  .pux-modal .modal-content.background-cover {
    background-size: cover !important; }
  .pux-modal-leave .modal-content.top-center,
  .pux-modal .modal-content.top-center {
    background-position: top center !important; }
  .pux-modal-leave .modal-content.top-left,
  .pux-modal .modal-content.top-left {
    background-position: top left !important; }
  .pux-modal-leave .modal-content.top-right,
  .pux-modal .modal-content.top-right {
    background-position: top right !important; }
  .pux-modal-leave .modal-content.center-center,
  .pux-modal .modal-content.center-center {
    background-position: center center !important; }
  .pux-modal-leave .modal-content.bottom-center,
  .pux-modal .modal-content.bottom-center {
    background-position: bottom center !important; }
  .pux-modal-leave .modal-content.bottom-left,
  .pux-modal .modal-content.bottom-left {
    background-position: bottom left !important; }
  .pux-modal-leave .modal-content.bottom-right,
  .pux-modal .modal-content.bottom-right {
    background-position: bottom right !important; }

.modal-backdrop {
  background: #000; }

@media print {
  header,
  footer {
    display: none; } }
