
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";



.wrapper-discounts{
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 5rem;
  .discounts-perex{
    font-size: 3.5rem;
    text-align: center;
    margin: auto;
    @media ( min-width: 360px ) {
      font-size: 3.8rem;
    }
    @include media(md){
    width: 75%;
    margin: auto;
  }
    @include media(lg){
      margin: 0;
      text-align: left;
      line-height: 5rem;
    }
    @include media(xl){
      font-size: 3rem;
      line-height: 4rem;
    }
  }
  .discounts-text{
    margin-top: 4rem;
    padding: 0 5px 0 5px;
    @media ( min-width: 360px ) {
      padding: 0 15px 0 15px;
    }
    @include media(xxl){
      padding-right: 15rem;
    }
    h2{
      font-size: 4rem;
      font-weight: bold;
      @include media(md){
        font-size: 3.5rem;
      }
      @include media(xl){
        font-size: 2.5rem;
      }
    }
    ul{
      li{
      padding-left: 2rem;
      margin-bottom: 3.5rem;
      line-height: 5rem;
        @include media(lg){
          line-height: 3rem;
          margin-bottom: 2.5rem;
        }
        @include media(xl){
          line-height: 2rem;
          margin-bottom: 2rem;
        }
        ::before{
          @include media(md){
            top: 14px;
          }
        }
    }
      li, a{
        @include media(xs) {
          font-size: 2.5rem;
        }
        @include media(lg){
          font-size: 2rem;
        }
        @include media(xl){
          font-size: 1.8rem;
        }
      }
  }
    h3{
      font-weight: bold;
      font-size: 3rem;
      line-height: 5rem;
      @include media(lg){
        font-size: 2.5rem;
        line-height: 3rem;
      }
      @include media(xl){
        font-size: 2rem;
      }
    }
    .link{
      padding: 0;
    }
  }


}
