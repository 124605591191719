
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.row:after {
  content: "";
  display: table;
  clear: both;
}

.container {
  position: relative;
  display: block;
}



.wrapper-product {
  display: flex;
  flex-direction: column;
  //@include media(md) {
  //flex-wrap: wrap;
  //}
  @include media(md){
    flex-direction: row;
    flex-wrap: wrap;
  }
  .product-gallery{
    @include media(sm){
      padding-bottom: 2rem;
    }
    @include media(md){//lg-md
      padding: 5rem 3rem;
      width: 50%;
    }
    @include media(xl){
      padding: 5rem 5rem;
      width: 50%;
    }
    @include media(xxl){
      padding: 5rem 7rem;
    }
    .product-gallery-detail{
      display: block;
      width: 95%;
      margin: auto;
      height: 55rem;
      @media ( min-width: 360px ){
        height: 65rem;
      }
      @include media(sm){
        height: 70rem;
      }
      @include media(md){//lg-md
        height: 55rem;
      }
      @include media(xl){
        height: 53rem;
      }

      img{
        display: block;
        margin: auto;
        width: 55rem;
        @media ( min-width: 360px ){
          width: 65rem;
        }
        @include media(sm){
          width: 70rem;
        }
        @include media(lg){
          width: 50rem;
        }
      }
    }

  }
  .product-detail{
    display: flex;
    flex-direction: column;
    @include media(md){//lg-md
      width: 50%;
    }
    @include media(xl){
      width: 50%;
    }
  h1{
    text-align: left;
    padding-bottom: 0;
    line-height: 5rem;
    @include media(md){//lgmd
      font-size: 2.8rem;
    }
    @include media(lg){
      font-size: 3rem;
    }
    @include media(xl){
      font-size: 4rem;
    }
  }
  h2{
    text-align: left;
    font-weight: normal;
    font-size: 2.5rem;
    padding: 1rem 0;
    @include media(md){//lgmd
      font-size: 2.3rem;
    }
    @include media(xl){
      font-size: 3rem;
    }
  }
  .product-detail-club{
    width: 5rem;
    color: $base-black;
    font-weight: bold;
    padding: 0;
    margin: 3rem auto 4rem 0;
    white-space: nowrap;
    @include media (sm){
      font-size: 2.3rem;
    }
    @include media(md){//lgmd
      margin: 1rem auto 1rem 0;
    }
    .product-detail-club-icon{
      display: inline-block;
      width: 10rem;
      @include media(md){//lgmd
        width: 8rem;
      }
      @include media(xl){
        width: 7rem;
      }
      img{
        width: 100%;
        max-width: 10rem;
        padding-right: 2rem;
        @include media(lg){
          max-width: 8rem;
          padding-right: 1rem;
        }
      }
    }
  }
  table{
    margin: 6rem 0;
    order: 5;
    @include media(md){//lg-md
      order: 0;
      margin: 1rem 0 2rem 0;
    }
    tr{
      line-height: 10rem;
      @include media(md){//lgmd
        line-height: 3rem;
      }
      .sold-out{
        color: $error-label-color;
      }
      .table-head{
        @include media(md){//lg-md
          width: 50%;
        }
        @include media(lg){
          width: 35%;
        }
        @include media(xl){
          width: 25%;
        }
      }
    }
  }

    > .main-label{
        @include media(md) {//lgmd
          padding-top: 2rem;
        }
    }

    .product-purchase-inputs{
      justify-content: space-between;
      @include media(md){//lgmd
        padding-bottom: 1rem;
        justify-content: flex-start;
      }
      .product-purchase-inputs-inner{
        width: 50%;
        @include media(md){//lgmd
          padding-right: 5rem;
        }
        .main-label{
          @include media(md) {//lgmd
            padding-left: 0;
          }

        }

      .selectric-wrapper{
        //width: 25rem;
        width: 100%;
        height: 10rem;
        margin: 1rem 0;
        line-height: 7rem;
        font-size: 3rem;
        @media ( min-width: 360px ) {
        //  width: 30rem;
          height: 11rem;
        }
        @include media(sm){
          line-height: 8rem;
          font-size: 3rem;
        }
        //@include media(md){
        //  height: 10rem;
        //  line-height: 7rem;
        //  font-size: 3rem;
        //}
        @include media(md){
          height: 6rem;
          //width: 17rem;
          font-size: 2rem;
          line-height: 3rem;
        }
        .label{
          font-size: 3rem;
          @include media(md){//lgmd
          font-size: 2rem;
            padding: 0 0.5rem;
          }
        }
        .selectric-items ul li{
          padding-left: 3.5rem;
          margin-top: 1rem;
          @include media(lg){
            padding-left: 2.5rem;
            margin-top: 0;
          }
        }
      }
      .input-group{
        width: 25rem;
        height: 10rem;
        margin: 1rem 0;
        @media ( min-width: 360px ) {
          width: 30rem;
          height: 11rem;
        }
        //@include media(md){
        //  height: 10rem;
        //}
        @include media(md){
          height: 6rem;
          width: 17rem;
        }
        input{
          margin: auto auto;
          @include media(md){//lgmd
            padding: 0;
            font-size: 2rem;
          }
          &:disabled{
            background: none;
          }
        }
      }
      }
    }

  .btn-default{
    order: 3;
    width: 80%;
    margin: 3rem auto;
    font-size: 3.5rem;

    //@include media(md){
    //  width: 100%;
    //  font-size: 2.5rem;
    //}
    @include media(md){//lgmd
      order: 0;
      width: 37rem;
      margin: 0;
      font-size: 2rem;
    }
    @include media(xl){
      font-size: 2rem;
     width: 30rem;
    }
  }

  .main-price{
    font-size: 5rem;
    line-height: 6rem;
    @include media(md){//lgmd
      font-size: 4rem;
      line-height: 5rem;
    }
    @include media(xl){
      font-size: 3rem;
    }
    &.sold-out{
      color: $base-black;
    }
  }


  .btn-primary{
    width: 80%;
    padding: 17px 30px;
    margin: 2rem auto;
    order: 4;
    font-size: 3.5rem;
    line-height: 5rem;
    //@include media(md){
   ///   width: 100%;
    //  font-size: 2.5rem;
    //}
    @include media(md){//lgmd
      margin: 1rem 0 0 0;
      line-height: 4rem;
      width: 33rem;
      font-size: 2rem;
    }
    @include media(xl){
      width: 30rem;
      font-size: 2rem;
      line-height: 3rem;
    }
    &::before{
      display: block;
      vertical-align: middle;
      font-size: 3rem;
      margin: 0 0.6rem 0.3rem 0;
      @media ( min-width: 360px ) {
        font-size: 4rem;
        margin: 0 0.3rem 0.3rem 0;
      }
      @include media(lg){
        font-size: 3rem;
      }
    }
    &.sold-out{
      background-color: $dark-grey;
    }
  }

  .product-gallery-tabs {
    padding: 3rem 3rem;
    @include media(lg){
      max-width: 400px;
    }
  }
}
  .product-related{
    margin: auto;
    width: 100%;
    .products-catalogue{
      display: flex;
    }
    .card-item-outer{
      width: 90%;
      @include media(sm){
        width: 50%;
      }
      //@include media(md) {
      //  width: 33%;
      //  padding: 0 0.5em 1em 0.5em;
      //  text-align: center;
      //  margin: 0;
      //}
      @include media(md) {
        width: 50%;
        margin: auto;
      }
      @media ( min-width: 860px ){
        width: 33%;
      }
      @include media(lg) {
        width: 25%;
      }
    }
    h2{
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      padding: 6rem 0 2rem 0;
    }
  }
}

.slider-title{
  padding:0 0 0 30px;
}
